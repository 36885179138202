import Deposit from 'components/Modules/Deposit';
import History from 'components/Modules/History';
import Security from 'components/Modules/Security';
import Withdraw from 'components/Modules/Withdraw';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';

const CartBtnsNav = () => {
  const dispatch = useDispatch();

  const openMenu = (content: JSX.Element) => {
    dispatch(hideDrawer());
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 300);
  };

  return (
    <div className='cartBtnsNav'>
      <div className='itemBox1'>
        <NavLink to={"/order/market"} className='item'>
          <div className='cir1'>
            <div className='cir2'>
              <span className='icon-d_pro'></span>
            </div>
          </div>
          <p className='t1'>معامله حرفه ای</p>
        </NavLink>
        <div className='herDivider'></div>
        <NavLink to={"/order/fast"} className='item'>
          <div className='cir1'>
            <div className='cir2'>
              <span className='icon-d_fast'></span>
            </div>
          </div>
          <p className='t1'>معامله آسان</p>
        </NavLink>
        <div className='herDivider'></div>
        <div onClick={() => openMenu(<Deposit />)} className='item'>
          <div className='cir1'>
            <div className='cir2'>
              <span className='icon-d_depo'></span>
            </div>
          </div>
          <p className='t1'>واریز</p>
        </div>
        <div className='herDivider'></div>
        <div onClick={() => openMenu(<Withdraw />)} className='item'>
          <div className='cir1'>
            <div className='cir2'>
              <span className='icon-d_withdraw'></span>
            </div>
          </div>
          <p className='t1'>برداشت</p>
        </div>
      </div>
      <div className='itemBox1'>
        <NavLink to={"/finance/wallet"} className='item'>
          <div className='cir1'>
            <div className='cir2'>
              <span className='icon-d_wallet'></span>
            </div>
          </div>
          <p className='t1'>کیف پول</p>
        </NavLink>
        <div className='herDivider'></div>
        <div onClick={() => openMenu(<Security />)} className='item'>
          <div className='cir1'>
            <div className='cir2'>
              <span className='icon-d_security'></span>
            </div>
          </div>
          <p className='t1'>امنیت</p>
        </div>
        <div className='herDivider'></div>
        <div onClick={() => dispatch(fullDrawer(<History />))} className='item'>
          <div className='cir1'>
            <div className='cir2'>
              <span className='icon-d_order'></span>
            </div>
          </div>
          <p className='t1'>تاریخچه ها</p>
        </div>
        <div className='herDivider'></div>
        <NavLink to={"#"} className='item'>
          <div className='cir1'>
            <div className='cir2'>
              <span className='icon-d_menu'></span>
            </div>
          </div>
          <p className='t1'>بیشتر</p>
        </NavLink>
      </div>
    </div>
  );
}

export default CartBtnsNav;
