import React from 'react';
import "react-circular-progressbar/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CartDetail from './items/CartDetail';
import CartWallet from './items/CartWallet';
import NewsBox from './items/NewsBox';
import CardChartChange from './items/CardChartChange';
import TableTraNotBox from './items/TableTra_NotBox';
import CardInvite from './items/CardInvite';
import Header from 'components/Layouts/Header';
import Bottom from 'components/Layouts/Bottom';
import CartBtnsNav from './items/CartBtnsNav';
import CardSlider from './items/CardSlider';

const Dashboard = () => {

  return (
    <>
      <Header title={'داشبورد'} />
      <div className='dashboard'>
        <CartDetail />
        <CartBtnsNav />
        <CardSlider />
        <CartWallet />
        <NewsBox />
        <div className='cardReadMore'>
          <div className='right'>
            <span className='icon-quoteSquare'></span>
            <p className='t1'>آپدیت جدید سامانه اکسنوین اعمال شد</p>
          </div>
          <div className='left'>
            {/* <p className='t1'>بیشتر بخوانید</p> */}
            <span className='icon-arrowCircle'></span>
          </div>
        </div>
        <CardChartChange />
        <TableTraNotBox />
        <CardInvite />
      </div>
      <Bottom />
    </>
  );
}

export default Dashboard;
