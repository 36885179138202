import { CircularProgress, Skeleton, Stack } from '@mui/material'
import _ from 'lodash'
import React from 'react'

const FetchListLoadSmall = ({ load, list }: { load: any, list: any }) => {
  return (
    <div className='fetchListLoad' style={{margin:"0px"}}>
      {load
        ? <div className='emptyLoad'>
          <Stack spacing={1} style={{ width: "100%" }}>
            {new Array(3)?.fill("a").map((i, n) =>
              <Skeleton key={n} variant="text" sx={{ fontSize: '2rem',lineHeight:"30px" }} />
            )}
          </Stack>
        </div>
        : _.isEmpty(list)
        && <div className='noItem'>موردی یافت نشد</div>
      }
    </div>

  )
}

export default FetchListLoadSmall