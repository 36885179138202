const formatter = (price: number, max: number = 0, min: number = 0, zeroMax = false) => {
  // return price.toLocaleString('en-US', {
  //   minimumFractionDigits: min,
  //   maximumFractionDigits: max,
  // });

  if (price) {
    return price.toLocaleString('en-US', {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    });
  } else {
    return zeroMax ? "0" : ''
  }
};

const formatterMilion = (price: number, max: number = 0, min: number = 0, compact: boolean = false) => {
  const data = Number(price).toFixed(16).split('.');
  let dec = (data[1] ? data[1].substring(0, max) : '').replace(/0+$/, '');

  let before = Number(data[0]);
  let add = '';

  if (compact) {
    // LARGE
    if (before > 100000000) {
      dec = '';
      add = 'M';
      before = toFixed(before / 1000000, 0);
    } else if (before > 10000000) {
      dec = '';
      add = 'K';
      before = toFixed(before / 100000, 0);
    }

    // DEC
    if (dec) {
      const leadingZerosMatch = dec?.match(/^0+/);
      const leadingZerosCount = leadingZerosMatch ? leadingZerosMatch[0].length : 0;
      if (leadingZerosCount > 3) {
        const trimmedNumber = dec.replace(/^0+/, '');
        dec = `0{${leadingZerosCount}}${trimmedNumber}`;
      }
    }
  }

  dec = dec.replace(/0+$/, '');
  if (dec.length < min) {
    dec += '0'.repeat(min - dec.length);
  }

  return before.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (dec ? '.' + dec : '') + add;
};

export const zeroFormat = (price: number, max: number = 4, isApi: boolean = true) => {
  // const newMax = max <= 0 ? 1 : max
  if (isApi) {
    const str = price ? price.toString() : "";
    if (str.includes("e") || Number(price) < 1) {
      let myMax = 4
      const demical = Number(price)?.toFixed(16)?.replace(/0+$/, "")?.split('.')[1]?.length || 0;
      if (demical) {
        // myMax = Number(demical.match(/^0*/)?.[0].length) + 3;
        myMax = Number(demical) + 3;
      }
      return price == 0 ? 0 : formatter(price, myMax, 0);
    } else if (price < 10) {
      return price == 0 ? 0 : formatter(price, 5, 0);
    } else if (price < 100) {
      return price == 0 ? 0 : formatter(price, 4, 0);
    } else if (price < 1000) {
      return price == 0 ? 0 : formatter(price, 3, 0);
    } else if (price < 50000) {
      return price == 0 ? 0 : formatter(price, 2, 0);
    } else {
      return price == 0 ? 0 : formatter(price, 0, 0);
    }
  }

  if (price === Infinity) return ' - - ';

  if (price) {
    return formatter(price, max, 0);
  } else {
    return '0'
  }
}

const autoFormatter = (price: number) => {
  let max = 0;

  if (price >= 50000) {
    max = 0;
  } else if (price >= 1000) {
    max = 2;
  } else if (price >= 100) {
    max = 3;
  } else if (price >= 10) {
    max = 4;
  } else if (price >= 1) {
    max = 5;
  } else if (price > 0) {
    max = 9;
  } else {
    const demical = price.toString().split('.')[1];
    if (demical) {
      max = Number(demical?.match(/^0*/)?.[0].length) + 3;
    }
  }

  return formatter(price, max, 0);
};

const getPercent = (v: number, d: number) => {
  return Number((v * d) / 100);
};

const getRandomNumber = (min: number, max: number, decimals: number) => {
  return parseFloat((Math.random() * (max - min) + min).toFixed(decimals));
};

const toFixed = (number: number, digits: number = 8) => {
  return Number(number.toFixed(digits));
};

const toFloor = (number: number, value: number = 8) => {
  const precision = Math.pow(10, value);
  return Math.floor(number * precision) / precision;
};

const toCeil = (number: number, value: number = 8) => {
  const precision = Math.pow(10, value);
  return Math.ceil(number * precision) / precision;
};

export { formatterMilion,autoFormatter, formatter, getPercent, getRandomNumber, toCeil, toFixed, toFloor };
