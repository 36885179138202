import useUser from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { isUserLogin } from 'utils/user';
import Menu from './Menu';
import PreLogin from './PreLogin';

const Bottom = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useUser();

  return (
    <div className='menu'>
      <div className={`${isUserLogin() ? "" : "myPriceIcon"} ${location.pathname === (isUserLogin() ? "/dashboard" : '/price') ? 'active' : ''}`}
        onClick={() => (isUserLogin() ? navigate('/dashboard') : navigate('/price'))}
      >
        {isUserLogin() ? "داشبورد" : " نرخ روز"}
      </div>
      <div className={location.pathname.includes("/order/market") ? 'active' : ''} onClick={() => navigate('/order/market')}>
        بازارها
      </div>
      <div className={location.pathname.includes("/order/fast") ? 'active' : ''} onClick={() => navigate('/order/fast')}>
        مبادله
      </div>
      {profile.isOld ? (
        <div className={location.pathname === '/order/currency' ? 'active' : ''} onClick={() => navigate('/order/currency')}>
          ارزها
        </div>
      ) : (
        <div
          className={location.pathname === '/order/currency' ? 'active' : ''}
          onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : dispatch(fullDrawer(<Menu />)))}
        >
          پروفایل
        </div>
      )}
      <div
        className={location.pathname === '/finance/wallet' ? 'active' : ''}
        onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : navigate('/finance/wallet'))}
      >
        کیف پول
      </div>
    </div>
  );
};

export default Bottom;
