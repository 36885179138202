import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import useUser from 'hooks/useUser';
import { toFarsiNumber } from 'utils/tools';
import { zeroFormat } from 'utils/numeral';
import { isUserLogin } from 'utils/user';
import useTicker from 'hooks/useTicker';
import gather from 'utils/gather';
import config from 'config';
import moment from 'jalali-moment';

const CartDetail = () => {
  const { profile } = useUser();
  const [rateAuth, setRateAuth] = useState(0);
  const [listOrder, setListOrder]: any = useState(null);
  // const ticker = useTicker();

  const handleAuth = () => {
    let userRate = 0;

    if (profile?.verify?.card) userRate += 20;
    if (profile?.verify?.document) userRate += 20;
    if (profile?.verify?.email) userRate += 20;
    if (profile?.verify?.identity) userRate += 20;
    if (profile?.verify?.rules) userRate += 20;

    setRateAuth(userRate)
  }

  const get = async () => {
    setListOrder(null)

    const result = await gather(`${config.data.api}/v1/order?sortBy=createdAt&orderBy=desc&limit=2000&fromDate=${Math.floor(moment().subtract(30, 'd').valueOf())}&toDate=${Math.floor(moment().add(1, 'm').valueOf())}`, true).get()

    if (result.message === 200) {
      setListOrder(result?.data ?? []);

    } else {
      setListOrder(null)
    }
  }

  useEffect(() => {
    if (isUserLogin()) {
      handleAuth();
      get();
    }
  }, []);

  return (
    <div className='cartDetail'>
      <div className='boxAuth'>
        <div className='authChart'>
          <CircularProgressbar
            value={rateAuth}
            text={`${toFarsiNumber(rateAuth)}%`}
            strokeWidth={13}
            styles={buildStyles({
              textSize: "20px",
              pathColor: "#22c58b",
              textColor: "var(--color-black4)",
              trailColor: "#d6d6d6",
            })}
          />
        </div>
        <div className='boxText'>
          <p className='t1'>احراز هویت</p>
          <p className='t2'>احراز هویت شما تکمیل {rateAuth !== 100 ? "نشده" : "شده"} است.</p>
          <div className='boxBtn'>
            <div className='txts'>
              <p className='p1'>درصد تکمیل</p>
              <p className='p2'>درصد باقی مانده</p>
            </div>
          </div>
        </div>
      </div>
      <div className='divider'></div>
      <div className='boxDetail'>
        <div className='right'>
          <div className='userIcon'>
            <img src="/images/m-IconUser1.png" alt="userIcon" />
          </div>
          <div className='userName'>
            <p className='t1'>{(profile?.name ?? "") + " " + (profile?.family ?? "")}</p>
            <p className='t2'>{toFarsiNumber(profile?.mobile ?? "")}</p>
          </div>
        </div>
        <div className='left'>
          <div className='item'>
            <p className='t1'>سطح کاربری</p>
            <p className='t2'>سطح {profile?.plane?.level?.name ?? "---"}</p>
          </div>
          <div className='item'>
            <p className='t1'> کارمزد خرید</p>
            <p className='t2'>{toFarsiNumber(profile?.plane?.level?.fee?.tmn?.buy ?? 0)}%</p>
          </div>
          <div className='item'>
            <p className='t1'> کارمزد فروش</p>
            <p className='t2'>{toFarsiNumber(profile?.plane?.level?.fee?.tmn?.sell ?? 0)}%</p>
          </div>
        </div>
      </div>
      {/* <div className='divider'></div> */}
      <div className='boxSumOrders'>
        <span className="icon-d_pro"></span>
        <p className='t1'>حجم معاملات ۳۰ روز اخیر شما  <b> {listOrder ? toFarsiNumber(
            zeroFormat(listOrder
              ?.filter((ele: any) => ele?.pair == "tmn")
              ?.reduce((n: any, e: any) => n + (Number(e.fill) * Number(e.price)), 0), 0, false)) : "0"} </b>  تومان </p>
      </div>
    </div>
  );
}

export default CartDetail;
