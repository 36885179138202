// import VerificationInput from "react-verification-input";
import { useDispatch } from "react-redux";
// import Countdown from "react-countdown";
import { useEffect, useState } from "react";
import _ from "lodash";
import gather from "utils/gather";
import config from "config";
import { errorAlert, successAlert } from "services/reducer/alert";
import { hideDialog } from "services/reducer/dialog";
import { hideLoading, showLoading } from "services/reducer/loading";
import Top from "../Top";
import PatchCode from "components/Helper/PatchCode";
import useTimer from "hooks/useTimer";
import { fullDrawer, hideDrawer } from "services/reducer/drawer";
import { getProfile } from "services/reducer/user";
import AddEmail from "./AddEmail";
import VerificationInput from "react-verification-input";
import { numbersToEn } from "utils/tools";


const VerifyCodeEmailPhone = ({ flag, value }: { flag: boolean, value: any }) => {
  const [code, setCode] = useState<any>('');
  const dispatch = useDispatch<any>();
  const timer = useTimer();

  const send = async (code1:any) => {
    dispatch(showLoading());

    const result = flag
      ? await gather(`${config.data.api}/v1/user/mobile/verify`, true).post({ code:numbersToEn(code1?.toString()) })
      : await gather(`${config.data.api}/v1/user/email/verify`, true).post({ code:numbersToEn(code1?.toString())})

    if (result.code === 200) {
      dispatch(successAlert("عملیات با موفقیت انجام شد"));
      dispatch(hideDrawer());
      dispatch(hideDialog());
      await dispatch(getProfile());
    } else {
      setCode('');
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    dispatch(hideLoading());
  };

  const openMenu = (content: JSX.Element) => {
    dispatch(hideDrawer());
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 300);
  };

  const reloadCode = async () => {
    dispatch(showLoading());

    const result = flag
      ? await gather(`${config.data.api}/v1/user/mobile`, true).post({ "mobile": value })
      : await gather(`${config.data.api}/v1/user/email`, true).post({ "email": value })

    if (result.message === 200) {
      timer.start();
      setCode('');
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  }

  useEffect(() => {
    timer.start();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm registerFormBottom'>
        <Top title={`کد تاییدارسالی به ${flag ? "تلفن همراه" : "ایمیل"}`} />
        <div className='logo'></div>
        <div className='container inData verifyCodeEmailPhone'>
          <h4 className='confirmTitle'>کد تایید به {flag ? "تلفن همراه" : "ایمیل"} {value} ارسال شد</h4>
          <div className='codeNumberBox'>
        <VerificationInput
          onComplete={(e) => send(e)}
          onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          value={code}
          placeholder='-'
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
      </div>
          <div className='changeMobile'>
            <i className="colorText">
              {!timer.isFinish ? (
                <>
                  {timer.minute ? `${timer.minute} دقیقه و ` : ''}
                  {timer.second} ثانیه
                </>
              ) : (
                <div className='active' onClick={reloadCode}>
                  ارسال مجدد کد
                </div>
              )}
            </i>
            <p onClick={() => openMenu(<AddEmail />)}>{`ویرایش ${flag ? "تلفن همراه" : "ایمیل"}`}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyCodeEmailPhone