import { Skeleton } from '@mui/material';
import config from 'config';
import useUser from 'hooks/useUser';
import { IBuySell } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { socket } from 'services/socket';
import gather from 'utils/gather';
import { formatter, formatterMilion, toFixed, zeroFormat } from 'utils/numeral';
import { countZeroDecimal, priceZeroDecimal, removeComma } from 'utils/tools';
let oldPrice = 0;

const OrderBook = ({ orderList, setOrderList, coinPrice, precision, setCustomPrice, coin, pair, setAmount, tradeTab }: any) => {
  const [up, setUp] = useState(true);
  const [changedRowsSell, setChangedRowsSell] = useState(new Set());
  const [changedRowsBuy, setChangedRowsBuy] = useState(new Set());
  const [prevDataSell, setPrevDataSell] = useState<any>([]);
  const [prevDataBuy, setPrevDataBuy] = useState<any>([]);
  const user = useUser();
  const [isOffline, setIsOffline] = useState("");

  const handleArrowOrderBook = (item: any, type: string) => {
    const findx = Object.keys(user.orderForOderBook)?.find((e: any) => e == coin?.symbol + "-" + type + "-" + item?.price);
    if (findx) {
      return 'arrowOrderBook'
    } else {
      return ""
    }
  }

  useEffect(() => {
    orderList.buy.forEach((value: any, i: number) => {
      if (prevDataBuy[i] && (value.quantity !== prevDataBuy[i].quantity || value.price !== prevDataBuy[i].price || value.amount !== prevDataBuy[i].amount)) {
        setChangedRowsBuy((prev) => new Set(prev).add(i));
        setTimeout(() => {
          setChangedRowsBuy((prev) => {
            const newSet = new Set(prev);
            newSet.delete(i);
            return newSet;
          });
        }, 300);
      }
    });

    setPrevDataBuy(orderList.buy);
  }, [orderList.buy]);

  useEffect(() => {
    orderList.sell.forEach((value: any, i: number) => {
      if (
        prevDataSell[i] &&
        (value.quantity !== prevDataSell[i].quantity || value.price !== prevDataSell[i].price || value.amount !== prevDataSell[i].amount)
      ) {
        setChangedRowsSell((prev) => new Set(prev).add(i));
        setTimeout(() => {
          setChangedRowsSell((prev) => {
            const newSet = new Set(prev);
            newSet.delete(i);
            return newSet;
          });
        }, 300);
      }
    });

    setPrevDataSell(orderList.sell);
  }, [orderList.sell]);

  const handleClick = (value: any, data: any, num: any, type: any) => {
    if (tradeTab == "فوری") return
    setAmount(handleTotalVolume(data, num, type));
    setCustomPrice(handleAvgPrice(data, num, type));
  }

  const handleAvgPrice = (dataList: any, numI: number, type: any) => {
    let number = 0;
    Array.from(dataList)?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += element?.price
      }
    });
    if (number > 0) {
      number = number / (dataList.length - numI);
    }

    return toFixed(number, precision);
  }

  const handleTotalVolume = (dataList: any, numI: number, type: any) => {
    let number = 0;
    Array.from(dataList)?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += element?.amount
      }
    });

    return toFixed(number, coin?.decimal)
  }

  const handleTotalVPrice = (dataList: any, numI: number) => {
    let number = 0;
    Array.from(dataList)?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += (Number(element?.price) * Number(element?.amount))
      }
    });
    return number

    // return zeroFormat(number, precision, false)
  }

  const list = (data: IBuySell[], type: string) => {
    const rows: React.ReactElement[] = [];

    const limit = 8;

    const newData = data.slice(0, limit);

    const max = Math.max(...newData.map((e) => e.quantity));
    const min = Math.min(...newData.map((e) => e.quantity));

    // if (type === 'sell') {
    newData.reverse();
    // }

    newData.forEach((value, i) => {
      let percent = ((value.quantity - min) / (max - min)) * 100;
      if (percent < 1) {
        percent = 1;
      }

      const isChanged = type === 'sell' ? changedRowsSell.has(i) : changedRowsBuy.has(i);

      rows.push(
        <span key={i} className={`trHover ${handleArrowOrderBook(value, type)}`} onClick={() => handleClick(value, newData, i, type)}>
          <i className={`ltr 
            ${(countZeroDecimal(removeComma(formatter(value.price, 20, 0))) > 2
              || coin?.symbol == "btc")
            && "font-10"}`}>
            {priceZeroDecimal(removeComma(formatter(value.price, 20, 0)), precision, false)}
          </i>
          <p className={`ltr 
          ${(value.amount?.toString()?.split(".")[0]?.length > 8
              || coin?.symbol == "btc")
            && "font-10"}`}>
            {formatterMilion(value.amount, coin?.decimal, 0, true)}</p>
          <section className={isChanged ? 'priceChanged' : ''} style={{ width: percent + '%' }}></section>

          <div className='hoverOrderBook'>
            <div className='itemHover'>
              <p className='p1'>میانگین قیمت : </p>
              <p className='p1 ltr'>
                {/* {handleAvgPrice(newData, i, type)} */}
                {/* {priceZeroDecimal(removeComma(handleAvgPrice(newData, i, type)), precision, false)} */}
                {priceZeroDecimal(removeComma(formatter(handleAvgPrice(newData, i, type), 20, 0)), precision, false)}
              </p>
            </div>
            <div className='itemHover'>
              <p className='p1'>حجم کل : </p>
              <p className='p1'>{zeroFormat(handleTotalVolume(newData, i, type), coin?.decimal, false)}</p>
            </div>
            <div className='itemHover'>
              <p className='p1'>ارزش کل : </p>
              <p className='p1'>{zeroFormat(toFixed(handleTotalVPrice(newData, i), coin?.decimal))}</p>
            </div>
          </div>
        </span>,
      );
    });

    for (let i = rows.length; i < limit; i++) {
      rows[type === 'sell' ? 'unshift' : 'push'](
        <Skeleton variant='text' key={1000 + i} sx={{ fontSize: '1rem' }} />,
      );
    }

    return rows;
  };

  const orderBook = (result: any) => {
    setOrderList(result);
  };

  const getData = async () => {
    setOrderList({ buy: [], sell: [] });
    const result = await gather(`${config.data.api}/v1/orderBook/${coin?.symbol}/${pair}`).get();
    if (result.code === 200) {
      setOrderList(result.data);
    }
  };

  useEffect(() => {
    setOrderList({ buy: [], sell: [] });
    getData();
  }, [coin, pair, isOffline]);

  useEffect(() => {
    if (coinPrice > oldPrice) {
      setUp(true);
    } else {
      setUp(false);
    }

    oldPrice = coinPrice;
  }, [coinPrice]);

  useEffect(() => {
    socket.emit('orderBook', coin?.symbol + pair);
    socket.on('orderBook', orderBook);

    return () => {
      socket.emit('unOrderBook', coin?.symbol + pair);
      socket.removeListener('orderBook', orderBook);
    };
  }, [coin, pair, isOffline]);

  useEffect(() => {
    const handleOnline = () => {
      setTimeout(() => {
        setIsOffline(new Date().getTime().toString())
      }, 4000);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', () => { });

    // پاکسازی رویدادها
    return () => {
      window.removeEventListener('online', () => { });
      window.removeEventListener('offline', () => { });
    };
  }, []);

  return (
    <div className='leftMarket'>
      <div className='depth depthTitle'>
        <span>
          <i>قیمت</i>
          <p>مقدار</p>
        </span>
      </div>
      <div className='depth'>{list(orderList.sell, 'sell')}</div>
      <h5 className={`${up ? 'colorGreen' : 'colorRed'} ltr`} onClick={() => setCustomPrice(toFixed(coinPrice, precision))}>
        {priceZeroDecimal(removeComma(formatter(coinPrice, 20, 0)), precision, false)} {pair?.toUpperCase()}
      </h5>
      <div className='depth reverse'>{list(orderList.buy, 'buy')}</div>
    </div>
  );
};

export default OrderBook;
