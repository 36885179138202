import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const Chart = ({ symbol }: any) => {
  const pair = symbol === 'USDT' ? 'USDTUSD' : symbol + 'USDT';
  const [flag, setFlag] = useState(false);
  const [theme] = useLocalStorage('themePWAEX', "light");

  useEffect(() => {
    setFlag(false)
    setTimeout(() => {
      setFlag(true);
    }, 700);
  }, [symbol,theme]);

  return (
    <div className='chartView'>
      {flag && <iframe
        src={`https://www.tradingview-widget.com/widgetembed/?hideideas=1&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en#%7B%22symbol%22%3A%22${pair}%22%2C%22frameElementId%22%3A%22tradingview_db12a%22%2C%22interval%22%3A%2260%22%2C%22hide_top_toolbar%22%3A%221%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22save_image%22%3A%220%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22${theme}%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22demo.exnovin.net%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22USDTUSD%22%2C%22page-uri%22%3A%22demo.exnovin.net%2Forder%2Fmarket%22%7D`}
      ></iframe>}
    </div>
  );
};

export default Chart;
