import Top from 'components/Layouts/Top';
import useUser from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import bank from 'utils/bank';
import NewCard from '../NewCard';
import View from './View';
import _ from 'lodash';
import AddNationalAndBirthday from 'components/AddNationalAndBirthday';
import Level1 from '../Level1';
import Identity from '../Identity';

const Cards = () => {
  const user = useUser();
  const dispatch = useDispatch();

  const ticketsRender = () => {
    const rows: JSX.Element[] = [];

    Object.values(user.cards).forEach((e,n) => {
      rows.push(
        <div key={n} onClick={() => dispatch(centerDialog(<View data={e} />))}>
          <span>
            <img src={bank.detect(e?.number??""!)?.logo} />
          </span>
          <span>{bank.detect(e?.number??""!)?.title}</span>
          {e?.number && <span>{e?.number.match(new RegExp('.{1,4}', 'g'))?.join('-')}</span>}
        </div>,
      );
    });

    if (rows.length === 0) {
      return <div key={1000} className='noItem'>موردی یافت نشد</div>;
    }

    return (
      <div className='openOrder cardList'>
        <div>
          <span className='colorText'>#</span>
          <span className='colorText'>بانک</span>
          <span className='colorText'>شماره کارت</span>
        </div>
        {rows}
      </div>
    );
  };

  const handleCreate = () => {
    if (user?.profile?.plane?.level?.index > 0) {
      if (!user?.profile?.verify?.identity || _.isNull(user?.profile?.nationalCode)
        || _.isNull(user?.profile?.birthDate) || user?.profile?.nationalCode?.trim().length != 10
        || user?.profile?.birthDate?.trim().length == 0) {
        dispatch(fullDrawer(<AddNationalAndBirthday />))
      } else {
        dispatch(fullDrawer(<NewCard />));
      }
    } else {
      dispatch(fullDrawer(<Identity />))
    }
  }

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'کارت های بانکی'} />
        <div className='container inData'>
          <div className='addNew' onClick={handleCreate}>
            افزودن کارت بانکی
          </div>
          <div className='items itemsFull itemsTicket'>{ticketsRender()}</div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
