import OptionForm from 'components/Helper/OptionForm';
import Coins from 'components/Layouts/Coins';
import Top from 'components/Layouts/Top';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import { ICoin, IDispatch } from 'interfaces/global';
import _, { slice } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Address = ({ setData }: { setData: any }) => {
  const global = useGlobal();
  const [receiver, setReceiver] = useState('');
  const [title, setTitle] = useState('');
  const [memo, setMemo] = useState('');
  const [coin, setCoin] = useState<ICoin | null>(null);
  const [network, setNetwork] = useState('');
  const [list, setList] = useState([]);
  const dispatch = useDispatch<IDispatch>();
  const [needTagMemo, setNeedTagMemo] = useState(false);

  const justEnglish = (e: any) => {
    // const re = /^[A-Za-z0-9-]+$/;
    const re =  /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setReceiver(e);
    } else {
      dispatch(errorAlert("فقط حروف لاتین برای آدرس کیف پول وارد کنید"));
    }
  };

  const justNum = (e: any) => {
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setMemo(e);
    } else {
      dispatch(errorAlert("فقط اعداد و حروف لاتین برای آدرس ممو وارد کنید"));
    }
  };

  const hanleSelectNetwork = (e: any) => {
    setNeedTagMemo(false);
    setReceiver("");
    setMemo("");
    setNetwork(e);

    if (coin?.networks.find((ele: any) => ele.network.name === e)?.network?.memo?.isForce
      || coin?.networks.find((ele: any) => ele.network.name === e)?.network?.memo?.isNeed) {
      setNeedTagMemo(true);
    }
  }

  const hanleSelectCoin = (e: any) => {
    setNeedTagMemo(false);
    setReceiver("");
    setMemo("");
    setNetwork('');
    setCoin(e);
  }

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) { }
  };

  const listRender = () => {
    const rows: JSX.Element[] = [];

    list
      .sort((a: any, b: any) => b.updatedAt - a.updatedAt)
      .forEach((e: any) => {
        rows.push(
          <div className='itemWithdrawAdress'>
            <div className='itemWithdraw'>
              <section className='right'>
                <img className='icon' crossOrigin="anonymous" src={global?.coins[e?.coin?.symbol]?.icon} alt="" />
                <section className='txtBox'>
                  <p className='t1'> {e?.coin?.symbol?.toUpperCase()} - {global?.coins[e?.coin?.symbol]?.networks.find((e1) => e1.network._id === e?.network?._id)?.network.name}</p>
                  <div className='txtAdress'>
                    <p className='t2'>
                      {e.receiver?.length > 18 ? e.receiver?.slice(0, 6) + "..." + e.receiver?.slice(-6, e.receiver?.lengthth) : e.receiver}
                    </p>
                    <i className='copy' onClick={() => copy(e.receiver)}></i>
                  </div>
                  {e.memo?.trim().length > 0
                    &&
                    <div className='txtAdress'>
                      <p className='t2'>
                        {e.memo?.length > 18 ? e.memo?.slice(0, 6) + "..." + e.memo?.slice(-6, e.memo?.lengthth) : e.memo}
                      </p>
                      <i className='copy' onClick={() => copy(e.memo)}></i>
                    </div>
                  }
                </section>
              </section>
              <section className='left'>
                <p className='t1'>{e.title?.length > 9 ? e.title?.slice(0, 9) + "..." : e.title} </p>
                <div className='btnsOp'>
                  <span className={`boxStatus bgGreen`}
                    onClick={() => {
                      setData(e);
                      dispatch(hideDrawer());
                    }}
                  >انتخاب</span>
                  <span className={`boxStatus bgGray`}
                    onClick={(o) => {
                      o.stopPropagation();
                      deleteAddress(e._id);
                    }}
                  >حذف</span>
                </div>
              </section>
            </div>
          </div>
        );
      });

    if (rows.length === 0) {
      return <section className='noItem w100Per'>هیچ آدرس ثبت شده ای ندارید</section>;
    }

    return rows;
  };

  const deleteAddress = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/address/${id}`, true).delete();
    if (result.code === 200) {
      await init();
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const saveAction = async () => {
    if (title.length < 1) {
      dispatch(errorAlert(" عنوان را وارد کنید"));
      return;
    }

    if (_.isEmpty(coin)) {
      dispatch(errorAlert("لطفا ارز خود را وارد کنید"));
      return;
    }

    if (network.length <= 1) {
      dispatch(errorAlert("لطفا شبکه انتقال را انتخاب کنید"));
      return;
    }

    if (receiver.length < 20) {
      dispatch(errorAlert("آدرس مقصد را بررسی کنید"));
      return;
    }

    if (coin?.networks.find((ele: any) => ele.network.name === network)?.network?.regex &&
      !new RegExp(coin?.networks.find((ele: any) => ele.network.name === network)?.network?.regex).test(receiver)) {
      dispatch(errorAlert("آدرس مقصد را معتبر نیست"));
      return
    }


    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/address`, true).post({
      coin: coin?._id,
      network: coin?.networks.find((e) => e.network.name === network)?.network._id,
      title: title,
      receiver: receiver,
      memo: memo,
    });

    if (result.code === 200) {
      setData(result.data);
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/address`, true).get();
    if (result.code === 200) {
      setList(result.data);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setNetwork('');
  }, [coin]);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'دفترچه آدرس'} />
        <div className='container inData'>
          <div className='historyList'>{listRender()}</div>
          <div className='mainInput mainInputAddress'>
            <div>
              <input type='text' placeholder='عنوان دلخواه' value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div className='noInput' onClick={() => dispatch(centerDialog(<Coins setCoin={hanleSelectCoin} />))}>
              {coin ? (
                <>
                  <img crossOrigin='anonymous' src={coin.icon} />
                  <h3>
                    {coin.name} ({coin?.symbol.toUpperCase()})
                  </h3>
                </>
              ) : (
                <>انتخاب ارز</>
              )}
            </div>
            <div
              className='noInput'
              onClick={() =>
                coin &&
                dispatch(
                  centerDialog(<OptionForm setOption={hanleSelectNetwork} def={network ?? ""} value={coin?.networks} />),
                )
              }
            >
              {network ? <h3>{network}</h3> : <>نوع شبکه</>}
            </div>
            <div className='spacerFix'>
              <input type='text' placeholder='آدرس کیف پول' value={receiver} onChange={(e) => justEnglish(e.target.value)} />
            </div>
            {needTagMemo
              &&
              <div className='spacer'>
                <input type='text' placeholder='MEMO/TAG (اختیاری)' value={memo} onChange={(e) => justNum(e.target.value)} />
              </div>
            }
          </div>
          <div className='largeButton' onClick={() => saveAction()}>
            ثبت آدرس جدید
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
