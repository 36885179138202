import { CircularProgress } from '@mui/material';
import Tabs from 'components/Helper/Tabs';
import Detail from 'components/Modules/Detail';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { IDispatch } from 'interfaces/global';
import moment from 'jalali-moment';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { addOrderForOderBook } from 'services/reducer/user';
import { socket } from 'services/socket';
import { statusColor, typeColor } from 'utils/color';
import gather from 'utils/gather';
import { zeroFormat } from 'utils/numeral';
import { sideTranslate, statusOrder } from 'utils/translate';
import { isUserLogin } from 'utils/user';

const historyTabTitles = {
  open: 'سفارش های باز',
  history: 'تاریخچه تبادلات',
};

const Orders = ({ reload, isVoucher = false }: { reload: number, isVoucher: boolean }) => {
  const global = useGlobal();
  const user = useUser();
  const [orders, setOrders] = useState<null | []>(null);
  const dispatch = useDispatch<IDispatch>();
  const [historyTab, setHistoryTab] = useState(historyTabTitles.open);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(30, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));
  const [objSocket, setObjSocket]: any = useState({});

  const ordersRender = (isOpen: boolean) => {
    const rows: JSX.Element[] = [];

    orders
      ?.filter((e: any) => (isOpen ? e.status === 'pending' || e.status === 'waiting' : e.status !== 'pending' && e.status !== 'waiting'))
      .forEach((e: any, i) => {
        rows.push(
          <div key={i} onClick={() => dispatch(centerDialog(<Detail data={e} type={isVoucher ? "voucher" : "order"} />))}>

            <span className='w110 nameCoinOrder'>
              <span className='inlineFlex' >
                <span className='iconCoinPair'>
                  <img crossOrigin='anonymous' className='iconPair'
                    src={e.pair.toUpperCase() === "TMN" ? '/images/tmn.png' : global?.coins[e.pair]?.icon} />
                  <img crossOrigin='anonymous' className='iconCoin' src={global?.coins[e.coin?.symbol]?.icon} />
                </span>
                <i>{e?.coin?.symbol?.length > 5 ? e?.coin?.symbol?.slice(0, 4)?.toUpperCase() : e?.coin?.symbol.toUpperCase()}/<b className='color_gray'>{e.pair.toUpperCase()}</b></i>
              </span>
              <span className='ml_5 ltr textCenter font-10' style={{ margin: "auto", marginTop: "-10px" }}>
                {moment(e.createdAt).locale('fa').format('YYYY/MM/DD')}
              </span>
            </span>
            <span className={`w50 color${typeColor(e.side)}`}>{sideTranslate(e.side)} </span>
            <span className='f1 ltr textRight'>
              {zeroFormat(e.amount)}  {e.amount?.toString()?.split(".")[0]?.length > 7 ? "" : (e?.coin?.symbol?.length > 5 ? e?.coin?.symbol?.slice(0, 4)?.toUpperCase() : e?.coin?.symbol.toUpperCase())}
            </span>
            {isOpen
              && <span className='w40 ml_5 ltr textCenter'>
                {zeroFormat((e?.fill / e?.amount) * 100, 0, false)}%
              </span>
              // : <span className='ml_5 ltr textCenter font-10'>
              //   {moment(e.createdAt).locale('fa').format('YYYY/MM/DD')}
              // </span>
            }
            <span className='disFlex w60'>
              <i className={`boxStatus bg${statusColor(e.status)}`}>{statusOrder(e.status)}</i>
              {(e.status === 'pending' || e.status === 'waiting') && (
                <section
                  className='cancelOrder'
                  onClick={(o) => {
                    o.stopPropagation();
                    cancelAction(e._id);
                  }}
                ></section>
              )}
            </span>
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return (
      <div className='openOrder'>
        <div>
          <span className='w110'>ارز</span>
          <span className='w50'>نوع</span>
          <span className='f1'>مقدار</span>
          {/* <span className='w50 ml_5'>{isOpen ? "انجام" : "تاریخ"}</span> */}
          {isOpen &&
            <span className='w40 ml_5 textCenter'>انجام</span>}
          <span className='w60 textRight'>وضعیت</span>
        </div>
        {rows}
      </div>
    );
  };

  const cancelAction = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/order/${id}`, true).delete();

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('سفارش شما با موفقیت حذف شد'));
      init();
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const init = async () => {
    setOrders(null);
    const result = isVoucher
      ? await gather(`${config.data.api}/v1/order?sortBy=createdAt&orderBy=desc&limit=20&page=${pageCurrent}&fromDate=${from}&toDate=${to}&coins=${Object.keys(global.listVoucherObjID).toString().replaceAll(",", "-") ?? ""}`, true).get()
      : await gather(`${config.data.api}/v1/order?sortBy=createdAt&orderBy=desc&limit=20&page=${pageCurrent}&fromDate=${from}&toDate=${to}&-coins=${Object.keys(global.listVoucherObjID).toString().replaceAll(",", "-") ?? ""}`, true).get();

    if (result.code === 200) {
      setOrders(result.data ?? []);
      if (!isVoucher && historyTab === historyTabTitles.open) {
        handleOrderForOderBook(result?.data?.filter((e: any) => (e.status === 'pending' || e.status === 'waiting')))
      }
    } else {
      setOrders([]);
    }
  };

  useEffect(() => {
    if (isUserLogin()) {
      init();
    } else {
      setOrders([]);
    }
  }, [reload]);

  const handleOrderForOderBook = (data: any) => {
    if (!data || data?.length == 0) {
      dispatch(addOrderForOderBook({}))
      return
    }

    let obj = _.cloneDeep(user.orderForOderBook);
    Array.from(data)?.forEach((i: any) => {
      obj[i?.coin?.symbol + "-" + i?.side + "-" + i?.price] = { symbol: i?.coin?.symbol, price: i?.price, side: i?.side }
    });
    dispatch(addOrderForOderBook(obj))
  }


  const changeList = () => {
    if (!orders) {
      init();
      return
    }

    const newList: any = _.cloneDeep(orders);
    const index: any = Array.from(newList)?.findIndex((e: any) => e?._id == objSocket?.id);

    if (index != -1 && newList[index] && objSocket?.status == "pending") {
      newList[index].fill = objSocket?.fill;
      newList[index].status = objSocket?.status;
      setOrders(newList)
    } else {
      init();
    }
  }

  useEffect(() => {
    changeList()
  }, [objSocket]);

  const handleSocket = (result: any) => {
    setObjSocket(result);
  }

  useEffect(() => {
    handleSocket(global.orderSocketData)
    // socket.on('order', handleSocket);
    // return () => {
    //   socket.removeListener('order', handleSocket);
    // };
  }, [global.orderSocketData]);

  useEffect(() => {
    if (isVoucher && orders) {
      const found = Array.from(orders)?.find((element: any) => element?.status == "pending");
      if (!_.isEmpty(found)) {
        setTimeout(() => {
          isVoucher && init();
        }, 10000);
      }
    }
  }, [orders]);

  return (
    <div className='whiteBg buyExpress'>
      <div className='container'>
        <div className='upTabs'>
          <div className='tabs'>
            <Tabs titles={historyTabTitles} selected={historyTab} setSelected={setHistoryTab} />
          </div>
          <div
            className='refresh'
            onClick={() => {
              setOrders(null);
              init();
            }}
          >
            بروزرسانی
          </div>
        </div>
        <div className='items'>
          {orders ? (
            historyTab === historyTabTitles.open ? (
              ordersRender(true)
            ) : (
              ordersRender(false)
            )
          ) : (
            <div className='loadingItem'>
              <CircularProgress size={22} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
