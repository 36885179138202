import useGlobal from 'hooks/useGlobal';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { successAlert } from 'services/reducer/alert';
import { statusColor, typeColor } from 'utils/color';
import { zeroFormat, formatter } from 'utils/numeral';
import { sideTranslate, statusOrder, tradeTranslate } from 'utils/translate';

const Order = ({ data }: any) => {
  const global = useGlobal();
  const dispatch = useDispatch();

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) {}
  };

  return (
    <div className='voucherData'>
      <div className='listCashCode listCashCodeCustom'>
        <section>
          <div>
            <span>شناسه درخواست</span>
            <i className='copy' onClick={() => copy(data?.uniqueId)}></i>
            <span className='ltr'>{data?.uniqueId} </span>
          </div>
        </section>
        <section>
          <div>
            <span>شناسه سفارش</span>
            <i className='copy' onClick={() => copy(data?._id)}></i>
            <span className='ltr'>{data?._id} </span>
          </div>
        </section>
        <section>
          <div>
            <span>وضعیت</span>
            <span className={`boxStatus bg${statusColor(data.status)}`}>{statusOrder(data.status)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>تاریخ</span>
            <span className='ltr'>
              {moment(data.createdAt)
                .locale('fa')
                .format('YYYY-MM-DD , HH:mm:ss')}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>بازار</span>
            <span>{tradeTranslate(data.trade)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>سمت</span>
            <span className={`color${typeColor(data.side)}`}>{sideTranslate(data.side)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>درصد انجام</span>
            <span className='ltr'>
              {formatter(((data?.fill / data?.amount) * 100))}%
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>ارز</span>
            <span className='currencyDetail'>
              {data?.coin.name} ({data?.coin?.symbol.toUpperCase()})
              <img crossOrigin='anonymous' src={global?.coins[data?.coin?.symbol]?.icon} />
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مقدار</span>
            <span className='ltr'>
              {zeroFormat(data.amount)} {data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مقدار انجام شده</span>
            <span className='ltr'>
              {zeroFormat(data?.fill)}  {data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>کارمزد</span>
            <span className='ltr'>
              {zeroFormat(data.fee.amount)} {data.side === 'sell' ? data.pair.toUpperCase() : data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مقدار نهایی</span>
            <span className='ltr'>
              {zeroFormat(data.final)} {data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        {data?.stop
          ? <section>
            <div>
              <span>حد ضرر</span>
              <span className='ltr'>
                {zeroFormat(data.stop)} {data.pair.toUpperCase()}
              </span>
            </div>
          </section>
          : ""}
        <section>
          <div>
            <span>قیمت</span>
            <span className='ltr'>
              {zeroFormat(data.price)} {data.pair.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مجموع {sideTranslate(data.side)}</span>
            <span className='ltr'>
              {zeroFormat(data.total)} {data.pair.toUpperCase()}
            </span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Order;
