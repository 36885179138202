import Tabs from 'components/Helper/Tabs';
import { EPair } from 'enums/global';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IPairProps } from 'interfaces/global';
import { invert } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { priceColor } from 'utils/color';
import { zeroFormat, formatter } from 'utils/numeral';

const tabTitles = {
  market: 'محدوده بازار',
  category: 'دسته بندی',
  fav: 'مورد علاقه',
};

const marketTabTitles = {
  all: 'همه',
  tmn: 'تومان',
  usdt: 'تتر',
};

const marketTabTitles2 = {
  tmn: 'تومان',
};

const categoryTabTitles = {
  all: 'همه',
  ai: 'هوش مصنوعی',
  fan: 'فن توکن',
  exchange: 'صرافی',
  game: 'بازی',
  web3: 'وب۳',
  contract: 'قرارداد هوشمند',
  nft: 'ان اف تی',
  meme: 'میم',
  metaverse: 'متاورس',
  defi: 'دیفای',
  best: 'برترها',
};

const Pairs = ({ pair, setCoin, setPair, trade,isMarket }: IPairProps) => {
  const dispatch = useDispatch();
  const global = useGlobal();
  const ticker = useTicker();
  const user = useUser();
  const [text, setText] = useState('');
  const [tab, setTab] = useState(tabTitles.market);
  const [marketTab, setMarketTab] = useState(marketTabTitles.tmn);
  const [categoryTab, setCategoryTab] = useState(categoryTabTitles.all);
  const navigate = useNavigate();

  const coinListRender = () => {
    const rows: JSX.Element[] = [];

    Object.values(global?.coins)
      .filter((e) => e.isActive)
      .filter((e) => !Object.keys(global.listVoucherSymbol)?.includes(e.symbol))
      .filter((e) => e.name?.includes(text) || e.nameFa?.includes(text) || e.symbol?.includes(text))
      .filter((e) => (tab === tabTitles.fav ? user.favorite.includes(e._id) : e.symbol))
      .filter((e) => (tab === tabTitles.category && categoryTab !== categoryTabTitles.all ? e.category?.includes(invert(categoryTabTitles)[categoryTab]) : e))
      .forEach((e, i) => {
        Object.entries(e.pair)
          .filter(([k, v]) => v[trade] && pair.includes(k))
          .filter(([k, v]) => (tab !== tabTitles.category && marketTab !== marketTabTitles.all ? invert(marketTabTitles)[marketTab] === k : e))
          .forEach(([k, _v], j) => {
            rows.push(
              <div
                key={`${i}_${j}`}
                onClick={() => {
                  if (e?.isActive) {
                    setCoin(e);
                    setPair && setPair(k as EPair);
                    dispatch(hideDrawer());
                    if(isMarket){
                      navigate("/order/market/" + e?.symbol?.toUpperCase() + "-" + k?.toUpperCase() as EPair)
                    }else{
                      navigate("/order/fast/" + e?.symbol?.toUpperCase())
                    }
                  } else {
                    dispatch(errorAlert("ارز مورد نظر غیرفعال می باشد"));
                  }
                }}
              >
                <img crossOrigin='anonymous' src={e.icon} />
                <section>
                  <h2>
                    {e.nameFa}/{(marketTabTitles as any)[k]}
                    {/* {e.name} <span>({e.symbol.toUpperCase()})</span> */}
                  </h2>
                  <h3>{e.symbol.toUpperCase()}</h3>
                </section>
                <section>
                  <i className={`boxStatus percent bg${priceColor(ticker.change(e.symbol + k))}`}>{zeroFormat(ticker.change(e.symbol + k))}%</i>
                  <p>{formatter(ticker.price(e.symbol + k), e.pair[k as EPair].precision)}</p>
                </section>
              </div>,
            );
          });
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  return (
    <div className='popup colorForm pairsDrawer'>
      <div className='registerForm registerFormStart'>
        <div className='parentPairs'>
          <div className='rightArrow rightArrowPad' onClick={() => dispatch(hideDrawer())}></div>
          <div className='search searchPrice'>
            <input type='text' className='ltr textRight' onChange={(e) => setText(e.target.value)} placeholder='جستجوی ارز' />
          </div>
        </div>
        <div className='tabsPrice tabsPricePair'>
          <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
        </div>
        {tab !== tabTitles.category ? (
          <div className='tabsPair'>
            <Tabs titles={isMarket?marketTabTitles:marketTabTitles2} selected={marketTab} setSelected={setMarketTab} />
          </div>
        ) : (
          <div className='tabsPair tabsPairFull'>
            <Tabs titles={categoryTabTitles} selected={categoryTab} setSelected={setCategoryTab} />
          </div>
        )}
        <div className='listOfPair'>{coinListRender()}</div>
      </div>
    </div>
  );
};

export default Pairs;
