import Tabs from 'components/Helper/Tabs';
import Bottom from 'components/Layouts/Bottom';
import Header from 'components/Layouts/Header';
import Orders from 'components/Layouts/Orders';
import PreLogin from 'components/Layouts/PreLogin';
import VerifyList from 'components/Layouts/VerifyList';
import Detail from 'components/Modules/Detail';
import config from 'config';
import useBalance from 'hooks/useBalance';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Slider from 'react-input-slider';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { zeroFormat, toFixed } from 'utils/numeral';
import { numbersToEn } from 'utils/tools';
import { digitalName } from 'utils/translate';
import { isUserLogin } from 'utils/user';

const sideTabTitles = {
  buy: 'خرید',
  sell: 'فروش',
};

const Digital = () => {
  const dispatch = useDispatch();
  const global = useGlobal();
  const ticker = useTicker();
  const balance = useBalance();
  const [coin, setCoin] = useState(Object.values(global?.coins).filter((e) => Object.keys(global.listVoucherSymbol)?.includes(e.symbol))[0]);
  const [focus, setFocus] = useState('payer');
  const [payer, setPayer] = useState(0);
  const [payee, setPayee] = useState(0);
  const [sideTab, setSideTab] = useState(sideTabTitles.buy);
  const [reload, setReload] = useState(0);
  const [range, setRange] = useState({ x: 0 });
  const [code, setCode] = useState("");
  const side = sideTab === sideTabTitles.buy ? 'buy' : 'sell';
  const userAsset = balance[sideTab === sideTabTitles.buy ? 'tmn' : coin?.symbol];
  const currentPrice = ticker[side](coin?.symbol + 'tmn');

  const resetInput = () => {
    setCode("");
    setPayer(0);
    setPayee(0);
    setRange({ x: 0 });
  };

  const coinListRender = () => {
    const rows: JSX.Element[] = [];

    const data = Object.values(global?.coins)
      .filter((e) => Object.keys(global.listVoucherSymbol)?.includes(e.symbol))
      .filter((e) => e.pair.tmn.otc)
      .splice(0, 3);

    if (!data.find((e) => e === coin)) {
      data[0] = coin;
    }

    data.forEach((e, i) => {
      rows.push(
        <div key={i} className={`${coin?.symbol === e.symbol ? 'active' : ''} ${data?.length < 3 &&"mxW50"}`}
        onClick={() =>{e?.isActive?setCoin(e):dispatch(errorAlert('ارز مورد نظر غیر فعال می باشد'));} }>
          <img crossOrigin='anonymous' src={e.icon} />
          <h2>
            {e.name} <span>({e.symbol.toUpperCase()})</span>
          </h2>
          <h3>{digitalName[e.symbol.toLocaleLowerCase()]}</h3>
        </div>,
      );
    });

    return rows;
  };


  const buy = async () => {
    if (_.isEmpty(coin)) {
      dispatch(errorAlert('لطفا یک ارز را انتخاب کنید'));
      return
    }

    if (!Number(numbersToEn(payee?.toString()))||Number(numbersToEn(payee?.toString())) <= 0) {
      dispatch(errorAlert('مقدار را وارد کنید'));
      return
    }

    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/order/voucher/buy`, true).post({
      "coin": coin?._id,
      "network": coin?.networks[0]?.network?._id,
      "pair": "tmn",
      "amount": Number(numbersToEn(payee?.toString())),
    });

    if (result.message === 200) {
      dispatch(centerDialog(<Detail type={'voucher'} data={result.data} />));
      setReload(reload + 1);
      resetInput();
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    dispatch(hideLoading());
  }

  const sell = async () => {

    const myvouCode=numbersToEn(code?.trim().toString());

    if (_.isEmpty(coin)) {
      dispatch(errorAlert('لطفا یک ارز را انتخاب کنید'));
      return
    }

    if (myvouCode?.trim()?.length == 0) {
      dispatch(errorAlert('کد را وارد کنید'));
      return
    }

    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/order/voucher/sell`,true).post({
      "coin": coin?._id,
      "network": coin?.networks[0]?.network?._id,
      "pair": "tmn",
      "code":myvouCode,
    })

    if (result.message === 200) {
      dispatch(centerDialog(<Detail type={'voucher'} data={result.data} />));
      dispatch(successAlert('سفارش شما با موفقیت ثبت شد'));
      setReload(reload + 1);
      resetInput();

    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    dispatch(hideLoading());
  }

  const handleSliderBar = (num: any) => {
    setFocus('range');
    setRange(num);
    const myPayer=toFixed((userAsset.balance * num.x) / 100, sideTab === sideTabTitles.buy ? coin.pair.tmn.precision : coin.pair.usdt.precision)
    setPayer(toFixed(myPayer));
    if (sideTab === sideTabTitles.buy) {
      setPayee(toFixed(myPayer / currentPrice, coin.pair.usdt.precision));
    } else {
      setPayee(toFixed(myPayer * currentPrice, coin.pair.tmn.precision));
    }

  }

  useEffect(() => {
    if (focus === 'payer') {
      if (sideTab === sideTabTitles.buy) {
        setPayee(toFixed(payer / currentPrice, coin.pair.usdt.precision));
      } else {
        setPayee(toFixed(payer * currentPrice, coin.pair.tmn.precision));
      }
      const pers = (Number(payer / userAsset.balance) * 100);
      setRange({ x: pers > 100 ? 100 : pers });
    }
  }, [payer]);

  useEffect(() => {
    if (focus === 'payee') {
      if (sideTab === sideTabTitles.sell) {
        setPayer(toFixed(payee / currentPrice, coin.pair.usdt.precision));
      } else {
        setPayer(toFixed(payee * currentPrice, coin.pair.tmn.precision));
      }
      const total = sideTab === sideTabTitles.sell
      ? Number(payee) / Number(currentPrice ?? 0)
      : Number(payee) * Number(currentPrice ?? 0)
    const pers = (Number(total / userAsset.balance) * 100);
    setRange({ x: pers > 100 ? 100 : pers });
    }
  }, [payee]);

  // useEffect(() => {
  //   setFocus('payer');
  //   setPayer(toFixed((userAsset.balance * range.x) / 100, sideTab === sideTabTitles.buy ? coin.pair.tmn.precision : coin.pair.usdt.precision));
  // }, [range]);

  useEffect(() => {
    resetInput();
  }, [sideTab, coin]);

  // useEffect(() => {
  //   setTimeout(async () => {
  //     const success = searchParams.get('success');
  //     if (success) {
  //       dispatch(showLoading());

  //       const amount = searchParams.get('amount');
  //       const coin = searchParams.get('coin');

  //       ////// GET DATA FROM ID FOR FINAL AMOUNT!!!!!!!

  //       const resultOrder = await gather(`${config.data.api}/v1/order`, true).post({
  //         coin: coin,
  //         price: currentPrice,
  //         pair: 'tmn',
  //         side: 'sell',
  //         trade: 'otc',
  //         amount: amount,
  //       });

  //       if (resultOrder.code === 200) {
  //         dispatch(successAlert('سفارش شما با موفقیت ثبت شد'));
  //         setReload(reload + 1);
  //         resetInput();
  //       } else {
  //         dispatch(errorAlert(config.errors[resultOrder?.message] || config.errors[99999]));
  //       }

  //       dispatch(hideLoading());

  //       searchParams.delete('amount');
  //       searchParams.delete('hash');
  //       searchParams.delete('success');
  //       searchParams.delete('message');
  //       searchParams.delete('coin');

  //       setSearchParams(searchParams);
  //     }
  //   }, 10);

  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      <Header title={'ارز دیجیتال'} />
      <VerifyList />
      <div className='whiteBg buyExpress'>
        <div className='container'>
          <div className='step stepMargin'>
            <span></span>
            <h3>
              مرحله اول <i>نوع ارز</i>
            </h3>
            <div></div>
          </div>
          <div className='stepBelow'>ارز موردنظر را انتخاب کنید</div>
          <div className='coinList'>{coinListRender()}</div>
          <div className='step step2'>
            <span></span>
            <h3>
              مرحله دوم <i>نوع معامله</i>
            </h3>
            <div></div>
          </div>
          <div className='stepBelow'>نوع معامله را مشخص کنید</div>
          <div className='sellBuy'>
            <Tabs titles={sideTabTitles} selected={sideTab} setSelected={setSideTab} />
          </div>
          <div className='formFast'>
            <div className='payed'>
              <img crossOrigin='anonymous' src={sideTab === sideTabTitles.buy ? '/images/tmn.png' : coin.icon} />
              <NumericFormat
                inputMode='decimal'
                className='ltr textRight'
                value={payer > 0 ? payer : ''}
                onFocus={() => setFocus('payer')}
                allowNegative={false}
                thousandSeparator={true}
                onValueChange={(e) => setPayer(Number(e.value))}
                placeholder='پرداخت می‌کنم (اینجا وارد نمایید)'
              />
            </div>
            <div className='convert' onClick={() => (sideTab === sideTabTitles.buy ? setSideTab(sideTabTitles.sell) : setSideTab(sideTabTitles.buy))}></div>
            <div className='payed'>
              <img crossOrigin='anonymous' src={sideTab === sideTabTitles.sell ? '/images/tmn.png' : coin.icon} />
              <NumericFormat
                inputMode='decimal'
                className='ltr textRight'
                value={payee > 0 ? payee : ''}
                onFocus={() => setFocus('payee')}
                allowNegative={false}
                thousandSeparator={true}
                onValueChange={(e) => setPayee(Number(e.value))}
                placeholder='دریافت می‌کنم (اینجا وارد نمایید)'
              />
            </div>

            <div className='yourBalance'>
              {sideTab === sideTabTitles.buy && (
                <div onClick={() => {
                  setFocus("payer");
                  userAsset.balance > 0 && setPayer(userAsset.balance)
                }}>
                  موجودی:{' '}
                  <i>
                    {zeroFormat(isUserLogin() ? userAsset.balance : 0)} {sideTab === sideTabTitles.buy ? 'TMN' : coin?.symbol.toUpperCase()}
                  </i>
                </div>
              )}
              <div>
                قیمت: <i>{zeroFormat(currentPrice)} TMN</i>
              </div>
            </div>
            <div className='runRange'>
              <Slider
                x={range.x}
                xreverse={true}
                styles={{
                  track: {
                    backgroundColor: '#DFDFDF',
                  },
                  active: {
                    backgroundColor: '#2424DA',
                  },
                  thumb: {
                    backgroundColor: '#2424DA',
                    boxShadow: 'none',
                  },
                }}
                onChange={handleSliderBar}
              />
              <span style={{ width: range.x + '%' }}></span>
              <section>
                <i>0%</i>
                <i>25%</i>
                <i>50%</i>
                <i>75%</i>
                <i>100%</i>
              </section>
            </div>
          </div>

          {sideTab === sideTabTitles.sell 
          // && coin?.symbol === 'pusd' 
          && (
            <>
          <div className='step step2'>
            <span></span>
            <h3>
              مرحله سوم <i>کد ووچر</i>
            </h3>
            <div></div>
          </div>
          <div className='stepBelow'>کد ووچر را وارد نمایید</div>
          <div className='voucherCodeInput'>
            <input type='text' value={code} onChange={(e) => setCode(e.target.value)} placeholder='لطفا کد ووچر خود را اینجا وارد کنید ' />
            {/* <input type='number' inputMode='numeric' value={code[1]} onChange={(e) => setCode([code[0], e.target.value])} placeholder='کد ۱۶ رقمی' /> */}
          </div>
          </>
          )}

          {/* {sideTab === sideTabTitles.buy && coin?.symbol !== 'pusd' && (
            <>
              <div className='step step2'>
                <span></span>
                <h3>
                  مرحله سوم <i>آدرس دریافت کننده</i>
                </h3>
                <div></div>
              </div>
              <div className='stepBelow'>آدرس/ای دی دریافت کننده را وارد نمایید</div>
              <div className='voucherCodeInput'>
                <input type='text' value={receiver} onChange={(e) => setReceiver(e.target.value)} placeholder='U XXXXXX' />
              </div>
            </>
          )} */}

          <div className='formFast'>
            <div
              className='agreePay'
              onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) :
                // coin?.symbol === 'pusd' ? pmvAction() : orderAction()
                sideTab === "خرید" ? buy() : sell()
              )}
            >
              با تایید {sideTab}، قوانین و مقررات را می پذیرم
              <i>تایید {sideTab}</i>
            </div>
          </div>
        </div>
      </div>
      <Orders reload={reload} isVoucher={true} />
      <Bottom />
    </>
  );
};

export default Digital;
