import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { HighlightScope, legendClasses } from '@mui/x-charts';

const DonatChart = ({ data }: any) => {
  const [highlighted, setHighlighted] = React.useState('item');
  const [faded, setFaded] = React.useState('global');

  const pieChartsParams = {
    series: [
      {
        data: data,
        innerRadius: 90,
        outerRadius: 55,
        paddingAngle: 0,
      },
    ],
    height: 186,
    width: 186,

  };

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format;

  const otherProps = {
    width: 400,
    height: 200,
    sx: {
      [`.${legendClasses.root}`]: {
        transform: 'translate(20px, 0)',
      },
    },
  };

  const data2 = [
    { team: 'Amber Ants', rank: 3, points: 31 },
    { team: 'Eagle Warriors', rank: 1, points: 50 },
    { team: 'Elephant Trunk', rank: 4, points: 18 },
    { team: 'Jaguars', rank: 2, points: 37 },
    { team: 'Smooth Pandas', rank: 5, points: 6 },
  ];

  return (
    <PieChart
      {...pieChartsParams}
      series={pieChartsParams.series.map((series: any) => ({
        ...series,
        valueFormatter: (v: any): any => {
          if (v.label == "دارایی") {
            return ` ${0}`;
          } else {
            return ` ${v.value} $`;
          }
        },
        highlightScope: {
          highlighted,
          faded,
        } as HighlightScope,
      }))}
      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
      slotProps={{
        legend: {
          hidden: true,
        },
      }}
    />
  );
}
export default DonatChart