import config from 'config';
import { IForm } from 'interfaces/password';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import Register from '../Register';

const Form = ({ mobile, setMobile, setStep }: IForm) => {
  const dispatch = useDispatch();

  const loginAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/auth/forgetPassword`).post({
      mobile: mobile.startsWith('0') ? mobile : '0' + mobile,
    });

    if (result.code === 200) {
      setStep(2);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  return (
    <>
      <p className='inTitle'>بازیابی رمز عبور</p>
      <p className='inAlarm'>لطفا فقط با آدرس زیر وارد شوید</p>
      <div className='domain'>
        <i>https://</i>pwa.exnovin.net
      </div>
      <div className='mainInput'>
        <p>شماره همراه</p>
        <div>
          <span></span>
          <input type='phone' inputMode='tel' placeholder='+98' onChange={(e) => setMobile(e.target.value)} />
        </div>
      </div>
      <div className='largeButton' onClick={() => loginAction()}>
        ارسال کد تایید
      </div>
      <div
        className='haveAccount'
        onClick={() => {
          dispatch(hideDrawer());
          setTimeout(() => dispatch(fullDrawer(<Register />)), 300);
        }}
      >
        <i  className='colorText'>حساب کاربری ندارید؟</i>
        <p>ثبت نام کنید</p>
      </div>
    </>
  );
};

export default Form;
