import config from 'config';
import { IDispatch } from 'interfaces/global';
import { ICard } from 'interfaces/identity';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';
import heic2any from 'heic2any';

const Document = ({ setStep }: ICard) => {
  const dispatch = useDispatch<IDispatch>();
  const [src, setSrc] = useState('');
  const [docName, setDocName] = useState("اینجا کلیک کنید یا فایل را بکشید");

  const saveAction = async (file: any) => {
    setDocName("اینجا کلیک کنید یا فایل را بکشید")
    dispatch(showLoading());

    const formData = new FormData();
    formData.append('type', 'document');
    formData.append('doc', file);

    const result = await gather(`${config.data.api}/v1/file`, true).upload(formData);
    if (result.code === 200) {
      setDocName(file?.name??"فایل با موفقیت ارسال شد")
      setSrc(result.data.link);
      await dispatch(getProfile());
      setTimeout(() => {
        dispatch(hideLoading());
      }, 3000);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      dispatch(hideLoading());
      setDocName("خطا در ارسال دوباره تلاش کنید")
    }

  };

  // const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // const file = event.target.files?.[0];
  //   const file:any = event;

  //   if (file && file.type.startsWith('image/')) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.src = e.target?.result as string;

  //       img.onload = async () => {
  //         const canvas = document.createElement('canvas');
  //         const maxWidth = 800;
  //         const scaleFactor = maxWidth / img.width;
  //         const width = Math.min(maxWidth, img.width);
  //         const height = img.height * scaleFactor;

  //         canvas.width = width;
  //         canvas.height = height;

  //         const ctx = canvas.getContext('2d');
  //         ctx?.drawImage(img, 0, 0, width, height);

  //         canvas.toBlob(
  //           async (blob) => {
  //             if (blob) {
  //               // setFileImage(blob)
  //               saveAction(blob)
  //             }
  //           },'image/jpeg',  0.6 );
  //       };
  //     };
  //   }
  // };

  const handleImageUpload = async (event: any) => {
    const file = event;

    if (!file || !file.type.startsWith('image/')) return;

    let convertedFile = file;

    // **تبدیل HEIC به JPEG**
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      try {
        const blob = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.8 });
        convertedFile = new File([blob as Blob], file.name.replace(/\.\w+$/, '.jpg'), { type: 'image/jpeg' });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return;
      }
    }

    const reader = new FileReader();
    reader.readAsDataURL(convertedFile);

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 1024;  // **حداکثر عرض تصویر**
        const maxHeight = 1024; // **حداکثر ارتفاع تصویر**
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const scaleFactor = Math.min(maxWidth / width, maxHeight / height);
          width = Math.floor(width * scaleFactor);
          height = Math.floor(height * scaleFactor);
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // **فشرده‌سازی و ارسال**
        let quality = 0.8; // کیفیت اولیه
        let blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'image/jpeg', quality)
        );

        // **کاهش حجم تا زیر 600 کیلوبایت**
        while (blob && blob.size > 600 * 1024 && quality > 0.2) {
          quality -= 0.1;
          blob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(resolve, 'image/jpeg', quality)
          );
        }

        if (blob) {
          saveAction(blob);
        }
      };
    };
  };

  return (
    <>
      <div className='emailLogo smsLogo smsLogoNew docNew'></div>
      <div className='rules rulesCenter'>
        لطفا یک کارت شناسایی خود را بارگذاری نمایید.
        <br />« کارت ملی یا گواهینامه یا شناسنامه »
      </div>
      <div className='uploadPicture fileUploader'>
        {src && <img src={src} alt='' />}
        <FileUploader handleChange={handleImageUpload} defaultValue='' value='' name='fileImage' types={['JPG', 'PNG', 'JPEG']} />
        <span></span>
        <i>{docName}</i>
      </div>
      <div className='largeButton' onClick={() => setStep(3)}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Document;
