import { CircularProgress } from '@mui/material';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import moment from 'jalali-moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { statusColor, typeColor } from 'utils/color';
import gather from 'utils/gather';
import { zeroFormat } from 'utils/numeral';
import { digitalName, sideTranslate, statusVoucher } from 'utils/translate';

const Voucher = ({ data }: any) => {
  const global = useGlobal();
  const dispatch = useDispatch();
  const newCode = data?.code ?? data?.data?.code;
  const [myLoading, setMyLoading] = useState(false);

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) { }
  };

  const checkVoucher = async () => {
    setMyLoading(true);

    let body: any = {
      "coin": data?.coin?._id,
      // "network": listNetworkObj["uusd"]?._id ?? 0,
      "code": newCode?.split("-")[0]
    }

    const result = await gather(`${config.data.api}/v1/voucher/check`, true).post(body)

    if (result.message === 200) {
      // dispatch(DialogShow.show2(<CheckVoucher data={result?.data} myData={data} close={() => dispatch(DialogShow.hide2())} />));
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    setMyLoading(false);

  }

  return (
    <div className='voucherData'>
      {/* <div className='listCashCode listCashCodeCustom'> */}
      {/* <img crossOrigin='anonymous' src={global?.coins[data?.coin?.symbol]?.icon} />
        <h2>
          {data?.coin.name} ({data?.coin?.symbol.toUpperCase()})
        </h2> */}
      <div className='listCashCode listCashCodeCustom'>
        <section>
          <div>
            <span>شناسه درخواست</span>
            <i className='copy' onClick={() => copy(data?.uniqueId)}></i>
            <span className='ltr'>{data?.uniqueId} </span>
          </div>
        </section>
        <section>
          <div>
            <span>شناسه سفارش</span>
            <i className='copy' onClick={() => copy(data?._id)}></i>
            <span className='ltr'>{data?._id} </span>
          </div>
        </section>
        <section>
          <div>
            <span>وضعیت</span>
            <span className={`color${statusColor(data.status)}`}>{statusVoucher(data.status)}</span>
          </div>
        </section>
        <div className='vouBox'>
          <div className='brBox'></div>
          <section>
            <div>
              <span>کد ووچر :  </span>
            </div>
          </section>
          <section className='bx1'>
            <div onClick={() => data?.data?.code && copy(data?.data?.code)}>
              <span> </span>
              <i></i>
              <span className='t1'>{data?.data?.code ?? "در حال آماده سازی"}</span>
            </div>
          </section>
        </div>
        <section>
          <div>
            <span>تاریخ</span>
            <span className='ltr'>{moment(data.createdAt).locale('fa').format('YYYY-MM-DD , HH:mm:ss')}</span>
          </div>
        </section>
        <section>
          <div>
            <span>سمت</span>
            <span className={`color${typeColor(data.side)}`}>{sideTranslate(data.side)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>ارز</span>
            <span className='currencyDetail'>
              {digitalName[data?.coin?.symbol.toLocaleLowerCase()] ?? data?.coin?.symbol.toUpperCase()}
              <img crossOrigin='anonymous' src={global?.coins[data?.coin?.symbol]?.icon} />
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مقدار</span>
            <span className='ltr'>
              {zeroFormat(data.amount)} {data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        {data && <>
          <section>
            <div>
              <span>قیمت</span>
              <span className='ltr'>
                {zeroFormat(data?.price)} {data?.pair}
              </span>
            </div>
          </section>
          <section>
            <div>
              <span>کارمزد</span>
              <span className='ltr'>
                {zeroFormat(data?.fee?.amount ?? 0)} {data?.coin?.symbol.toUpperCase()}
              </span>
            </div>
          </section>
          <section>
            <div>
              <span>مجموع</span>
              <span className='ltr'>
                {zeroFormat(data?.total)} {data?.pair}
              </span>
            </div>
          </section>
        </>}
        {/* <section>
          <div>
            <span>نهایی</span>
            <span className='ltr'>
              {zeroFormat(data.final)} {data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section> */}

        {/* <section>
            <div onClick={() => copy(code[1])}>
              <span>کد فعالسازی (16 رقمی)</span>
              <i></i>
              <span>{code ? code[1] : "---"}</span>
            </div>
          </section> */}
        {/* {(data?.type == "deposit" && data?.coin?.symbol?.toUpperCase() == "PUSD") &&
            myLoading
            ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
            : <button className='btnShowMore voucherBtn' onClick={checkVoucher}>استعلام</button>} */}

      </div>
      {/* </div> */}
    </div>
  );
};

export default Voucher;
