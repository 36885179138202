import { useDispatch } from 'react-redux';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import Top from './Layouts/Top';
import { IDispatch } from 'interfaces/global';
import gather from 'utils/gather';
import config from 'config';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { getProfile } from 'services/reducer/user';
import { hideLoading, showLoading } from 'services/reducer/loading';
import moment from 'jalali-moment';
import { hideDrawer } from 'services/reducer/drawer';
import { numbersToEn } from 'utils/tools';


const AddNationalAndBirthday = () => {
  const user = useUser();
  const [name, setName] = useState(user?.profile?.name ?? '');
  const [family, setFamily] = useState(user?.profile?.family ?? '');
  const [nationalCode, setNationalCode] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const dispatch = useDispatch<IDispatch>();

  const daysRender = (from: number, to: number) => {
    const rows: JSX.Element[] = [];
    for (let i = from; i <= to; i++) {
      rows.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return rows;
  };

  const saveAction = async () => {
    if (name?.trim().length < 3) {
      dispatch(errorAlert("نام خود را وارد کنید"));
      return
    }
    if (family?.trim().length < 3) {
      dispatch(errorAlert("نام خانوادگی خود را وارد کنید"));
      return
    }

    if (numbersToEn(nationalCode).length !== 10) {
      dispatch(errorAlert("کد ملی را وارد کنید"));
      return
    }

    if (year.length == 0 || month.length == 0 || day.length == 0) {
      dispatch(errorAlert("تاریخ تولد را وارد کنید"));
      return
    }
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/verify`, true).post({
      name: name?.trim(),
      family: family?.trim(),
      address: user?.profile?.address ?? "",
      nationalCode: numbersToEn(nationalCode),
      birthDate: moment.from(year + '-' + month + '-' + day, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD'),
    });


    if (result.code === 200) {
      await dispatch(getProfile());
      dispatch(hideDrawer());
      dispatch(hideDrawer());
      dispatch(successAlert("ثبت با موفقیت انجام شد"));
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
    dispatch(hideLoading());
  };
  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'احراز هویت'} />
        <div className='container inData'>
          <h4 className='mt_25'>با توجه به نقص اطلاعات حساب کاربری شما ، جهت تکمیل لطفا کد ملی و تاریخ تولد خود را وارد کنید.</h4>
          <div className='mainInput'>
            <p>نام</p>
            <div>
              <span className='userprofile'></span>
              <input type='text' inputMode='text' value={name} onChange={(e) => setName(e.target.value)} />
            </div>
          </div>
          <div className='mainInput'>
            <p>نام خانوادگی</p>
            <div>
              <span className='userprofile'></span>
              <input type='text' inputMode='text' value={family} onChange={(e) => setFamily(e.target.value)} />
            </div>
          </div>
          <div className='mainInput'>
            <p>کد ملی</p>
            <div>
              <span className='id'></span>
              <input type='number' inputMode='numeric' value={nationalCode} onChange={(e) => setNationalCode(e.target.value)} />
            </div>
          </div>
          <div className='mainInput birthdate'>
            <p>تاریخ تولد</p>
            <div>
              <span className='birthday'></span>
              <select onChange={(e) => setYear(e.target.value)}>
                <option>سال</option>
                {daysRender(1330, 1385)}
              </select>
              <select onChange={(e) => setMonth(e.target.value)}>
                <option>ماه</option>
                {daysRender(1, 12)}
              </select>
              <select onChange={(e) => setDay(e.target.value)}>
                <option>روز</option>
                {daysRender(1, 31)}
              </select>
            </div>
          </div>
          <div className='spacer'></div>
          <div className='largeButton' onClick={() => saveAction()}>
            ثبت
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNationalAndBirthday;
