import Top from 'components/Layouts/Top';
import { useLocalStorage } from 'usehooks-ts';

const Goftino = () => {
  const [theme, setTheme] = useLocalStorage('themePWAEX', "light");

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'پشتیانی آنلاین'} />
        <div className='container2 inData2'>
          <iframe
            src={theme == "dark" ? "https://exnovin.net/dl/api/live-dark.html" : "https://exnovin.net/dl/api/live.html"}
            // srcDoc={`<html><head><style>body{margin:0;padding:0;overflow:hidden;}</style></head><body><iframe src='${theme=="dark"?"https://exnovin.net/dl/api/live-dark.html":"https://exnovin.net/dl/api/live.html"}' style='width:100%; height:100vh; border:none;'></iframe></body></html>`}
            className="support-iframe"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Goftino;
