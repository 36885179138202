import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import useTimer from 'hooks/useTimer';
import { ICode } from 'interfaces/email';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { numbersToEn } from 'utils/tools';

const Code = ({ setStep }: ICode) => {
  const [code, setCode] = useState<any>('');
  const dispatch = useDispatch();
  const timer = useTimer();

  const confirmAction = async (code1:any) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/email/confirm`, true).post({
      code: numbersToEn(code1?.toString()),
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setStep(3);
    } else {
      setCode([]);
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    timer.start();
  }, []);

  return (
    <>
      <h4 className='confirmTitle confirmTitleIn'>کد تایید ایمیل قدیم</h4>
      <div className='rules'>
        کد تایید برای ایمیل<i>xxx</i>ارسال شد
      </div>
      <div className='codeNumberBox'>
        <VerificationInput
          onComplete={(e) => confirmAction(e)}
          onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          value={code}
          placeholder='-'
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
      </div>
      <div className='changeMobile'>
        <i className="colorText">
          {!timer.isFinish ? (
            <>
              {timer.minute ? `${timer.minute} دقیقه و ` : ''}
              {timer.second} ثانیه
            </>
          ) : (
            <div
              className='active'
              onClick={() => {
                // startTimer();
              }}
            >
              ارسال مجدد کد
            </div>
          )}
        </i>
      </div>
      <div className='spacer'></div>
      <div className='largeButton' onClick={() => confirmAction(code)}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Code;
