import useGlobal from 'hooks/useGlobal';
import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';
import { useLocalStorage } from 'usehooks-ts';
import { toFixed, zeroFormat } from 'utils/numeral';


const DialogPriceOrder = ({ buyOrSellAction, orderBookPrice, userPric, side, pair }: any) => {
  const dispatch = useDispatch();
  const [confiemOrder, setConfiemOrder] = useLocalStorage('confiemPriceOrder', "false");

  const dontShowDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfiemOrder(event.target.checked + "")
  }

  return (
    <div className='popup'>
      <div className='closePop closePopWhite' onClick={() => dispatch(hideDialog())}></div>
      <div className='detailHistory'>
        <div className='headerBox'></div>
        <div className='voucherData'>
          <div className='listCashCode listCashCodeCustom'>
            <section>
              <div>
                <span >⚠️ قیمت پیشنهادی شما {side == "buy" ? "پایین‌تر" : "بالاتر"} از قیمت {side == "buy" ? "فروشندگان" : "خریداران"} است</span>
              </div>
            </section>
            <section>
              <div>
                <span >در حال حاضر، هیچ {side == "buy" ? "فروشنده‌ای" : "خریداری"} با قیمت <span >{zeroFormat(userPric)} {pair?.toUpperCase()}</span> در دسترس نیست.</span>
              </div>
            </section>
            <section>
              <div>
                <span >💡 <span >بهترین قیمت  {side == "buy" ? "فروش" : "خرید"} :</span> <span style={{ color: "#ff9800", fontWeight: "500", fontSize: 13 }}>{zeroFormat(orderBookPrice)} {pair?.toUpperCase()}</span></span>
              </div>
            </section>
            <section>
              <div>
                <span >✅ برای انجام سریع سفارش، قیمت را به <span >{zeroFormat(orderBookPrice)} {pair?.toUpperCase()}</span> یا {side == "buy" ? "بالاتر" : "پایین‌تر "} تنظیم کنید. در غیر این صورت، سفارش شما باز می‌ماند تا زمانی که {side == "buy" ? "فروشنده‌ای" : "خریداری"} با قیمت پیشنهادی شما پیدا شود.</span>
              </div>
            </section>
          </div>
        </div>
        <div className='borderHex'></div>
        <div className='checkBoxConfirmBuySellFast'>
          <input className='checkbox' onChange={dontShowDialog} type="checkbox" name="" id="checkId" />
          <label htmlFor="checkId">این تاییدیه را در معاملات بعد نشان نده</label>
        </div>
        <div className='btnsConfirmBuySellFast'>
          <button className="btnShowMore voucherBtn" onClick={() => {
            dispatch(hideDialog());
            buyOrSellAction();
          }} > تایید  </button>
          <button className=" btnShowMore voucherBtn btnCancel" onClick={() => dispatch(hideDialog())} > لغو  </button>
        </div>
      </div>
    </div>
  );
};

export default DialogPriceOrder;
