import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { hideDrawer } from 'services/reducer/drawer';
import { useLocalStorage } from 'usehooks-ts';

const TopMenu = ({ title }: { title: string; }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
    const [theme, setTheme] = useLocalStorage('themePWAEX', "light");

  useEffect(() => {
    searchParams.delete('success');
    searchParams.delete('hash');
    searchParams.delete('message');
    setSearchParams(searchParams);
  }, []);

  return (
    <div className='top'>
      <div className='container'>
        <div className='rightArrow rightArrowPad' onClick={() => dispatch(hideDrawer())}></div>
        <h1>{title}</h1>
        <div className={`sunIcon`} onClick={() => setTheme(theme == "light" ? "dark" : "light")}></div>
      </div>
    </div>
  );
};

export default TopMenu;
