import React from 'react';
import Slider from 'react-slick';
import _ from 'lodash';

const CardSlider = () => {
	const images = [
		"/images/img_s1.png",
		"/images/img_s2.png",
		"/images/img_s3.png"
	];

	const settingSlider = {
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		appendDots: (dots: React.ReactNode) => (
			<div style={{ position: "absolute", bottom: "10px", width: "100%", display: "flex", justifyContent: "center" }}>
				<ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
			</div>
		),
		dotsClass: "slick-dots custom-dots"
	};


	return (
		<div className='newsBox' style={{marginBottom:"10px"}}>
			<div className='cartSilder'>
				<Slider {...settingSlider}>
					{images?.map((i: any, n: any) =>
						<div key={n} className='itemImg'>
							<img src={i} alt={`Slide`} className="slide-image" />
						</div>
					)}
				</Slider>
			</div>
		</div>
	);
}

export default CardSlider;
