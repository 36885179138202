import useGlobal from 'hooks/useGlobal';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { successAlert } from 'services/reducer/alert';
import { statusColor } from 'utils/color';
import { zeroFormat } from 'utils/numeral';
import { dotted } from 'utils/tools';
import { statusPayment } from 'utils/translate';

const Deposit = ({ data }: any) => {
  const global = useGlobal();
  const dispatch = useDispatch();

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) { }
  };

  return (
    <div className='voucherData'>
      <div className='listCashCode listCashCodeCustom'>
        <section>
          <div>
            <span>شناسه درخواست</span>
            <i className='copy' onClick={() => copy(data?.uniqueId)}></i>
            <span className='ltr'>{data?.uniqueId} </span>
          </div>
        </section>
        <section>
          <div>
            <span>وضعیت</span>
            <span className={`color${statusColor(data.status)}`}>{statusPayment(data.status)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>تاریخ</span>
            <span className='ltr'>
              {moment(data.createdAt)
                .locale('fa')
                .format('YYYY-MM-DD , HH:mm:ss')}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span> کیف پول واریز</span>
            <span>کیف پول اسپات</span>
          </div>
        </section>
        <section>
          <div>
            <span>ارز</span>
            <span className='currencyDetail'>
              {data?.coin.name} ({data?.coin?.symbol.toUpperCase()})
              <img crossOrigin='anonymous' src={global?.coins[data?.coin?.symbol]?.icon} />
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مقدار</span>
            <span className='ltr'>
              {zeroFormat(data.amount)} {data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>کارمزد شبکه</span>
            <span className='ltr'>
              {zeroFormat(data.fee)} {data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مقدار نهایی</span>
            <span className='ltr'>
              {zeroFormat(data.final)} {data?.coin?.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>شبکه</span>
            <span className='ltr'>{data?.network?.name}</span>
          </div>
        </section>
        <section>
          <div>
            <span>TxID</span>
            {data.hash &&
              <>
                <i onClick={() => copy(data.hash)}></i>
                <span className='ltr iconLinkWithTag' onClick={() => window.open(data.network.scanner + '/' + data.hash, '_blank')?.focus()}>
                  {dotted(data?.hash)}
                </span>
              </>}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Deposit;
