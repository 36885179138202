import React, { useState } from 'react';
import moment from 'jalali-moment';
import _ from 'lodash';

const CardInvite = () => {
  const [listInvited, setListInvited] = useState(new Array(3).fill("a"));
  const [loadHisInvite, setLoadHisInvite] = useState(false);


  return (

    <div className='cardInvite'>
      <p className='title'>لیست دعوت ها و جوایز</p>
      <div className='tb_View tb_Invited'>
        <table>
          <thead>
            <tr>
              <th><h5>نام دعوت شده</h5></th>
              <th><h5>جایزه شما</h5></th>
              <th><h5>وضعیت</h5></th>
            </tr >
          </thead >
          {/* <tbody className={"relative"}>
            {(!loadHisInvite && !_.isEmpty(listInvited))
              && Array.from(listInvited)?.map((i: any, n: any) =>
                <tr key={n} className="box">
                  <td>اصغر کرمانشاهی </td>
                  <td className='ltr'>{moment().locale("fa").format('YYYY-MM-DD  HH:mm')} </td>
                  <td>0.000666 <b>USDT</b></td>
                  <td>5.235.237.30</td>
                  <td>موفق</td>
                </tr>)}
          </tbody> */}
        </table >
        <div className='fetchListLoad'>
          <div className='emptyLoad'>
            {/* <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconNotingList2.svg" alt="" /> */}
            <p>بزودی . . .</p>
          </div>
        </div>
        {/* <FetchListLoad list={listInvited} load={loadHisInvite} /> */}
      </div >

    </div>
  );
}

export default CardInvite;
