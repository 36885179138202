import { ClickAwayListener } from '@mui/material';
import useGlobal from 'hooks/useGlobal';
import { ICard, ICoin, INetwork } from 'interfaces/global';
import { ISelectBox } from 'interfaces/helper';
import { useRef, useState } from 'react';
import bank from 'utils/bank';
import { zeroFormat } from 'utils/numeral';

const SelectBox = ({ value, data, setSelected, template, search, symbol }: ISelectBox): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const anchorRef = useRef<HTMLButtonElement>(null);
  const global = useGlobal();

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef?.current && anchorRef?.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setIsOpen(false);
  };

  const handleIsActive = (item: any) => {
    try {
      if (template === 'networkDep') {
        if (item?.isActive && item?.deposit?.isActive) {
          return true
        }
        return false

      } else {
        if (item?.isActive && item?.withdraw?.isActive) {
          return true
        }
        return false
      }

    } catch (error) {
      return false
    }
  }

  const listRender = () => {
    try {
      const rows: React.ReactElement[] = [];

      if (template === 'coin') {
        Object?.values(data as ICoin[])
          ?.filter(
            (e) =>
              e?.name?.toLowerCase().includes(text?.toLowerCase()) ||
              e?.nameFa?.toLowerCase().includes(text?.toLowerCase()) ||
              e?.symbol?.toLowerCase().includes(text?.toLowerCase()),
          )
          ?.filter((e) => !Object?.keys(global?.listVoucherSymbol)?.includes(e?.symbol))
          ?.forEach((e, i) => {
            rows.push(
              <div
                key={i}
                onClick={() => {
                  setSelected(e);
                  setIsOpen(false);
                }}
              >
                <img crossOrigin='anonymous' src={e.icon} />
                <section>
                  <h2>
                    {e?.name} <span>({e?.symbol?.toUpperCase()})</span>
                  </h2>
                  <h3>{e?.nameFa}</h3>
                </section>
              </div>,
            );
          });
      } else if (template === 'card') {
        Object?.values(data as ICard[])
          ?.filter(
            (e) =>
              e?.bank?.toLowerCase().includes(text?.toLowerCase()) ||
              e?.number?.toLowerCase().includes(text?.toLowerCase()) ||
              e?.iban?.toLowerCase().includes(text?.toLowerCase()),
          )
          ?.forEach((e, i) => {
            rows.push(
              <div
                key={i}
                onClick={() => {
                  setSelected(e);
                  setIsOpen(false);
                }}
              >
                <img src={bank?.detect(e?.number ?? ""!)?.logo || '/svg/cardDown.svg'} />
                <h3 className='colorText'>{e?.bank}</h3>
                {e?.number && <h2>{e?.number ? e?.number?.match(new RegExp('.{1,4}', 'g'))?.join('-') : "---"}</h2>}
              </div>,
            );
          });
      } else if (template === 'network' || template === 'networkDep') {
        Object?.values(data as INetwork[])?.forEach((e, i) => {
          rows.push(
            <div
              key={i}
              onClick={() => {
                if (e?.isActive) {
                  if (handleIsActive(e)) {
                    setSelected(e?.network?.name);
                    setIsOpen(false);
                  }
                }
              }}
            >
              <h3>
                {e?.network?.name}
                <br />
                {template === 'networkDep' ? <span>حداقل واریز: {symbol} {e?.deposit?.min}</span> : <span>کارمزد شبکه: {symbol} {zeroFormat(e?.withdraw?.fee)}</span>}
              </h3>
              <h2 className={`statusNetwork ${handleIsActive(e) ? 'active' : 'deActive'}`}>{handleIsActive(e) ? 'فعال' : 'غیرفعال'}</h2>
            </div>,
          );
        });
      } else {
        Object.values(data as string[])
          ?.filter((e) => e?.toLowerCase()?.includes(text?.toLowerCase()))
          ?.forEach((e, i) => {
            rows.push(
              <span
                key={i}
                onClick={() => {
                  setSelected(e);
                  setIsOpen(false);
                }}
              >
                {e}
              </span>,
            );
          });
      }

      if (rows.length === 0) {
        return <div className='noItem'>موردی یافت نشد</div>;
      }

      if (template === 'coin') {
        return <div className='listOfCoin'>{rows}</div>;
      } else if (template === 'card') {
        return <div className='listOfCards'>{rows}</div>;
      } else if (template === 'network' || template === 'networkDep') {
        return <div className='listOfCards listOfNetwork'>{rows}</div>;
      } else {
        return <div className='optionList'>{rows}</div>;
      }
    } catch (error) {
      console.log("error", error);
      return ""
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className='selectParent'>
        <div
          className='noInput'
          onClick={() => {
            setText('');
            setIsOpen(true);
          }}
        >
          {value}
        </div>
        {isOpen && (
          <div className='downSelect'>
            {search && (
              <div className='search'>
                <input type='text' className='ltr textRight' onChange={(e) => setText(e.target.value)} placeholder='جستجو' />
              </div>
            )}
            {listRender()}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SelectBox;
