import Deposit from 'components/Modules/Deposit';
import Withdraw from 'components/Modules/Withdraw';
import React, { useState } from 'react';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { zeroFormat } from 'utils/numeral';
import Trade from './Trade';

const ItemWallet = ({ e, dispatch, eye }: { e: any, dispatch: any, eye: any }) => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <section className={`newItemWallet`}>
        <div className={`newItem ${show ? "brNone" : ""}`}>
          <span className='w110 inlineFlex' onClick={() => setShow(!show)}>
            <img crossOrigin='anonymous' src={e.icon} />
            <span className='viaFa ml_10'>
              <i className='symbolCoin'>{e.symbol.toUpperCase()}</i> {e.nameFa}
            </span>
          </span>
          <span className='f1 ltr textRight' onClick={() => setShow(!show)}>{eye ? '***' : zeroFormat(e.balance)}</span>
          <span className='settingWallet alignItemsCenter'>
            {e.symbol !== 'tmn' && (
              <i className={`boxStatus minWidth-37 bgwhite`} onClick={() => dispatch(centerDialog(<Trade data={e} />))}>
                معامله
              </i>
            )}
            <i className={`boxStatus minWidth-37 bgGreen`} onClick={() => dispatch(fullDrawer(<Deposit symbol={e.symbol} />))}>
              واریز
            </i>
            <i className={`boxStatus minWidth-37 bgRed`} onClick={() => dispatch(fullDrawer(<Withdraw symbol={e.symbol} />))}>
              برداشت
            </i>
          </span>
        </div>
        {show
          ? <section className='numBalanceWallet f1'  onClick={() => setShow(!show)}>
            <div className='item'>
              <span className='t1'>تومانی : </span>
              <span className='t2 ltr textRight'>{eye ? '***' : zeroFormat(e?.value?.tmnAmount,0,false)}</span>
            </div>
            <div className='item'>
              <span className='t1'>آزاد : </span>
              <span className='t2  ltr textRight'>{eye ? '***' : zeroFormat(e.balance)}</span>
            </div>
            <div className='item'>
              <span className='t1'>رزرو شده :</span>
              <span className='t2 ltr textRight'>{eye ? '***' : zeroFormat(e.blocked)}</span>
            </div>
          </section>
          : ""}
      </section>
    </div>
  );
}

export default ItemWallet;
