import config from 'config';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import bank from 'utils/bank';
import gather from 'utils/gather';
import Level2 from '../Level2';
import { codeUp25 } from 'services/reducer/user';
import useGlobal from 'hooks/useGlobal';
import _ from 'lodash';
import AddNationalAndBirthday from 'components/AddNationalAndBirthday';
import Level1 from '../Level1';
import Identity from '../Identity';

const Code = () => {
  const dispatch = useDispatch<any>();
  const user = useUser();
  const global = useGlobal()
  const [list, setList] = useState(user.codeUp25 ?? []);

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) { }
  };

  const listRender = () => {
    const rows: JSX.Element[] = [];

    let newList: any = []

    Array.from(list).forEach((e: any) => {
      Array.from(global.listLevelObjByID[user.profile?.plane?.level?._id]?.cashCode)
      ?.filter((ele:any)=>ele?.isActive)
      ?.forEach((element: any) => {
        if (element?.slug == e?.bank) {
          let newObj = _.cloneDeep(element)
          newObj["codeUser"] = e?.code;
          newList.push(newObj)
        }
      });
    });

    newList?.forEach((i: any, n: any) => {
      rows.push(
        <section key={n}>
          <div onClick={() => copy(i?.sheba)}>
            <span>شماره شبا مقصد</span>
            <i></i>
            <span>{i?.sheba}</span>
            <img crossOrigin='anonymous' src={bank.logo(i.slug)!} alt='' />
          </div>
          <div onClick={() => copy(i?.account)}>
            <span>شماره حساب </span>
            <i></i>
            <span>{i?.account}</span>
          </div>
          <div>
            <span>نام صاحب حساب</span>
            <span>{i?.owner}</span>
          </div>
          <div>
            <span>نام بانک</span>
            <span>{i?.bank}</span>
          </div>
          <div onClick={() => copy(i?.codeUser)}>
            <span>شناسه پرداخت/واریز <b className='txtforceGateway'>(الزامی)</b></span>
            <i></i>
            <span>{i?.codeUser}</span>
          </div>
        </section>,
      );
    });

    return rows;
  };

  const checkNotionalBirtday=()=>{
    if (user?.profile?.plane?.level?.index > 0 && (!user?.profile?.verify?.identity || _.isNull(user?.profile?.nationalCode)
   || _.isNull(user?.profile?.birthDate) || user?.profile?.nationalCode?.trim().length != 10
   || user?.profile?.birthDate?.trim().length == 0)) {

   dispatch(fullDrawer(<AddNationalAndBirthday />))
 }else{
   dispatch(fullDrawer(user?.profile?.plane?.level?.index == 0 ? <Identity /> : <Level1 />))
 }
}

  const getCode = async () => {
    if (user?.profile?.plane?.level?.index > 0 && (!user?.profile?.verify?.identity || _.isNull(user?.profile?.nationalCode)
      || _.isNull(user?.profile?.birthDate) || user?.profile?.nationalCode?.trim().length != 10
      || user?.profile?.birthDate?.trim().length == 0)) {

      dispatch(fullDrawer(<AddNationalAndBirthday />))
      return
    }
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/cashCode`, true).post();
    if (result.code === 200) {
      // setList(result.data.map((e: any) => e.data).flat(1));

      let newList: any = []

      Array.from(result?.data)?.forEach((item: any) => {
        newList.push([...item?.data]);
      });

      let addSort = newList?.flat()?.map((i: any) => {
        if (i?.bank == "mellat") {
          return { ...i, "sort": 0 }
        } else {
          return { ...i, "sort": 1 }
        }
      })
      setList(addSort);
      dispatch(codeUp25());
      // setWarning("true");

    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  return (
    <>
      {list.length === 0 ? (
        <>
          <div className='step stepDeposit'>
            <i>۱</i>
            <h3>ارتقای سطح کاربری</h3>
          </div>
          <p className='rules'>جهت دریافت شناسه واریز حداقل سطح کاربری ۲ می باشد</p>
          <div className='step stepDeposit'>
            <i>۲</i>
            <h3>درخواست شناسه</h3>
          </div>
          <p className='rules'>شناسه واریز فقط برای بانک های ملت، آینده و سامان فعال می باشد</p>
          <div className='spacer'></div>
          {user?.profile?.plane?.level?.index > 1 ? (
            <div className='largeButton' onClick={getCode}>
              درخواست صدور شناسه واریز
            </div>
          ) : (
            <div className='largeButton largeButtonGreen' onClick={checkNotionalBirtday}>
              درخواست ارتقای سطح کاربری
            </div>
          )}
        </>
      ) : (
        <>
          <div className='listCashCode'>{listRender()}</div>
          <div className='spacer'></div>
        </>
      )}
    </>
  );
};

export default Code;
