import config from 'config';
import { IForm } from 'interfaces/login';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import ForgetPassword from '../ForgetPassword';
import Register from '../Register';
import { numbersToEn } from 'utils/tools';

const Form = ({ mobile, setMobile, setStep, setType }: IForm) => {
  const [password, setPassword] = useState('');
  const [passwordEye, setPasswordEye] = useState(false);
  const dispatch = useDispatch();

  const loginAction = async () => {
    let newMobile = numbersToEn(mobile).startsWith("0") ? numbersToEn(mobile) : '0' + numbersToEn(mobile);
    if (newMobile.length !== 11) {
      dispatch(errorAlert('شماره موبایل را با دقت وارد کنید'));
      return
    }
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/auth/signin`).post({
      mobile: newMobile,
      password: password,
    });

    if (result.code === 200) {
      const resultType = await gather(`${config.data.api}/v1/auth/signin/confirm`).post({
        mobile: newMobile,
        type: result.data.type,
      });

      if (resultType.code === 200) {
        setStep(2);
        setType(result.data.type);
      } else {
        dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      }
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  return (
    <>
      <p className='inTitle'>ورود به اکس نوین</p>
      <p className='inAlarm'>لطفا فقط با آدرس زیر وارد شوید</p>
      <div className='domain'>
        <i>https://</i>pwa.exnovin.net
      </div>
      <div className='mainInput'>
        <p>شماره همراه</p>
        <div>
          <span></span>
          <input type='number' placeholder='+98' value={mobile} onChange={(e) => setMobile(e.target.value)} />
        </div>
      </div>
      <div className='mainInput'>
        <p>رمز عبور</p>
        <div>
          <span className='password'></span>
          <input type={!passwordEye ? 'password' : 'text'} value={password} autoComplete='one-time-code' onChange={(e) => setPassword(e.target.value)} />
          <i className={`eye ${passwordEye && 'active'}`} onClick={() => setPasswordEye(!passwordEye)}></i>
        </div>
      </div>
      <div
        className='rules'
        onClick={() => {
          dispatch(hideDrawer());
          setTimeout(() => dispatch(fullDrawer(<ForgetPassword />)), 300);
        }}
      >
        <i>رمز عبور خود را فراموش کرده‌ام!</i>
      </div>
      <div className='largeButton' onClick={() => loginAction()}>
        وارد شوید
      </div>
      <div
        className='haveAccount'
        onClick={() => {
          dispatch(hideDrawer());
          setTimeout(() => dispatch(fullDrawer(<Register />)), 300);
        }}
      >
        <i  className='colorText'>حساب کاربری ندارید؟</i>
        <p>ثبت نام کنید</p>
      </div>
    </>
  );
};

export default Form;
