import React, { useEffect, useState } from 'react';
import { autoFormatter, formatter, toFixed, zeroFormat } from 'utils/numeral';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import moment from 'jalali-moment';
import useUser from 'hooks/useUser';
import useGlobal from 'hooks/useGlobal';
import useBalance from 'hooks/useBalance';
import DonatChart from 'components/DonatChart';
import { removeComma, toFarsiNumber } from 'utils/tools';

const CartWallet = () => {
  const user = useUser();
  const [chart, setChart] = useState([{ label: "دارایی", value: 100, color: "#000" }]);
  const [persNumTmn, setPersNumTMN] = useState(0);
  const [persNumUSDT, setPersNumUSDT] = useState(0);
  const balance = useBalance();
  const global = useGlobal();
  const color = ["#1d6800", "#00ff1a", "#3647ff", "#000000"];
  const RemainderTMN = user?.profile?.plane?.level ? Number(user?.profile?.plane?.level?.withdraw?.fiat?.daily ?? 0) - Number(user?.profile?.limit?.fiat?.daily ?? 0) : 0;
  const RemainderUSDT = user?.profile?.plane?.level ? Number(user?.profile?.plane?.level?.withdraw?.coin?.daily ?? 0) - Number(user?.profile?.limit?.coin?.daily ?? 0) : 0;

  const handleAllAmount = () => {

    let data: any = [{
      key: 1
      , label: "TMN" + "   " + "تومان"
      , value: removeComma(zeroFormat(Number(balance["tmn"]?.value?.usdt), 2, false))
      , color: color[0]
      , month: 'December',
    },
    {
      key: 2
      , label: "USDT" + "   " + "تتر"
      , value: removeComma(zeroFormat(Number(balance["usdt"]?.value.usdtAmount), 2, false))
      , color: color[1]
      , month: 'December',
    },
    {
      key: 3
      , label: "سایر ارزها"
      , value: removeComma(zeroFormat(Object.values(balance)
        ?.filter((e: any) => !Object?.values(global.listVoucherSymbol)?.includes(e.symbol))
        ?.filter((e: any) => !['tmn', 'usdt',].includes(e.symbol))
        ?.reduce((n, e: any) => n + e.value.usdtAmount, 0), 2, false))
      , color: color[2]
      , month: 'December',
    }]

    const checkList = data.filter((e: any) => e.value > 0);
    if (checkList?.length > 0) {
      setChart(data)
    }

  }

  const handlePercent = (count: any, total: any) => {
    return 100 - toFixed(Number(Number(count * 100) / Number(total)), 0)
  }


  const handlePecentBalance = () => {
    const allBalanceTmn = Object.values(balance)
      ?.filter((e: any) => !Object.keys(global.listVoucherSymbol)?.includes(e.symbol))
      ?.reduce((n, e: any) => n + e.value.tmnAmount, 0)

    const onlyTmn = balance["tmn"]?.value.tmnAmount

    const pers = Number(Number(onlyTmn / allBalanceTmn) * 100);
    setPersNumTMN(toFixed(pers ?? 0, 0))


    const allBalanceUsdt = Object.values(balance)
      ?.filter((e: any) => !Object?.values(global.listVoucherSymbol)?.includes(e.symbol))
      ?.reduce((n, e: any) => n + e.value.usdtAmount, 0)

    const onlyTmnUsdt = Object.values(balance)
      ?.filter((e: any) => e.symbol?.toLowerCase() == "tmn")
      ?.reduce((n, e: any) => n + e.value.usdtAmount, 0)

    const persUsdt = Number(Number(Number(allBalanceUsdt - onlyTmnUsdt) / allBalanceUsdt) * 100);
    setPersNumUSDT(toFixed(persUsdt ?? 0, 0))
  }

  useEffect(() => {
    handleAllAmount();
    handlePecentBalance()
  }, []);


  return (
    <div className='cartWallet'>
      <div className='right'>
        <div className='detailWallet'>
          <div className='boxChart'>
            <div className='chartWallet'>
              <DonatChart data={chart} />
            </div>
            <div className='box1'>
              <p className='tx1'>ارزش دارایی ها</p>
              <p className='tx2'>{toFarsiNumber(formatter(Object.values(balance)
                ?.filter((e: any) => !Object?.values(global.listVoucherSymbol)?.includes(e.symbol))
                ?.reduce((n, e: any) => n + e.value.tmnAmount, 0), 0, 0, true) ?? "")}&nbsp;<b>تومان</b></p>
              <p className='tx3'> معادل <b>{formatter(Object.values(balance)
                ?.filter((e: any) => !Object?.values(global.listVoucherSymbol)?.includes(e.symbol))
                ?.reduce((n, e: any) => n + e.value.usdtAmount, 0), 2, 0, true)}&nbsp;</b>دلار</p>
            </div>
          </div>

          <div className='box2'>
            <div className="item">
              <div className='prog'>
                <CircularProgressbar
                  value={handlePercent(user?.profile?.limit?.fiat?.daily, user?.profile?.plane?.level?.withdraw?.fiat?.daily)}
                  strokeWidth={13}
                  styles={buildStyles({
                    pathColor: "#22c58b",
                    trailColor: "#d6d6d6",
                  })}
                />
                <span className='icon-smallArrow bgGreen'></span>
              </div>
              <p className='t1'>قابل برداشت امروز</p>
              <p className='t2'>{toFarsiNumber(autoFormatter(RemainderTMN) ?? "0")} <b>تومان</b></p>
            </div>
            <div className="item">
              <div className='prog'>
                <CircularProgressbar
                  value={handlePercent(user?.profile?.limit?.coin?.daily, user?.profile?.plane?.level?.withdraw?.coin?.daily)}
                  strokeWidth={13}
                  styles={buildStyles({
                    pathColor: "#3647ff",
                    trailColor: "#d6d6d6",
                  })}
                />
                <span className='icon-smallArrow'></span>
              </div>
              <p className='t1'>قابل برداشت امروز دلاری</p>
              <p className='t2 t2tmn'>${autoFormatter(RemainderUSDT)}</p>
            </div>
          </div>
        </div>

      </div>
      <div className='left'>
        <div className='balance'>
          <div className="item">
            <div className='boxTxt'>
              <p className='t1'>دارایی تومانی</p>
              <p className='t2'>{toFarsiNumber(formatter(Object.values(balance)
                ?.filter((e: any) => e.symbol?.toLowerCase() == "tmn")
                ?.reduce((n, e: any) => n + e.value.tmnAmount, 0), 0, 0, true) ?? "")}</p>
            </div>
            <div className='boxProg'>
              <CircularProgressbar
                value={persNumTmn ? persNumTmn : 0}
                text={`${toFarsiNumber(persNumTmn ? persNumTmn : 0)}%`}
                strokeWidth={10}
                styles={buildStyles({
                  textSize: "20px",
                  pathColor: "#22c58b",
                  textColor: "var(--color-black4)",
                  trailColor: "#d6d6d6",
                })}
              />
            </div>
          </div>
          {/* <div className='hordivider'></div> */}
          <div className="item">
            <div className='boxTxt'>
              <p className='t1 orangeBefore'>دارایی ارزی</p>
              <p className='t2'>${formatter(Object.values(balance)
                ?.filter((e: any) => e.symbol?.toLowerCase() !== "tmn")
                ?.filter((e: any) => !Object?.values(global.listVoucherSymbol)?.includes(e.symbol))
                ?.reduce((n, e: any) => n + e.value.usdtAmount, 0), 2, 0, true)}</p>
            </div>
            <div className='boxProg'>
              <CircularProgressbar
                value={persNumUSDT ? persNumUSDT : 0}
                text={`${toFarsiNumber(persNumUSDT ? persNumUSDT : 0)}%`}
                strokeWidth={10}
                styles={buildStyles({
                  textSize: "20px",
                  pathColor: "#FFB13B",
                  textColor: "var(--color-black4)",
                  trailColor: "#d6d6d6",
                })}
              />
            </div>
          </div>
        </div>
        <div className='activityUser'>
          <div className='item'>
            <img src="/svg/ticktColor.svg" alt="" className='icon' />
            <div className='boxTxt'>
              <p className='t1'>{toFarsiNumber(Object.keys(user.ticketList)?.length ?? 0)}</p>
              <p className='t2'>تیکت ها</p>
            </div>
          </div>
          <div className='hordivider'></div>
          <div className='item'>
            <img src="/svg/cardBankColor.svg" alt="" className='icon' />
            <div className='boxTxt'>
              <p className='t1'>{toFarsiNumber(Object.keys(user.cards)?.length ?? 0)}</p>
              <p className='t2'>کارت بانکی</p>
            </div>
          </div>
          <div className='hordivider'></div>
          <div className='item'>
            <img src="/svg/rateColor.svg" alt="" className='icon' />
            <div className='boxTxt'>
              <p className='t1'>{user?.profile?.plane?.level?.name ?? "---"}</p>
              <p className='t2'>سطح کاربری</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default CartWallet;
