import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ConfirmBuySellFast from 'components/Pages/Home/ConfirmBuySellFast';
import SelectBox from 'components/Helper/SelectBox';
import Tabs from 'components/Helper/Tabs';
import Bottom from 'components/Layouts/Bottom';
import Header from 'components/Layouts/Header';
import Orders from 'components/Layouts/Orders';
import Pairs from 'components/Layouts/Pairs';
import PreLogin from 'components/Layouts/PreLogin';
import VerifyList from 'components/Layouts/VerifyList';
import AlarmMemo from 'components/Modules/Deposit/AlarmMemo';
import Address from 'components/Modules/Withdraw/Address';
import VerifyWithdrawCoin from 'components/VerifyWithdrawCoin';
import config from 'config';
import useBalance from 'hooks/useBalance';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import _, { invert } from 'lodash';
import React, { useEffect, useState } from 'react';
import Slider from 'react-input-slider';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog, hideDialog, showDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { useLocalStorage } from 'usehooks-ts';
import gather from 'utils/gather';
import { zeroFormat, toFixed } from 'utils/numeral';
import { numbersToEn, removeComma } from 'utils/tools';
import { isUserLogin } from 'utils/user';
import DialogEmptyBalance from '../Market/DialogEmptyBalance';

const tradeTabTitles = {
  otc: 'تبدیل فوری',
  // limit: 'معامله با قیمت معین',
};

const sideTabTitles = {
  buy: 'خرید',
  sell: 'فروش',
};

const Home = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const global = useGlobal();
  const ticker = useTicker();
  const balance = useBalance();
  const user = useUser();
  const [coin, setCoin] = useState(state?.default || Object.values(global?.coins)[0]);
  const [tradeTab, setTradeTab] = useState(tradeTabTitles.otc);
  const [sideTab, setSideTab] = useState(sideTabTitles.buy);
  const [reload, setReload] = useState(0);
  const [range, setRange] = useState({ x: 0 });
  const [myFoucs, setMyFoucs] = useState("");
  const [radioBuy, setRadioBuy] = useState('defualt');
  const [address, setAddress] = useState('');
  const [minWitdraw, setMinWitdraw] = useState(false);
  const [network, setNetwork] = useState('');
  const [needTagMemo, setNeedTagMemo] = useState(false);
  const [tagMemo, setTagMemo] = useState('');
  const [confiemOrder] = useLocalStorage('confiemOrder', "false");
  const { coinParam } = useParams();
  const navigate = useNavigate();
  const side = sideTab === sideTabTitles.buy ? 'buy' : 'sell';
  const userAsset = balance[sideTab === sideTabTitles.buy ? 'tmn' : coin?.symbol];
  const trade = invert(tradeTabTitles)[tradeTab] as any;
  const currentPrice = ticker[side](coin?.symbol + 'tmn');
  const [targetInput, setTargetInput] = useState("");
  ///////////////////
  const [payment, setPayment] = useState(0);
  const [amount, setAmount] = useState(0);
  //////////////

  const resetInput = () => {
    setAmount(0);
    setPayment(0);
    setRange({ x: 0 });
  };

  const resetInputWithdraw = () => {
    setNeedTagMemo(false);
    setTagMemo('');
    setAddress("")
  }

  const justEnglish = (e: any) => {
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setAddress(e);
    } else {
      dispatch(errorAlert("فقط حروف لاتین برای آدرس کیف پول وارد کنید"));
    }
  };

  const justNum = (e: any) => {
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setTagMemo(e);
    } else {
      dispatch(errorAlert("فقط اعداد و حروف لاتین برای آدرس ممو وارد کنید"));
    }
  };


  const pastWallet = async () => {
    const text = await navigator.clipboard.readText();
    justEnglish(text);
  }

  const hanleSelectNetwork = (e: any) => {
    resetInputWithdraw();
    setNetwork(e);


    if (coin?.networks.find((ele: any) => ele.network.name === e)?.network?.memo?.isForce
      || coin?.networks.find((ele: any) => ele.network.name === e)?.network?.memo?.isNeed) {
      setNeedTagMemo(true);
      dispatch(centerDialog(<AlarmMemo symbol={e} depo={false} />));
    }
  }


  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioBuy((event.target as HTMLInputElement).value);
  };

  const coinListRender = () => {
    const rows: JSX.Element[] = [];

    const data = Object.values(global?.coins)
      .filter((e) => !Object.keys(global.listVoucherSymbol)?.includes(e.symbol))
      .filter((e) => (e.pair.tmn as any)[trade])
      .splice(0, 3);

    if (!data.find((e) => e.symbol === coin?.symbol)) {
      data[0] = coin;
    }

    data.forEach((e, i) => {
      rows.push(
        <div key={i} className={coin?.symbol === e?.symbol ? 'active' : ''} onClick={() => { setCoin(e); navigate("/order/fast/" + e?.symbol?.toUpperCase()) }}>
          <img crossOrigin='anonymous' src={e?.icon} />
          <h2>
            {e.name} <span>({e.symbol.toUpperCase()})</span>
          </h2>
          <h3>{e.nameFa}</h3>
        </div>,
      );
    });

    return rows;
  };

  const handleMaxWithdraw = () => {
    const daily = Number(user?.profile?.limit?.coin?.daily);
    const monthly = Number(user?.profile?.limit?.coin?.monthly);
    const maxMonthly = Number(user?.profile?.plane.level.withdraw.coin.monthly);
    const maxDaily = Number(user?.profile?.plane.level.withdraw.coin.daily);

    const calcDaily = Number(maxDaily - daily) ?? 0;
    const calcMonthly = Number(maxMonthly - monthly) ?? 0;

    return { calcDaily, calcMonthly }
  }

  const confirmSend = () => {

    // const myAmount = sideTab === sideTabTitles.buy ? payment : amount;
    const myNetwork = coin.networks.find((e: any) => e.network.name.toLowerCase() === network.toLowerCase())

    const myAmount = sideTab === sideTabTitles.buy ? Number(numbersToEn(payment?.toString()))
      : Number(numbersToEn(amount.toString()));

    const myBuySell = sideTab === sideTabTitles.buy ? Number(numbersToEn(amount.toString()))
      : Number(numbersToEn(payment?.toString()));

    if (Number(userAsset.balance) < Number(numbersToEn(amount.toString()))) {
      dispatch(centerDialog(<DialogEmptyBalance symbol={sideTab === sideTabTitles.buy ? "tmn" : coin?.symbol} />))
      return
    }

    if (_.isEmpty(coin)) {
      dispatch(errorAlert('لطفا یک ارز را انتخاب کنید'));
      return
    }

    if (myAmount <= 0) {
      dispatch(errorAlert('مقدار را وارد کنید'));
      return
    }

    if (radioBuy !== "defualt") {
      if (coin?.symbol?.toLocaleLowerCase() != "usdt" && !ticker.price(coin?.symbol + 'usdt')) {
        dispatch(errorAlert("دردسترس نیست"));
        return
      }

      // if (profile?.plane?.level?.index === 0 || _.isNull(profile?.plane?.level?.index)) {
      //   dispatch(DialogShow.show2(<NeedUpgrade />))
      //   return
      // }

      if (network?.trim().length == 0) {
        dispatch(errorAlert("لطفا شبکه انتقال را انتخاب کنید"));

        return;
      }

      if (!myNetwork?.withdraw?.isActive) {
        dispatch(errorAlert("این ارز در این شبکه قابلیت برداشت ندارد"));
        return
      }

      if (address.length < 20) {
        dispatch(errorAlert("آدرس مقصد را بررسی کنید"));
        return;
      }

      if (coin?.networks.find((ele: any) => ele.network.name === network)?.network?.regex &&
        !new RegExp(coin?.networks.find((ele: any) => ele.network.name === network)?.network?.regex).test(address)) {
        dispatch(errorAlert("آدرس مقصد را معتبر نیست"));
        return
      }

      if ((myAmount * ticker.price(coin?.symbol + 'usdt', 1)) > handleMaxWithdraw().calcDaily || (myAmount * ticker.price(coin?.symbol + 'usdt', 1)) > handleMaxWithdraw().calcMonthly) {
        dispatch(errorAlert(`مقدار برداشت درخواست شده بیش از سقف مجاز است`));
        return;
      }

      if (myAmount < myNetwork?.withdraw?.min) {
        dispatch(errorAlert("حداقل مقدار برداشت را رعایت کنید"));
        setMinWitdraw(true)
        setTimeout(() => { setMinWitdraw(false) }, 3000)
        return;
      }

      if (myNetwork?.network?.memo?.isForce && tagMemo?.trim().length <= 0) {
        dispatch(errorAlert(" تگ / ممو را وارد کنید"));
        return;
      }
    }


    if (confiemOrder == "true") {
      buyOrSellAction({
        'coin': coin._id,
        'pair': 'tmn',
        'side': side,
        'price': ticker[sideTab === sideTabTitles.buy ? "buy" : "sell"](coin?.symbol + 'tmn'),
        "amount": sideTab === sideTabTitles.buy
          ? toFixed(myBuySell / ticker['buy'](coin?.symbol + 'tmn'), coin?.decimal)
          : toFixed(amount, coin?.decimal)
        ,
        "trade": "otc",
        "stop": null
      })
    } else {
      const data = {
        "coin": coin,
        "side": side,
        'amount': myAmount,
        "radioBuy": radioBuy,
        "network": myNetwork,
        "needTagMemo": needTagMemo,
        "address": address,
        "tagMemo": tagMemo,
        "myBuySell": myBuySell
      }

      dispatch(centerDialog(<ConfirmBuySellFast data={data} send={buyOrSellAction} close={() => dispatch(hideDialog())} />))
    }
  }

  const withdrawCoin = async () => {
    dispatch(showLoading());
    const myAmount = sideTab === sideTabTitles.buy ? payment : amount;

    const body: any = {
      coin: coin?._id,
      network: coin.networks.find((e: any) => e.network.name.toLowerCase() === network.toLowerCase())?.network?._id,
      receiver: numbersToEn(address),
      amount: Number(numbersToEn(myAmount?.toString())),
    }

    if (coin.networks.find((e: any) => e.network.name.toLowerCase() === network.toLowerCase())?.network?.memo?.isForce
      || coin.networks.find((e: any) => e.network.name.toLowerCase() === network.toLowerCase())?.network?.memo?.isNeed) {
      body["memo"] = tagMemo
    }

    const result = await gather(`${config.data.api}/v1/withdraw`, true).post(body);
    if (result.message === 200) {
      dispatch(centerDialog(<VerifyWithdrawCoin body={body} submit={submit} type={result?.data?.type}
        close={() => dispatch(hideDialog())} />))
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  }

  const submit = async (body: any) => {
    dispatch(hideDialog())
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/withdraw/confirm`, true).post(body);
    if (result.message === 200) {
      dispatch(successAlert("عملیات با موفقیت انجام شد"));
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  }

  const buyOrSellAction = async (body: any) => {
    dispatch(hideDialog())
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/order`, true).post(body);

    if (result.code === 200) {
      dispatch(successAlert('سفارش شما با موفقیت ثبت شد'));
      setReload(reload + 1);
      resetInput();
      if (radioBuy !== "defualt") {
        withdrawCoin()
      } else {
        dispatch(hideLoading());
      }
    } else {
      dispatch(hideLoading());
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  /////////////////////////////////////////////////////////////////////////////
  const handelChange = (e: any) => {
    setAmount(removeComma(e));
    const total = sideTab === sideTabTitles.buy
      ? Number(removeComma(e)) / Number(currentPrice ?? 0)
      : Number(removeComma(e)) * Number(currentPrice ?? 0)

    setPayment(toFixed(total));

    const pers = (Number(removeComma(e) / userAsset.balance) * 100);
    if (myFoucs !== "Range") {
      setRange({ x: pers > 100 ? 100 : pers });
    }
  }

  const handelChangeTMN = (e: any) => {
    if (sideTab === sideTabTitles.buy) {
      setTargetInput("payBuy")
    }

    setPayment(removeComma(e));
    const total = sideTab === sideTabTitles.sell
      ? Number(removeComma(e)) / Number(currentPrice ?? 0)
      : Number(removeComma(e)) * Number(currentPrice ?? 0)

    setAmount(toFixed(total));

    const pers = (Number(removeComma(total) / userAsset.balance) * 100);
    if (myFoucs !== "Range") {
      setRange({ x: pers > 100 ? 100 : pers });
    }
    setTimeout(() => {
      setTargetInput("")
    }, 3000);
  }

  const handleSliderBar = (num: any) => {
    setMyFoucs("Range")
    setRange(num);

    const amount = Number(userAsset.balance) * Number(num.x / 100) || 0;
    handelChange(toFixed(amount, sideTab === sideTabTitles.buy ? 0 : coin?.decim));
  }


  useEffect(() => {
    resetInput();
  }, [sideTab, tradeTab, coin]);

  useEffect(() => {
    setRadioBuy('defualt');
    setNetwork('');
    resetInputWithdraw()
  }, [coin]);

  const handleSelectAdressBook = (item: any) => {
    if (coin?._id == item?.coin?._id) {
      if (item?.isActive) {
        setAddress(item?.receiver);
        if (item?.memo && item?.memo?.trim().length > 0) {
          setNeedTagMemo(true)
          justNum(item?.memo);
        }
        setTimeout(() => {
          setNetwork(global?.coins[item?.coin?.symbol]?.networks.find((e) => e.network._id === item.network._id)?.network?.name!);
        }, 10);
      } else {
        dispatch(errorAlert('آدرس وارد شده فعال نمی باشد'));
      }
    } else {
      setAddress('');
      setTagMemo('');
      setNetwork('')
      dispatch(errorAlert('آدرس وارد شده با ارز انتخابی  یکسان نیست'));
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(async () => {
      const coinParams = coinParam?.split("-")[0]?.toLocaleLowerCase();
      if (coinParams) {
        if (Object.values(global?.coins).find((e) => e.symbol === coinParams?.toLocaleLowerCase())) {
          setCoin(Object.values(global?.coins).find((e) => e.symbol === coinParams?.toLocaleLowerCase())!)
        }
      }
    }, 10);
  }, []);

  useEffect(() => {
    if (targetInput !== "payBuy") {
      const total = sideTab === sideTabTitles.buy
        ? Number(removeComma(amount)) / Number(currentPrice ?? 0)
        : Number(removeComma(amount)) * Number(currentPrice ?? 0)

      setPayment(toFixed(total));
    }
  }, [ticker]);

  const Remainder = user?.profile ? (Number(user?.profile?.plane?.level?.withdraw?.coin?.daily) - Number(user?.profile?.limit?.coin?.daily)): 0;

  return (
    <>
      <Header title={'مبادله فوری'} />
      <VerifyList />
      <div className='whiteBg buyExpress'>
        <div className='container'>
          <div className='tabs'>
            <Tabs titles={tradeTabTitles} selected={tradeTab} setSelected={setTradeTab} />
          </div>
          <div className='step'>
            <span></span>
            <h3>
              مرحله اول <i>نوع ارز</i>
            </h3>
            <div></div>
          </div>
          <div className='stepBelow'>ارز موردنظر را انتخاب کنید</div>
          <div className='coinList'>{coinListRender()}</div>
          <div className='otherCoin' onClick={() => dispatch(fullDrawer(<Pairs isMarket={false} pair={['tmn']} trade={trade} setCoin={setCoin} />))}>
            سایر ارزها <span className='rightArrow'></span>
          </div>
          <div className='step step2'>
            <span></span>
            <h3>
              مرحله دوم <i>نوع معامله</i>
            </h3>
            <div></div>
          </div>
          <div className='stepBelow'>نوع معامله را مشخص کنید</div>
          <div className='sellBuy'>
            <Tabs titles={sideTabTitles} selected={sideTab} setSelected={setSideTab} />
          </div>
          <div className='formFast'>
            <div className='payed'>
              <img crossOrigin='anonymous' src={sideTab === sideTabTitles.buy ? '/images/tmn.png' : coin?.icon} />
              <NumericFormat
                inputMode='decimal'
                className='ltr textRight'
                // onFocus={() => setFocus('payer')}
                allowNegative={false}
                decimalScale={sideTab === sideTabTitles.buy ? 0 : coin?.decimal}
                thousandSeparator={true}
                // value={payer > 0 ? payer : ''}
                // onValueChange={(e) => handleAmount(Number(e.value))}
                value={amount > 0 ? amount : ''}
                onChange={(e) => { setMyFoucs(""); handelChange(e.target.value) }}
                placeholder='پرداخت می‌کنم (اینجا وارد نمایید)'
              />
            </div>
            {/* <div className='convert' onClick={() => (sideTab === sideTabTitles.buy ? setSideTab(sideTabTitles.sell) : setSideTab(sideTabTitles.buy))}></div> */}
            <div className='convert' onClick={() => {
              setSideTab(sideTab === sideTabTitles.buy ? sideTabTitles.sell : sideTabTitles.buy);
              setPayment(amount);
              setAmount(payment);
              setRange({ x: 0 });
            }}></div>
            <div className='payed'>
              <img crossOrigin='anonymous' src={sideTab === sideTabTitles.sell ? '/images/tmn.png' : coin?.icon} />
              <NumericFormat
                inputMode='decimal'
                className='ltr textRight'
                // onFocus={() => setFocus('payee')}
                allowNegative={false}
                decimalScale={sideTab === sideTabTitles.sell ? 0 : coin?.decimal}
                thousandSeparator={true}
                // value={payee > 0 ? payee : ''}
                // onValueChange={(e) => setPayee(Number(e.value))}
                value={payment > 0 ? payment : ''}
                onChange={(e) => { setMyFoucs(""); handelChangeTMN(e.target.value) }}
                placeholder='دریافت می‌کنم (اینجا وارد نمایید)'
              />
            </div>
            <div className='yourBalance' onClick={() => { setAmount(userAsset.balance); userAsset.balance > 0 && setRange({ x: 100 }) }}>
              <div>
                موجودی:{' '}
                <i>
                  {zeroFormat(isUserLogin() ? userAsset.balance : 0)} {sideTab === sideTabTitles.buy ? 'TMN' : coin?.symbol.toUpperCase()}
                </i>
              </div>
              <div>
                قیمت: <i>{zeroFormat(currentPrice)} TMN</i>
              </div>
            </div>
            <div className='runRange'>
              <Slider
                x={range.x}
                xreverse={true}
                styles={{
                  track: {
                    backgroundColor: '#DFDFDF',
                  },
                  active: {
                    backgroundColor: '#2424DA',
                  },
                  thumb: {
                    backgroundColor: '#2424DA',
                    boxShadow: 'none',
                  },
                }}
                onChange={handleSliderBar}
              />
              <span style={{ width: range.x + '%' }}></span>
              <section>
                <i>0%</i>
                <i>25%</i>
                <i>50%</i>
                <i>75%</i>
                <i>100%</i>
              </section>
            </div>

            {(sideTab === sideTabTitles.buy && isUserLogin()) &&
              <div className='selectWalletBuy'>
                <p className='t1'>روش دریافت:</p>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={radioBuy}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel value="defualt" control={<Radio />} label="کیف پول داخلی اکسنوین" />
                  <FormControlLabel value="other" control={<Radio />} label="انتقال به کیف پول دیگر" />
                </RadioGroup>
                {radioBuy !== "defualt"
                  &&
                  <>
                    <div className='adressBox'>
                      <SelectBox
                        data={coin?.networks ?? []}
                        search={false}
                        symbol={coin?.symbol.toUpperCase()}
                        value={network ? <h3>{network}</h3> : <>نوع شبکه را انتخاب کنید</>}
                        template='network'
                        setSelected={hanleSelectNetwork}
                      />

                      <div className='mainInput addSelectAdressBook'>
                        <div className='myInput'>
                          <span className='address' onClick={pastWallet} ></span>
                          <input type='text' value={address} placeholder='آدرس کیف پول مقصد را وارد کنید' onChange={(e) => justEnglish(e.target.value)} />
                        </div>
                        <span className='addressbook' onClick={() => dispatch(fullDrawer(<Address setData={handleSelectAdressBook} />))}>
                          <i></i>
                        </span>
                      </div>
                    </div>
                    {needTagMemo
                      &&
                      <div className='mainInput w100Per'>
                        <div>
                          <input type='text' value={tagMemo} placeholder={"تگ / memo"} onChange={(e) => justNum(e.target.value)} />
                        </div>
                      </div>
                    }
                    {minWitdraw
                      &&
                      <p className={`wrningText`}>حداقل مقدار برداشت {coin?.symbol} {(coin.networks.find((e: any) => e.network.name.toLowerCase() === network.toLowerCase())?.withdraw.min || 0)} می باشد</p>
                    }

                    <div className='feeReceiveBuy'>
                      <p>کارمزد</p>
                      <p><b>{coin?.symbol?.toUpperCase()} </b> {zeroFormat(coin.networks.find((e: any) => e.network.name.toLowerCase() === network.toLowerCase())?.withdraw?.fee ?? 0)} </p>
                    </div>
                    <div className='feeReceiveBuy'>
                      <p>مقدار دریافتی</p>
                      <p>{Number(payment) - Number(coin.networks.find((e: any) => e.network.name.toLowerCase() === network.toLowerCase())?.withdraw?.fee) > 0
                        ? zeroFormat(Number(payment) - Number(coin.networks.find((e: any) => e.network.name.toLowerCase() === network.toLowerCase())?.withdraw?.fee))
                        : 0} <b>{coin?.symbol?.toUpperCase()}</b></p>
                    </div>
                    <div className='feeReceiveBuy'>
                      <p>باقی مانده سقف روزانه :</p>
                      <p>{zeroFormat(Remainder)} دلار </p>
                    </div>
                  </>
                }
              </div>
            }
            <div className='agreePay' onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : confirmSend())}>
              با تایید {sideTab}، قوانین و مقررات را می پذیرم
              <i>تایید {sideTab}</i>
            </div>
          </div>
        </div>
      </div>
      <Orders reload={reload} isVoucher={false} />
      <Bottom />
    </>
  );
};

export default Home;
