import config from 'config';
import { IDispatch } from 'interfaces/global';
import Cookies from 'js-cookie';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { handleSockerOrderData, reloadApiOrder } from 'services/reducer/global';
import { updateTicker } from 'services/reducer/ticker';
import { getProfile, getVersionUpdate, updateAsset, updateBalance } from 'services/reducer/user';
import { Socket, io } from 'socket.io-client';
import { isUserLogin } from 'utils/user';

let socket: Socket;
let time: Date = new Date();

const connect = (dispatch: IDispatch) => {
  if (!socket?.connected) {
    socket = io(config.data.api, {
      query: {
        authorization: (isUserLogin() ? Cookies.get('pwaAccessToken')! : ''),
      },
      extraHeaders: {
        authorization: (isUserLogin() ? Cookies.get('pwaAccessToken')! : ''),
      },
      transports: ['websocket'],
      // extraHeaders: {
      //   authorization: 'Bearer ' + (isUserLogin() ? Cookies.get('pwaAccessToken')! : '')
      // },
      // path:"/socket.io/",
      // transports:["websocket"]
    });

    // console.log( 'Bearer ' + Cookies.get('pwaAccessToken'));

    if (new Date().getTime() - time.getTime() >= 20 * 1000) {
      window.location.reload();
    }
  }

  socket.on('connect', () => {
    console.log('socket', 'Connected');
  });

  socket.on('disconnect', () => {
    console.log('socket', 'Disconnected');
    socket.removeAllListeners();
    socket.close();
    time = new Date();
    setTimeout(() => {
      connect(dispatch);
    }, 700);
  });

  socket.on('connect_failed', () => {
    console.log('socket', 'Failed');
  });

  socket.on('connect_error', (e) => {
    console.log('socket', 'Error: ' + e);
  });

  socket.on('updateProfile', () => {
    // console.log("updateProfile");
    dispatch(getProfile());
  });

  socket.on('ticker', (result) => {
    dispatch(updateTicker(result));
  });
  socket.on('order', (result) => {
    console.log("order :",result);
    dispatch(handleSockerOrderData(result));
  });

  socket.on('balance', (result) => {
    console.log("balance",result);
    dispatch(getProfile());
    dispatch(updateBalance(result));

  });

  socket.on('version', (result) => {
    // console.log("version",result);
    dispatch(getVersionUpdate(result));
  });

  socket.on('asset', (result) => {
    console.log("asset",result);
    dispatch(updateAsset(result));
    dispatch(reloadApiOrder(result));
    dispatch(getProfile());
  });

  socket.on('notif', (result) => {
    if (result.type === 'error') {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    } else {
      dispatch(successAlert(config.errors[result?.message] || config.errors[99999]));
    }
  });

  socket.on('alert', (result) => {
    dispatch(successAlert('هشدار قیمت: ' + (result.coin + result.pair).toUpperCase() + ' به ' + result.price + ' رسید'));
  });
};

const reconnect = (dispatch: IDispatch) => {
  socket.disconnect();
  connect(dispatch);
};

const disconnect = () => {
  socket.disconnect();
};

export { connect, disconnect, reconnect, socket };
