import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideDialog } from 'services/reducer/dialog';

const OptionForm = ({ value, def, option, setOption }: any): React.ReactElement => {
  const dispatch = useDispatch();

  return <div className={`popup popupDown optionList ${option?.direct === 'ltr' && 'optionListLtr'}`}>
    {Array.from(value)?.map((i: any, n: any) =>
      <div key={n} className={`itemOptionForm ${def==i?.network?.name?"select":""}`}
        onClick={() => {
          if(i?.isActive && i?.withdraw?.isActive){
            setOption(i?.network?.name);
            dispatch(hideDialog());
          }else{
             dispatch(errorAlert("شبکه غیرفعال است"));
          }
        }}
      >
        <p className='t1 colorText'>{i?.network?.name}</p>
        <p className={`t2 ${(i?.isActive && i?.withdraw?.isActive) ? "active" : "deActive"}`}>
          {(i?.isActive && i?.withdraw?.isActive) ? "فعال" : "غیر فعال"}
        </p>
      </div>
    )}

  </div>;
};

export default OptionForm;
