import { EStatus, ETransaction } from 'enums/global';

const statusTranslate = (status: EStatus) => {
  if (status === EStatus.cancel || status === EStatus.reject) {
    return 'لغو شد';
  } else if (status === EStatus.complete || status === EStatus.confirm) {
    return 'تکمیل';
  } else if (status === EStatus.partial) {
    return 'ناقص';
  } else if (status === EStatus.pending) {
    return 'در انتظار';
  } else if (status === EStatus.close) {
    return 'بسته';
  } else if (status === EStatus.referral) {
    return ' ارجاع شده';
  } else if (status === EStatus.answer) {
    return 'پاسخ داده شد';
  }
  return 'در انتظار';
};

const sideTranslate = (status: ETransaction) => {
  if (status === ETransaction.buy) {
    return 'خرید';
  } else if (status === ETransaction.deposit) {
    return 'واریز';
  } else if (status === ETransaction.sell) {
    return 'فروش';
  } else if (status === ETransaction.cashout) {
    return 'برداشت';
  } else if (status === ETransaction.use) {
    return 'فـروش';
  } else if (status === ETransaction.create) {
    return 'خریـد';
  }
  return '-';
};

const payTranslate = (pay: string) => {
  if (pay === 'gateway') {
    return 'درگاه شتابی';
  } else if (pay === 'cashCode') {
    return 'شناسه واریز';
  } else if (pay === 'manual') {
    return 'سیستمی';
  } else if (pay === 'card') {
    return 'کارت به کارت';
  }
  return '-';
};

const tradeTranslate = (trade: string) => {
  if (trade === 'otc') {
    return 'سفارش سریع';
  } else if (trade === 'limit') {
    return 'قیمت معین';
  } else if (trade === 'market') {
    return 'قیمت بازار';
  } else if (trade === 'stop') {
    return 'حد ضرر';
  }
  return '-';
};

const statusPayment = (status: EStatus) => {
  if (status === EStatus.cancel) {
    return 'لغو شده';
  } else if (status === EStatus.reject) {
    return 'رد شده';
  } else if (status === EStatus.complete || status === EStatus.confirm) {
    return 'موفق';
  } else if (status === EStatus.partial) {
    return 'ناقص';
  } else if (status === EStatus.pending || status === EStatus.waiting) {
    return 'منتظر';
  } else if (status === EStatus.checking || status === EStatus.process) {
    return 'بررسی';
  } else if (status === EStatus.done) {
    return ' انجام شد';
  }
  return 'منتظر';
};



const statusOrder = (status: EStatus) => {
  if (status === EStatus.cancel || status === EStatus.reject) {
    return 'لغو شده';
  } else if (status === EStatus.complete || status === EStatus.confirm) {
    return 'انجام شده';
  } else if (status === EStatus.pending) {
    return 'باز';
  }
  else if (status === EStatus.process) {
    return 'منتظر';
  }
  return 'باز';
};

 const statusFA: any = {
  "pending": "منتظر",
  "confirm": "موفق",
  "complete": "موفق",
  "reject": "رد شده",
  "cancel": "لغو شد",
  "waiting": "منتظر",
  "checking": "بررسی",
  "process": "بررسی",
  "done": "انجام شد",
}

const statusVoucher = (status: EStatus) => {
  if (status === EStatus.reject) {
    return 'ناموفق';
  } else if (status === EStatus.cancel) {
    return 'لغو شد';
  } else if (status === EStatus.complete || status === EStatus.confirm) {
    return 'موفق';
  } else if (status === EStatus.pending) {
    return 'در انتظار';
  }
  return 'در انتظار';
};

const statusTicket = (status: EStatus) => {
  if (status === EStatus.referral) {
    return 'ارجاع شد';
  } else if (status === EStatus.close) {
    return 'بسته شد';
  } else if (status === EStatus.answer) {
    return 'پاسخ داده شد';
  } else if (status === EStatus.pending) {
    return 'منتظر پاسخ';
  }
  return 'منتظر پاسخ';
};

const socialLink: any = {
  googlePlayEX: "https://play.google.com/store/apps/details?id=com.exnovin&hl=fa",
  bazarEX: "https://cafebazaar.ir/app/com.exnovin",
  pwaEX: "https://pwa.exnovin.net",
  appStorAuthIOS: "https://apps.apple.com/us/app/google-authenticator/id388497605",
  googlePlayAuthAndroid: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US",
  bazarAuth: "https://cafebazaar.ir/app/com.google.android.apps.authenticator2",
  telegram: "https://t.me/exnovin_com",
  instagram: "https://www.instagram.com/exnovin_io/",
  aparat: "https://www.aparat.com/exnovin",
  whatsapp: "https://www.whatsapp.com",
  twitter: "https://x.com/exnovin_net",
  facebook: "https://www.facebook.com",
}

const digitalName: any = {
  "uusd": "یو ووچر ( یوتوپیا )",
  "pusd": "ووچر یوتوپیا"
};

export {statusFA, digitalName, socialLink, payTranslate, sideTranslate, statusVoucher, statusTranslate, statusTicket, tradeTranslate, statusOrder, statusPayment };
