import useGlobal from 'hooks/useGlobal';
import { ICoinProps } from 'interfaces/global';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';

const Coins = ({ setCoin }: ICoinProps) => {
  const dispatch = useDispatch();
  const global = useGlobal();
  const [text, setText] = useState('');

  const coinListRender = () => {
    const rows: JSX.Element[] = [];

    Object.values(global?.coins)
      .filter((e) => e.name?.includes(text) || e.nameFa?.includes(text) || e.symbol?.includes(text))
      .filter((e) => !Object.keys(global.listVoucherSymbol)?.includes(e.symbol))
      .forEach((e, i) => {
        rows.push(
          <div
            key={i}
            onClick={() => {
              setCoin(e);
              dispatch(hideDialog());
            }}
          >
            <img crossOrigin='anonymous' src={e.icon} />
            <section>
              <h2>
                {e.name} <span>({e.symbol.toUpperCase()})</span>
              </h2>
              <h3>{e.nameFa}</h3>
            </section>
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  return (
    <div className='popup popupWidth'>
      <div className='registerForm registerFormStart'>
        <div className='search'>
          <input type='text' className='ltr textRight' onChange={(e) => setText(e.target.value)} placeholder='جستجوی ارز' />
        </div>
        <div className='listOfCoin'>{coinListRender()}</div>
      </div>
    </div>
  );
};

export default Coins;
