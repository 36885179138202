import { DialogContent } from '@mui/material';
import Deposit from 'components/Modules/Deposit';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';

const DialogEmptyBalance = ({ symbol }: any) => {
  const dispatch = useDispatch();

  return (
    <DialogContent className='phishingWarning' style={{ background: "#fff" }}>
      <div className='body'>
        <div className='topTxt'>
          <span className='infoOrange'></span>
          <p className='t1'>موجودی کیف پول کافی نیست!</p>
        </div>
        <div className='divider'></div>
        <div className='warningText'>
            موجودی کیف پول {symbol == "tmn" ? "تومان" : symbol?.toUpperCase()} شما برای این معامله کافی نیست. لطفاً مقدار را بررسی و یا برای افزایش موجودی اقدام کنید.
        </div>
        <div className='btnsTxt'>
          <button className=" finalCheckBtn btnCancel "  onClick={() => dispatch(hideDialog())} > انصراف  </button>
          <button className="finalCheckBtn" style={{marginRight:"auto"}} onClick={() => { dispatch(hideDialog()); dispatch(fullDrawer(<Deposit symbol={symbol} />)) }} >افزایش موجودی {symbol == "tmn" ? "تومان" : symbol?.toUpperCase()}</button>
        </div>
      </div>
    </DialogContent>
  )
}

export default DialogEmptyBalance