import React, { useEffect, useState } from 'react';
import moment from 'jalali-moment';
import _ from 'lodash';
// import FetchListLoadSmall from '../../../components/FetchListLoadSmall';
// import { statusFA } from '../../../config/variable';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { zeroFormat } from 'utils/numeral';
import config from 'config';
import gather from 'utils/gather';
import Notices from 'components/Modules/Notices';
import { fullDrawer } from 'services/reducer/drawer';
import useGlobal from 'hooks/useGlobal';
import { statusFA } from 'utils/translate';
import FetchListLoadSmall from 'components/FetchListLoadSmall';
import { isUserLogin } from 'utils/user';
import Alarms from 'components/Modules/Alarms';

const TableTraNotBox = () => {
  const dispatch = useDispatch<any>();
  const global = useGlobal();
  const [listTras, setListTras] = useState([]);
  const [listAlarm, setListAlarm] = useState([]);
  const [listNotice, setListNotice] = useState([]);
  const [loadHisTras, setLoadHisTras] = useState(false);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(30, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));
  const [tabSelect, setTabSelect] = useState("depo");
  const [tabSelectNotif, setTabSelectNotif] = useState("private");


  const get = async () => {
    setLoadHisTras(true);
    setListTras([])

    const result = tabSelect == "depo"
      ? await gather(`${config.data.api}/v1/deposit?sortBy=createdAt&orderBy=desc&page=1&fromDate=${from}&toDate=${to}&limit=3`, true).get()
      : tabSelect == "cashout"
        ? await gather(`${config.data.api}/v1/withdraw?sortBy=createdAt&orderBy=desc&page=1&fromDate=${from}&toDate=${to}&limit=3`, true).get()
        : await gather(`${config.data.api}/v1/order?sortBy=createdAt&orderBy=desc&page=1&fromDate=${from}&toDate=${to}&limit=3`, true).get()


    if (result.message === 200) {
      setListTras(result?.data ?? []);

    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHisTras(false);
  }

  const initAlarm = async () => {
    const result = await gather(`${config.data.api}/v1/alarm`, true).get();
    if (result.code === 200) {
      setListAlarm(result.data)
    }
  };

  const initNotice = async () => {
    const result = await gather(`${config.data.api}/v1/notice`, true).get();
    if (result.code === 200) {
      setListNotice(result.data);
    }
  };

  useEffect(() => {
    if (isUserLogin()) {
      initAlarm();
      initNotice();
    }
  }, []);

  useEffect(() => {
    if (isUserLogin()) {
      get()
    }
  }, [tabSelect]);

  return (
    <div className='tableTra_NotBox'>
      <div className='cardTableTrans'>
        <div className='boxTitle'>
          <p className='t1'>تاریخچه اخیر</p>
        </div>
        <div className='boxtabs'>
          <button className={`btn ${tabSelect == "depo" ? "active" : ""}`} onClick={() => setTabSelect("depo")}>
            <span className='icon-deposit1'></span>
            واریزها
          </button>
          <button className={`btn ${tabSelect == "cashout" ? "active" : ""}`} onClick={() => setTabSelect("cashout")}>
            <span className='icon-witdraw1'></span>
            برداشت ها
          </button>
          <button className={`btn ${tabSelect == "order" ? "active" : ""}`} onClick={() => setTabSelect("order")}>
            <span className='icon-IconRepeat'></span>
            معاملات
          </button>
        </div>
        <div className='tb_View tb_listTrans'>
          <table>
            <thead>
              <tr>
                <th><h5>رمز ارز</h5></th>
                <th><h5>مقدار</h5></th>
                <th><h5>تاریخ</h5></th>
                <th><h5>وضعیت</h5></th>
              </tr >
            </thead >
            <tbody className={"relative"}>
              {(!loadHisTras && !_.isEmpty(listTras))
                && Array.from(listTras)?.map((i: any, n: any) =>
                  <tr key={n} className="box itemBody">
                    <td className='nameCoin'>
                      <img crossOrigin='anonymous' className='iconCoin' src={global?.coins[i.coin?.symbol]?.icon} />
                      <p className='t1'>{i?.coin?.nameFa} <b>({i?.coin?.symbol})</b></p>
                    </td>
                    <td className='amount'>{zeroFormat(i?.amount)}</td>
                    <td className='date ltr'>{i?.createdAt ? moment(i?.createdAt).locale("fa").format('  YYYY-MM-DD HH:mm') : "---"}</td>
                    <td className={`status ${i?.status}`}>{statusFA[i?.status]}</td>
                  </tr>)}
            </tbody>
          </table >
          <FetchListLoadSmall list={listTras} load={loadHisTras} />
        </div >
      </div>
      <div className='cardTableNotif'>
        <div className='boxTitle'>
          <p className='t1'>اطلاعیه ها</p>
          <p onClick={() => dispatch(fullDrawer(tabSelectNotif == "update" ? <Notices /> : <Alarms reloadApi={initAlarm} />))} className='t2 cPointer'>همه اطلاعیه ها</p>
        </div>
        <div className='boxtabs'>
          <button className={`btn ${tabSelectNotif == "private" ? "active" : ""}`} onClick={() => setTabSelectNotif("private")}>
            خصوصی
          </button>
          <button className={`btn ${tabSelectNotif == "public" ? "active" : ""}`} onClick={() => setTabSelectNotif("public")}>
            عمومی
          </button>
          <button className={`btn ${tabSelectNotif == "update" ? "active" : ""}`} onClick={() => setTabSelectNotif("update")}>
            بروزرسانی ها
          </button>
        </div>
        <div className='listNotif'>
          {((tabSelectNotif == "private" && listAlarm?.filter((ele: any) => ele?.user)?.length == 0) ||
            (tabSelectNotif == "public" && listAlarm?.filter((ele: any) => !ele?.user)?.length == 0) ||
            (tabSelectNotif == "update" && listNotice?.length == 0))
            ? <div className='noItem' style={{ alignSelf: "center" }}>موردی یافت نشد</div>
            : Array.from(tabSelectNotif == "update"
              ? listNotice
              : listAlarm?.filter((ele: any) => {
                if (tabSelectNotif == "private") {
                  return ele?.user
                } else {
                  return !ele?.user
                }
              })
            )?.slice(0,3)?.map((i: any, n: number) =>
              <div className='item' key={n}
                onClick={() => {
                  tabSelectNotif == "update"
                    ? dispatch(fullDrawer(<Notices />))
                    : dispatch(fullDrawer(<Alarms reloadApi={()=>{}} />))
                }}
              >
                <div className='iconLogo'>
                  <span className='icon-logoGray'></span>
                </div>
                <div className='boxDetail'>
                  <div className='txtBox'>
                    <p className='t1'>{i?.text ? i?.text?.slice(0, 50) : "- - -"}{i?.text?.length > 80 ? " . . ." : ""}</p>
                    <p className='t2 ltr'>{moment(i?.createdAt).locale("fa").format('YYYY-MM-DD HH:mm')}</p>
                  </div>
                  <span className='icon-ArrowLeft'></span>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default TableTraNotBox;
