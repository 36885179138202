import Alarms from 'components/Modules/Alarms';
import Notices from 'components/Modules/Notices';
import config from 'config';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import gather from 'utils/gather';
import { isUserLogin } from 'utils/user';
import Menu from './Menu';
import PreLogin from './PreLogin';
import Goftino from 'components/Modules/Goftino';
import useUser from 'hooks/useUser';
import { getProfile } from 'services/reducer/user';
// import { useLocalStorage } from 'usehooks-ts';
let READY = 0;

const Header = ({ title }: { title: string }) => {
  const { profile } = useUser();
  const dispatch: any = useDispatch();
  const [alarm, setAlarm] = useState(READY);
  // const [theme, setTheme] = useLocalStorage('themePWAEX', "light");


  ////////////////////////

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager.getSubscription().then((subscription) => {
          setIsSubscribed(!!subscription);
        });
      });
    }
  }, []);

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        console.log("دسترسی به نوتیفیکیشن رد شد.");
        return;
      }

      console.log("دسترسی به نوتیفیکیشن داده شد.");
      subscribeUserToPush();
    } catch (error) {
      console.error("خطا در دریافت دسترسی:", error);
    }
  };

  const subscribeUserToPush = async () => {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js");

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: 'BOFi9Vqhl0gmqvElW6J5d4lm4NRLq5-cokiUi-xPXEXBUuZAlQirQsH_bWGOXbMLOAJiazGNw_p9YK15T3qKoPQ',
      });

      console.log("اشتراک Push ثبت شد:", JSON.stringify(subscription));
      setIsSubscribed(true);
      sendSubscriptionToServer(subscription);
    } catch (error) {
      console.error("خطا در ثبت اشتراک:", error);
    }
  };

  const sendSubscriptionToServer = async (subscription: any) => {
    await fetch("https://your-server.com/subscribe", {
      method: "POST",
      body: JSON.stringify(subscription),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  /////////////////////

  const checkApi = async () => {
    dispatch(getProfile());
    init(new Date().getTime())
  }

  const init = async (itemTime: any) => {
    READY = 0
    const result = await gather(`${config.data.api}/v1/alarm`, true).get();
    if (result.code === 200) {
      // let count = 0;
      result.data?.forEach((i: any) => {
        if (itemTime) {
          if (i?.createdAt > itemTime) {
            READY += 1
          }
        }
      })

      setAlarm(READY);

    }
  };

  useEffect(() => {
    if (READY === 0 && isUserLogin()) {
      init(profile?.readNotif);
    }
  }, []);

  return (
    <div className='top topHeader'>
      <div className='container'>
        <div className='menuIcon' onClick={() => dispatch(fullDrawer(<Menu />))}></div>
        <h1 onClick={() => requestPermission()}>{title}</h1>
        {/* <div className={`sunIcon`} onClick={() => setTheme(theme == "light" ? "dark" : "light")}></div> */}
        <div className={` supportIcon`} onClick={() => dispatch(fullDrawer(<Goftino />))}> </div>
        <div
          className={` noticeIcon`}
          onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : dispatch(fullDrawer(<Notices />)))}
        ></div>
        <div
          className={`alarmBox  alarmBox_${alarm}`}
          style={{ position: "relative" }}
          onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : dispatch(fullDrawer(<Alarms reloadApi={checkApi} />)))}
        >
          <i className={` bellIcon ${alarm > 0 && "animBell"} `}></i>
          <span className='txtAlarm'>{alarm > 10 ? "+10" : alarm}</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
