import { Alert, Collapse } from '@mui/material';
import useAlert from 'hooks/useAlert';
import { IAlert } from 'interfaces/reducer';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideAlert, removeAlert, showAlert } from 'services/reducer/alert';
import { useLocalStorage } from 'usehooks-ts';

const timerHide: NodeJS.Timeout[] = [];
const timerShow: NodeJS.Timeout[] = [];

const AlertList = () => {
  const dispatch = useDispatch();
  const { alerts } = useAlert();
  const [isSundNotif] = useLocalStorage('isSundNotifPwa', "false");
  const [isFalg, setIsFalg] = useState(true);

  const handleClose = async (id: number) => {
    clearTimeout(timerHide[id]);
    clearTimeout(timerShow[id]);

    dispatch(hideAlert(id));
    setTimeout(() => {
      dispatch(removeAlert(id));
    }, 200);
  };

  const setTimerHide = (id: number) => {
    if (!timerHide[id]) {
      timerHide[id] = setTimeout(() => {
        handleClose(id);
      }, 4000);
    }
  };

  const setTimerShow = (value: any) => {
    if (!timerShow[value?.id]) {
      timerShow[value?.id] = setTimeout(() => {
        dispatch(showAlert(value?.id));
      }, 50);

      if (isSundNotif=="true" && isFalg) {
        if (!value?.text?.includes("کپی")) {
          const audio = new Audio('/sounds/notification.mp3');
          audio.play().catch(err => console.error("Audio play error:", err));
          setIsFalg(false)
          setTimeout(() => {
            setIsFalg(true)
          }, 1000);
        }
      }
    }
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    alerts.forEach((value: IAlert) => {
      setTimerHide(value.id);
      setTimerShow(value);

      rows.push(
        <Collapse key={value.id} in={value.open}>
          <Alert
            variant='filled'
            onClose={() => handleClose(value.id)}
            severity={value.severity}
            sx={{ width: '100%', mb: '10px' }}
          >
            {value.text}
          </Alert>
        </Collapse>,
      );
    });

    return rows;
  };

  return <div className='alertParent'>{list()}</div>;
};

export default AlertList;
