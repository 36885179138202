import config from 'config';
import useTimer from 'hooks/useTimer';
import { ICode } from 'interfaces/login';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { numbersToEn } from 'utils/tools';

const Code = ({ mobile, setStep, type }: ICode) => {
  const [code, setCode] = useState<any>('');
  const dispatch = useDispatch();
  const timer = useTimer();

  const confirmAction = async (code1:any) => {
    dispatch(showLoading());
    
    const result = await gather(`${config.data.api}/v1/auth/signin/verify`).post({
      mobile: mobile.startsWith('0') ? mobile : '0' + mobile,
      code: numbersToEn(code1?.toString()),
      type: type,
    });

    if (result.code === 200) {
      Cookies.set('pwaAccessToken', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('pwaRefreshToken', result.data.refreshToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      window.location.reload();
    } else {
      setCode([]);
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      dispatch(hideLoading());
    }
  };

  const reloadCode = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/auth/signin/confirm`).post({
      mobile: mobile.startsWith('0') ? mobile : '0' + mobile,
      type: type,
    })

    if (result.message === 200) {
      timer.start();
      setCode([]);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  }

  useEffect(() => {
    timer.start();
  }, []);


  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal
      } as CredentialRequestOptions)
        .then((otp: any) => {
          const code2 = (otp as any).code;
          setCode(code2.toLocaleString());
          confirmAction(code2.toLocaleString())

        }).catch((err) => {
          console.log('Error:', err);
        });

      return () => ac.abort();
    }
    return undefined;
  }, []);

  return (
    <>
      <h4 className='confirmTitle'>{type=="sms"?"تاییدیه پیامکی":"تایید کد اعتبارسنجی"}</h4>
      <div className='rules'>
        {type === 'sms' ? (
          <>
            کد تایید برای شماره <i>{mobile}</i> پیامک شد
          </>
        ) : (
          <>
            کد اعتبارسنجی <i>Google Authenticator</i> را وارد نمایید
          </>
        )}
      </div>
      <div className='codeNumberBox'>
        <VerificationInput
          onComplete={(e) => confirmAction(e)}
          onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          value={code}
          placeholder='-'
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
      </div>
      <div className='changeMobile'>
        {type === 'sms' && (
          <i className="colorText">
            {!timer.isFinish ? (
              <>
                {timer.minute ? `${timer.minute} دقیقه و ` : ''}
                {timer.second} ثانیه
              </>
            ) : (
              <div
                className='active'
                onClick={reloadCode}
              >
                ارسال مجدد کد
              </div>
            )}
          </i>
        )}
        <p onClick={() => setStep(1)}>ویرایش شماره</p>
      </div>
    </>
  );
};

export default Code;
