const data = {
  pwa: process.env.REACT_APP_PWA!,
  api: process.env.REACT_APP_API!,
};

// const digital = [
//   'uusd',
//   'pusd',
//   // 'wm',
// ];

// const errors: { [key: number]: string } = {
const errors: any = {
  "401": "به این بخش دسترسی ندارید",
  "500": "خطا سمت سرور",
  "429": "تعداد درخواست بیش از حد مجاز ",
  "200": "عملیات با موفقیت انجام شد",
  "403": "عدم دسترسی",
  "404": "پیدا نشد",
  "400": "درخواست شما صحیح نیست",
  "502": "خطا از سمت زیرساخت! لطفا دقایق بعد تلاش کنید.",
  "503": "در دسترس نیست",
  "100441": "لطفا فیلد های ضروری را تکمیل کنید",
  "10001": "این شماره موبایل قبلا ثبت شده است",
  "10002": "کد وارد شده صحیح نمی باشد",
  "10003": "شماره موبایل یا رمز عبور اشتباه است",
  "10004": "فایل پیدا نشد",
  "10005": "فایل صحیح نمی باشد",
  "10006": "کاربر تایید نشده است",
  "10007": "پرداخت تایید نشده است",
  "10008": "کد ملی با تاریخ تولد وارد شده مطابقت ندارد",
  "10009": "کد ملی با شماره تماس وارد شده مطابقت ندارد",
  "10010": "ایمیل صحیح نمی باشد",
  "10011": "شماره موبایل صحیح نمی باشد",
  "10012": "رمز عبور را به درستی وارد نمایید",
  "10013": "تکرار رمز عبور را به درستی وارد نمایید",
  "10014": "شماره کارت با کد ملی شما مطابقت ندارد",
  "10015": "شماره کارت صحیح نمی باشد",
  "10016": "شماره کارت قبلا ثبت شده است",
  "10017": "مدارک ثبت نشده است",
  "10018": "شماره کارت موجود نمی باشد",
  "10019": "نوع داده صحیح نمی باشد",
  "10020": "نماد وجود دارد",
  "10021": "نام صحیح نمی باشد",
  "10022": "اسکنر معتبر نیست",
  "10023": "نماد معتبر نیست",
  "10024": "وضعیت فعالیت معتبر نیست",
  "10025": "آی دی معتبر نیست",
  "10026": "شاخص وجود دارد",
  "10027": "ارز مورد نظر وجود ندارد",
  "10028": "کاربر مورد نظر وجود ندارد",
  "10029": "موجودی کافی نیست",
  "1002900": "مبلغ درخواستی بیش از موجودی شماست لطفا مقادیر کمتر را امتحان کنید یا در صورت داشتن سفارش باز آن ها را بررسی نمایید",
  "10030": "شبکه مورد نظر وجود ندارد",
  "10031": "در انتظار تایید ارتقای سطح کاربری",
  "10032": "سطح بعدی یافت نشد. با مدیریت تماس بگیرید",
  "10033": "ارز مورد نظر این شبکه را پشتیبانی نمی کند",
  "10034": "حداقل میزان برداشت رعایت نشده است",
  "10035": "حداقل میزان معامله رعایت نشده است",
  "10036": "کد معرف صحیح نمی باشد",
  "10037": "سطح کاربری شما پایین است",
  "1003700": "برای استفاده از سرویس واریز شناسه دار سطح کاربری 2 به بالا نیاز است",
  "10038": "کد صحیح نمی باشد",
  "10039": "حداقل میزان ساخت کد رعایت نشده است",
  "10040": "درخواست منقضی شده است",
  "10041": "دریافت کننده صحیح نمی باشد",
  "10042": "قیمت ثبت نشده است",
  "10043": "تایید قوانین پذیرفته نشده است",
  "10044": "آدرس را وارد کنید",
  "10045": "کد ملی را وارد کنید",
  "10046": "نام خانوادگی را وارد کنید",
  "10047": "تاریخ تولد را وارد کنید",
  "10048": "سفارش شما با موفقیت تکمیل شد",
  "10049": "پسورد قدیمی اشتباه است",
  "10050": "شماره شبا پیدا نشد",
  "10051": "شماره شبا موجود است",
  "10052": "وضعیت  معتبر نیست",
  "10053": "???",
  "10054": "سقف برداشت روزانه خود را بررسی کنید",
  "10055": "سقف برداشت ماهانه خود را بررسی کنید",
  "10056": "حداقل واریز را رعایت کنید ",
  "10057": "کارت معتبر نیست",
  "10058": "ایمیل در دیتابیس وجود دارد ",
  "10059": "کدملی در دیتابیس وجود دارد",
  "10060": "حد مجاز فیشینگ را بررسی کنید",
  "10061": "حداکثر معامله قبلا انجام شده است",
  "10062": "قیمت توقف صحیح نمی باشد",
  "10063": "قیمت نسبت به بازار فاصله زیادی دارد",
  "10064": "اطلاعات قبلا ثبت شده است",
  "10065": "اجازه برداشت ندارید",
  "10066": "سفارش گذاری برای این ارز غیرفعال می باشد",
  "99999": "خطا از سمت زیرساخت! لطفا دقایق بعد تلاش کنید.",
  "maxBalance": "محدودیت به دلیل عبور از سقف دارایی",
  "newUser": "محدودیت به دلیل عضویت جدید!",
  "newLogin": "محدودیت به دلیل عدم فعالیت در بازه زمانی طولانی!",
  "newDevice": "محدودیت به دلیل ورود با دستگاه جدید!",
  "cacheCode": "محدودیت به دلیل واریز بانکی به دستور پلیس فتا!",
  "level1": "محدودیت به دستور پلیس فتا!",
  "level2": "محدودیت به دلیل ارتقای سطح کاربری جدید!",
  "noPayment": "محدودیت به دلیل عدم تراکنش در 30 روز اخیر!",
  "amountDiff": "محدودیت به منظور جلوگیری از فیشینگ!"
};

export default {
  data,
  errors,
  // digital,
};
