import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const InstallPWA = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
  const isAndroid = /Android/i.test(window.navigator.userAgent);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowInstallMessage(false);
        Cookies.set('pwaPromptDismissed', 'true', { expires: 60 * 60, path: '/' });
      });
    }
  };

  const handleDismissClick = () => {
    setShowInstallMessage(false);
    Cookies.set('pwaPromptDismissed', 'true', { expires: 60 * 60, path: '/' });
  };

  useEffect(() => {
    const isPromptDismissed = Cookies.get('pwaPromptDismissed');
    if (isPromptDismissed !== 'true') {
      setShowInstallMessage(true);
    }

    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
      if (isAndroid && isPromptDismissed !== 'true') {
        setShowInstallMessage(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  if (!showInstallMessage) return null;

  return (
    <div className='dialogWrapper'>
      <span className='logo'></span>
      <h6>نسخه وب اپلیکیشن اکسنوین</h6>
      <p>با افزودن نسخه موبایل، به راحتی می توانید از خدمات اکسنوین استفاده کنید</p>
      {isIOS ? (
        <>
          <div className='boxWrapper'>
            <div>
              ۱. در نوار پایین دکمه <i></i> (Share) را انتخاب کنید
            </div>
            <div>
              ۲. گزینه <i></i> <b className='colorBlueTowhite'>Add to Home Screen</b> را انتخاب کنید
            </div>
            <div>
              ۳. در مرحله بعد <b className='colorBlueTowhite'>(Add)</b> را انتخاب کنید
            </div>
          </div>
          <div className='largeButton' onClick={() => handleDismissClick()}>
            متوجه شدم
          </div>
        </>
      ) : (
        <>
          <section>
            <span
              onClick={() => {
                handleInstallClick();
                window.open('https://play.google.com/store/apps/details?id=com.exnovin&hl=en', '_blank');
              }}
            >
              دانلود از Google Play
            </span>
            <span
              onClick={() => {
                handleInstallClick();
                window.open('https://cafebazaar.ir/app/com.exnovin?l=en', '_blank');
              }}
            >
              دانلود از کافه ‌بازار
            </span>
          </section>
          <section>
            <span onClick={() => handleDismissClick()} className='close'>
              دیگر این پیغام را نمایش نده
            </span>
          </section>
        </>
      )}
    </div>
  );
};

export default InstallPWA;
