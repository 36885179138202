import React from 'react';
import _ from 'lodash';
import useTicker from 'hooks/useTicker';
import { zeroFormat } from 'utils/numeral';
import { toFarsiNumber } from 'utils/tools';
import useGlobal from 'hooks/useGlobal';
import { NavLink } from 'react-router-dom';

const NewsBox = () => {
  const global = useGlobal();
  const ticker = useTicker();

  return (
    <div className='newsBox'>
      <div className='cardNewCoin'>
        <div className='boxTitle'>
          <p className='t1'>جدیدترین ارزهای لیست شده</p>
          <span className='icon-treeDot'></span>
        </div>
        <div className='listNewCoin'>
          {Array.from(Object.values(global?.coinsNews))?.slice(0, 10)?.map((i: any, n: any) =>
            <NavLink to={`/order/market/${i?.symbol?.toUpperCase()}-TMN`} className='item' key={n}>
              <div className='right'>
                <img crossOrigin='anonymous' className='icon'  src={i?.icon} />
                <div className='boxText'>
                  <p className='nameCoin'>{i?.symbol?.toUpperCase()}/TMN</p>
                  <div className='textBox'>
                    <p className='t1'>{zeroFormat(ticker.price(i?.symbol + "tmn"))}</p>
                    <div className='volBox'>
                      <p className={`t2 ltr ${ticker.change(i?.symbol + "tmn") > 0 ? "colorGreen" : "colorRed"}`}>
                        {toFarsiNumber(ticker.change(i?.symbol + "tmn"))}%
                      </p>
                      <span className={`icon-${ticker.change(i?.symbol + "tmn") > 0 ? "upVolume" : "downVolume"}`}></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='left'>
                <img src={`/svg/${ticker.change(i?.symbol + "tmn") > 0 ? "smallChartGreen" : "smallChartRed"}.svg`} alt="" />
              </div>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsBox;
