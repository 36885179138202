import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import useTicker from 'hooks/useTicker';
import useGlobal from 'hooks/useGlobal';
import gather from 'utils/gather';
import config from 'config';
import { toFarsiNumber } from 'utils/tools';
import { zeroFormat } from 'utils/numeral';
import moment from 'jalali-moment';

const CardChartChange = () => {
  const [selected, setSelected] = useState("weekly");
  const global = useGlobal();
  const ticker = useTicker();
  const [listTras, setListTras] = useState(new Array(3).fill("a"));
  const [loadHisTras, setLoadHisTras] = useState(false);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(7, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(1, 'd').valueOf()));

  const get = async () => {
    setLoadHisTras(true);

    const result = await gather(`${config.data.api}/v1/chart/667b0c3e623c94f7fcdc0a48/usdt/min1?fromDate=${from}&toDate=${to}`).get()

    if (result.message === 200) {
      setListTras(result?.data ?? []);

    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHisTras(false);
  }

  const series = [
    {
      name: "قمیت",
      data: [20000, 30000, 22000, 35000, 25000, 42000, 38000],
      // data: [20000, 30000, 22000, 35000, 25000, 42000, 38000, 34000, 37000, 32000, 29000, 40000],
    },
  ];


  const options: any = {
    chart: {
      type: "line",
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    markers: {
      size: 0,
      colors: ["#fff"],
      strokeColors: "#007bff",
      strokeWidth: 3,
      hover: { size: 4 },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (value: any) => `$${(value / 1000).toFixed(1)}k`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      // categories: [
      //   "فروردین", "اردیبهشت", "خرداد", "تیر", "مرداد", "شهریور",
      //   "مهر", "آبان", "آذر", "دی", "بهمن", "اسفند"
      // ],
      type: "day",
      categories: [
        moment().subtract(7, 'd').locale("fa").format('dddd'),
        moment().subtract(6, 'd').locale("fa").format('dddd'),
        moment().subtract(5, 'd').locale("fa").format('dddd'),
        moment().subtract(4, 'd').locale("fa").format('dddd'),
        moment().subtract(3, 'd').locale("fa").format('dddd'),
        moment().subtract(2, 'd').locale("fa").format('dddd'),
        moment().subtract(1, 'd').locale("fa").format('dddd'),
        moment().locale("fa").format('dddd'),
      ],
      labels: { style: { fontSize: "12px", colors: "var( --color-black4)" }, rotate: -90, rotateAlways: true,  },

    },
    yaxis: {
      labels: {
        formatter: (value: any) => `$${(value / 1000).toFixed(0)}k`,
        style: { fontSize: "13px", colors: "var( --color-black4)" },
      },
      show:false
    },
    grid: { borderColor: "#eee" },
  };

  useEffect(() => {
    get()
  }, []);

  return (
    <div className="cardChartChange">
      <div className='topTxt'>
        <p className='t1'>نمودار تغییرات</p>
        <div className='selectDay'>
          <select
            className="customSelect"
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            {/* <option value="daily">روزانه</option> */}
            <option value="weekly">هفتگی</option>
            <option value="monthly">ماهانه</option>
          </select>
          <span className='icon-selectIcon'></span>
        </div>
      </div>
      <div className='contentBoxCoin'>
        <div className='boxCoin'>
          {Array.from(Object.values(global?.coins))?.slice(0, 20)?.map((i: any, n: any) =>
            <div className="item" key={n}>
              <img crossOrigin='anonymous' className='icon' src={i?.icon} />
              <div className='boxText'>
                <p className='nameCoin'>{i?.symbol?.toUpperCase()}/TMN</p>
                <div className='textBox'>
                  <p className='t1'>{zeroFormat(ticker.price(i?.symbol + "tmn"))}</p>
                  <div className='volBox'>
                    <p className={`t2 ltr ${ticker.change(i?.symbol + "tmn") > 0 ? "colorGreen" : "colorRed"}`}>
                      {toFarsiNumber(ticker.change(i?.symbol + "tmn"))}%
                    </p>
                    <span className={`icon-${ticker.change(i?.symbol + "tmn") > 0 ? "upVolume" : "downVolume"}`}></span>
                  </div>
                </div>
              </div>
            </div>)}
        </div>
      </div>
      <div className='styleChart'>
        <Chart options={options} series={series} type="area" height={350} />
      </div>
    </div>
  );
}

export default CardChartChange;
