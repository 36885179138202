import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { fullDrawer } from 'services/reducer/drawer';
import bank from 'utils/bank';
import _ from 'lodash';
import SelectBox from 'components/Helper/SelectBox';
import NewCard from '../NewCard';
import { ICard } from 'interfaces/global';
import useGlobal from 'hooks/useGlobal';

const Card = () => {
  const dispatch = useDispatch<any>();
  const user = useUser();
  const global = useGlobal();
  const [card, setCard] = useState<ICard | null>(null);

  const copy = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      dispatch(successAlert('کپی شد'));
    } catch (error) { }
  };


  return (
    <>
      <div className='step stepDeposit'>
        <i>۱</i>
        <h3>انتخاب کارت مبدا</h3>
        <section className='addCard' onClick={() => dispatch(fullDrawer(<NewCard />))}>
          افزودن کارت
        </section>
      </div>
      <SelectBox
        data={user?.cards ?? []}
        search={false}
        symbol={""}
        value={
          card ? (
            <>
              <img src={bank.detect(card?.number ?? ""!)?.logo || '/svg/cardDown.svg'} />
              <h3>{card?.bank}</h3>
              <h2>{card?.number ? card?.number!.match(new RegExp('.{1,4}', 'g'))?.join('-') : "---"}</h2>
            </>
          ) : (
            <>شماره کارت خود را انتخاب کنید</>
          )
        }
        template='card'
        setSelected={setCard}
      />
      <p className='cashCardAlert'> شماره کارت باید به نام خودتان باشد و در اکسنوین ثبت شده باشد</p>
      <div className='step stepDeposit'>
        <i>۲</i>
        <h3>شماره کارت مقصد</h3>
      </div>
      {global.listLevelObjByID[user?.profile?.plane?.level?._id]?.cardToCard
        ?.filter((ele: any) => ele?.isActive)
        ?.map((i: any, n: any) =>
          <div className='listCashCode' key={n}>
            <section>
              {i?.card
                && <div onClick={() => copy(i?.card)}>
                  <span>شماره کارت مقصد</span>
                  <i></i>
                  <span>{i?.card}</span>
                  <img crossOrigin='anonymous' src={bank.logo(i.slug)!} alt='' />
                </div>
              }
              {i?.account &&
                <div onClick={() => copy(i?.account)}>
                  <span>شماره حساب مقصد</span>
                  <i></i>
                  <span>{i?.account}</span>
                  <img crossOrigin='anonymous' src={bank.logo(i.slug)!} alt='' />
                </div>
              }
              {i?.sheba
                && <div onClick={() => copy(i?.sheba)}>
                  <span>شماره شبا مقصد</span>
                  <i></i>
                  <span>{i?.sheba}</span>
                  <img crossOrigin='anonymous' src={bank.logo(i.slug)!} alt='' />
                </div>
              }
              <div>
                <span>نام صاحب کارت</span>
                <span>{i?.owner}</span>
              </div>
              <div>
                <span>نام بانک</span>
                <span>{i?.bank}</span>
              </div>
            </section>
            {n + 1 < global.listLevelObjByID[user?.profile?.plane?.level?._id]?.cardToCard?.length
              &&
              <div className='divderCardBank'></div>
            }
          </div>
        )}
      <div className='spacer'></div>
    </>
  );
};

export default Card;
