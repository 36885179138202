import { Backdrop, CircularProgress } from '@mui/material';
import InstallPWA from 'components/Layouts/InstallPWA';
import Digital from 'components/Pages/Digital';
import Home from 'components/Pages/Home';
import Market from 'components/Pages/Market';
import Price from 'components/Pages/Price';
import Wallet from 'components/Pages/Wallet';
import useLoading from 'hooks/useLoading';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getCoins, getCoinsNews, getLevels, getSetting } from 'services/reducer/global';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getTickers } from 'services/reducer/ticker';
import { connect, socket } from 'services/socket';
import { checkAuth, getUserData, isUserLogin } from 'utils/user';
import AlertList from './AlertList';
import DialogList from './DialogList';
import DrawerList from './DrawerList';
import { getToken, onMessage } from 'firebase/messaging';
import config from 'config';
import gather from 'utils/gather';
import { messaging } from '../../firebase/firebaseConfig';
import MessageNotif from 'components/MessageNotif';
import { toast, ToastContainer } from 'react-toastify';
import OfflineNotification from 'components/OfflineNotification';
import useUser from 'hooks/useUser';
import { useLocalStorage } from 'usehooks-ts'
import Dashboard from 'components/Pages/Dashboard';
import NotificationPrompt from 'components/NotificationPrompt';

const App = () => {
  const user = useUser();
  const [isSundNotif] = useLocalStorage('isSundNotifPwa', "false");
  const dispatch = useDispatch<IDispatch>();
  const { isLoadingShow } = useLoading();
  const [isReady, setIsReady] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [theme] = useLocalStorage('themePWAEX', "light");
  const [showPopNotif, setShowPopNotif] = useState(false);

  onMessage(messaging, (payload) => {
    if (isSundNotif=="true") {
      const audio = new Audio('/sounds/notification.mp3');
      audio.play();
    }
    toast.info(<MessageNotif notification={payload.notification} />);
  });

  const init = async () => {
    dispatch(showLoading());

    await checkAuth();

    await Promise.all([dispatch(getCoins()), dispatch(getTickers()), dispatch(getSetting()), dispatch(getLevels()), dispatch(getCoinsNews())]);

    if (isUserLogin()) {
      await getUserData(dispatch);
    }

    // fcmPermission();

    // try {
    //   if (Notification.permission === "granted" || Notification.permission === "denied") {
    //     setShowPopNotif(false);
    //   } else {
    //     setShowPopNotif(true)
    //   }
    // } catch (error) {
    //   console.log("error :", error);
    //   requestNotification();
    // }

    connect(dispatch);

    socket.on('connect', () => {
      setIsReady(true);
      dispatch(hideLoading());
    });


    try {
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent); // تشخیص iOS
      if (!isIOS) {
        if (Notification.permission === "granted" || Notification.permission === "denied") {
          setShowPopNotif(false);
        } else {
          setShowPopNotif(true);
        }
      } else {
        requestNotification();
      }
    } catch (error) {
      console.log("error :", error);
      requestNotification();
    }
  };

  const requestNotification = async () => {
    try {
      await Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          // dispatch(successAlert("اعلان‌ها فعال شد"));
          setShowPopNotif(false);

          try {
            const currentToken = await getToken(messaging, {
              vapidKey: 'BOFi9Vqhl0gmqvElW6J5d4lm4NRLq5-cokiUi-xPXEXBUuZAlQirQsH_bWGOXbMLOAJiazGNw_p9YK15T3qKoPQ',
            });

            if (!currentToken) {
              console.log("No registration token available.");
              return;
            }

            console.log("Token:", currentToken);

            if (isUserLogin()) {
              // await sendFCM(currentToken);
              sendFCM(currentToken);
            }
          } catch (err) {
            console.log("An error occurred while retrieving the token:", err);
            setShowPopNotif(false);
          }
        } else {
          // dispatch(errorAlert("مجوز اعلان رد شده است "));
          setShowPopNotif(false);
        }
      });
    } catch (error) {
      console.log("error :", error);
    }
  };

  const sendFCM = async (tokenFCM: any) => {
    const result = await gather(`${config.data.api}/v1/user/fcm`, true).post({ 'token': tokenFCM });

    if (result.message === 200) {
      console.log("fcm با موفقیت ثبت شد");
    } else {
      console.log(result?.message);
    }
  }

  const handleUpdate = () => {
    localStorage.removeItem("appVerPwaEX");
    window.location.reload();
  };

  const handleClose = () => {
    setIsUpdateAvailable(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    document.getElementById('MY-BODY')?.setAttribute('data-theme', theme);
  }, [theme]);


  useEffect(() => {
    if (user.versionUpdate?.trim().length > 0) {
      const currentVersion = localStorage.getItem("appVerPwaEX");
      if (!currentVersion) return
      if (currentVersion !== user.versionUpdate) {
        setIsUpdateAvailable(true);
        setTimeout(() => {
          setIsUpdateAvailable(false);
        }, 30000);
      }
    }
  }, [user.versionUpdate]);

  return (
    <BrowserRouter basename='/'>
      <div className='app'>
        {showPopNotif && <NotificationPrompt submit={() => requestNotification()} setShow={setShowPopNotif} />}
        <OfflineNotification />
        <Backdrop sx={{ backgroundColor: '#ffffffe8', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={isLoadingShow}>
          <CircularProgress />
        </Backdrop>
        <AlertList />
        <DrawerList />
        <DialogList />
        <InstallPWA />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        // theme={theme}
        // transition:Bounce
        />
        {isReady && (
          <Routes>
            {/* <Route path='*' element={<Navigate to={"/order/fast/:coinParam?"} />} /> */}

            <Route path='/order/fast/:coinParam?' element={<Home />} />
            <Route path='/order/market/:coinPairParam?' element={<Market />} />
            <Route path='/price' element={<Price />} />
            {isUserLogin() ?
              <>
                <Route path='/' element={<Navigate to={'/dashboard'} />} />
                <Route path='*' element={<Navigate to={'/dashboard'} />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/finance/wallet' element={<Wallet />} />
              </>
              : <>
                <Route path={"/"} element={<Home />} />
                <Route path={"*"} element={<Home />} />
              </>}

            {/* {isUserLogin() && } */}
            {user?.profile?.isOld && <Route path='/order/currency' element={<Digital />} />}
          </Routes>
        )}
        {isUpdateAvailable &&
          <div id="updateDialog" className="update-dialog">
            <div className='txtBox'>
              <span>نسخه جدیدی از اپلیکیشن آماده است!</span>
              <p className='tVer'>v{user.versionUpdate}</p>
            </div>
            <div className='btns'>
              <button onClick={handleUpdate}>به‌روزرسانی</button>
              <button className="close-btn" onClick={handleClose}>×</button>
            </div>
          </div>
        }
      </div>
    </BrowserRouter>
  );
};

export default App;
