import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import { useEffect, useState } from 'react';
import Crypto from './Crypto';
import Fiat from './Fiat';
import { useDispatch } from 'react-redux';
import moment from 'jalali-moment';
import { centerDialog } from 'services/reducer/dialog';
import Detail from '../Detail';
import useGlobal from 'hooks/useGlobal';
import { statusColor } from 'utils/color';
import { statusPayment } from 'utils/translate';
import gather from 'utils/gather';
import config from 'config';
import { zeroFormat } from 'utils/numeral';
import LoadingList from 'components/LoadingList';
import { fullDrawer } from 'services/reducer/drawer';
import History from '../History';

const tabTitles = {
  toman: 'تومان',
  crypto: 'ارز دیجیتال',
};

const Withdraw = ({ symbol }: { symbol?: string }) => {
  const global = useGlobal();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(tabTitles.toman);
  const [listHistory, setListHistory] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [from, _setFrom] = useState(
    Math.floor(
      moment()
        .subtract(360, 'd')
        .valueOf(),
    ),
  );
  const [to, _setTo] = useState(
    Math.floor(
      moment()
        .add(10, 'm')
        .valueOf(),
    ),
  );

  const getData = async (address: string) => {
    const result = await gather(`${config.data.api}/v1/${address}?fromDate=${from}&toDate=${to}`, true).get();
    if (result.code === 200) {
      return result.data.map((e: any) =>
        Object.assign(
          { tab: address === 'payment' ? e.side === 'cashout' ? tabTitles.toman : 'paymentDeposit' : tabTitles.crypto },
          e,
        ),
      );
    }
    return [];
  };

  const init = async () => {
    setLoadingList(true);

    setListHistory([]);

    const result = await Promise.all([getData('withdraw'), getData('payment')]).then((results) => {
      return [].concat.apply([], [results[0], results[1]]);
    });

    setListHistory(result.sort((b: any, a: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));

    setLoadingList(false);
  };

  useEffect(() => {
    init();
  }, [global.orderReload]);


  useEffect(() => {
    if (symbol) {
      if (symbol !== 'tmn') {
        setTab(tabTitles.crypto);
      }
    }
  }, []);


  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'برداشت'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          {tab === tabTitles.toman ? <Fiat /> : <Crypto symbol={symbol} />}
        </div>
        <div className='container inData'>
          <h4 className='notesTitle'>نکات مهم</h4>
          <div className='noteList'>
            {tab === tabTitles.toman ? (
              <>
                {/* <span>هنگام انتخاب کارت بانکی دقت کنید.</span>
                <span>
                  مقدار مبلغ درخواستی به <i>تومان</i> وارد نمایید.
                </span>
                <span>دقت کنید ، کارت بانکی که قصد ثبت در پروفایلتان دارید باید با کد ملی شما مطابقت داشته باشد.</span>
                <span>
                  جهت دریافت سریعتر تومان در خارج از سیکل پایا، از بانک های <i>ملی، ملت، سامان</i> استفاده نمائید
                </span>
                <span>
                  سیکل واریزی های پایا در ساعات <i>4:30، 11:30، 14:30 و 19:30</i> توسط شاپرک انجام خواهد شد
                </span>
                <span>
                  هنگام برداشت تومانی از حساب کاربری ، به مانده سقف روزانه/ ماهانه خود دقت کنید و در صورت نیاز به سقف بالاتر ، از منوی ارتقا حساب کاربری اقدام
                  نمایید.
                </span> */}
                <span>هنگام انتخاب <i>کارت بانکی</i> دقت کنید.</span>
                <span>مقدار مبلغ درخواستی <i>به تومان</i> وارد نمایید.</span>
                <span>جهت دریافت سریعتر تومان در خارج از سیکل پایا ، از بانک های <i className='colorRed'> صادرات </i> استفاده نمائید  </span>
                <span>سیکل واریزی های پایا در ساعات <i> 4:30 ، 11:30 ، 14:30 و 19:30 </i> توسط شاپرک انجام خواهد شد  </span>
                <span> هنگام برداشت تومانی از حساب کاربری ، به مانده سقف روزانه/ ماهانه خود دقت کنید و در صورت نیاز به سقف بالاتر ، از منوی ارتقا حساب کاربری اقدام نمایید.</span>

              </>
            ) : (
              <>
                {/* <span>رمزارز و نوع شبکه را با دقت انتخاب کنید.</span>
                <span>آدرس کیف پول مقصد و مقدار رمزارز را با دقت وارد کنید.</span>
                <span>با توجه به نوع شبکه ، در صورتی که تگ/ MEMO الزامی است ، آن را به درستی وارد کنید.</span>
                <span>کارمزد شبکه از مقدار برداشت درخواستی شما کسر خواهد شد ، بنابراین در وارد کردن مقدار برداشت خود دقت کنید.</span>
                <span>
                  هنگام برداشت رمزارزی، به <i>سقف برداشت روزانه/ماهانه</i> دلاری خود دقت کنید
                </span> */}
                <span>رمزارز و نوع شبکه را با دقت انتخاب کنید. </span>
                <span>آدرس کیف پول مقصد و مقدار رمزارز را با دقت وارد کنید.</span>
                <span>با توجه به نوع شبکه ، در صورتی که تگ/ MEMO الزامی است ، آن را به درستی وارد کنید.</span>
                <span>کارمزد شبکه از مقدار برداشت درخواستی شما کسر خواهد شد ، بنابراین در وارد کردن مقدار برداشت خود دقت کنید. </span>
                <span>هنگام برداشت رمزارزی ، به <i>سقف برداشت روزانه/ماهانه</i> دلاری خود دقت کنید</span>
                <span>در این صفحه آدرس واریزی کیف پول شامل آدرس و یا در صورت لزوم تگ/ ممو و شبکه واریزی دلخواه خود را انتخاب و کپی کنید.</span>
                <span>آدرس و یا در صورت لزوم تگ/ممو واریزی کپی شده را در صفحه‌ی مربوط به برداشت (مقصد) وارد کنید.</span>

              </>
            )}
          </div>
          <div className='spacer'></div>
        </div>
        <div className='container inData justStart '>
          <div className='topTilteBox'>
            <p className='t1'>تراکنش ها</p>
            <div className='refreshApi' onClick={init}>
              بروزرسانی
            </div>
          </div>
          <div className='historyList'>
            {loadingList ? <LoadingList /> :
              Array.from(listHistory).filter((e: any) => e.tab === tab).length > 0
                ? Array.from(listHistory).filter((e: any) => e.tab === tab)?.slice(0, 3)?.map((i: any, n: any) =>
                  <div key={n} className='itemWithdraw'
                    onClick={() => dispatch(centerDialog(<Detail data={i} type={i.tab == tabTitles.toman ? "payment" : "withdraw"} />))}
                  >
                    <section className='right'>
                      {i?.tab === tabTitles.crypto
                        ? <img className='icon' crossOrigin="anonymous" src={global?.coins[i?.coin?.symbol]?.icon} alt="" />
                        : <img className='icon' crossOrigin='anonymous' src='/images/tmn.png' />}
                      <section className='txtBox'>
                        <p className='t1'>برداشت {i.tab == tabTitles.toman ? tabTitles.toman : i?.coin?.symbol?.toUpperCase()}</p>
                        <p className='t2'>
                          {moment(i.createdAt)
                            .locale('fa')
                            .format('dddd D MMMM YYYY | HH:mm')}
                        </p>
                      </section>
                    </section>
                    <section className='left'>
                      <p className='t1'>{i.tab == tabTitles.toman ? tabTitles.toman : i?.coin?.symbol?.toUpperCase()} {zeroFormat(i?.amount)} </p>
                      <span className={`boxStatus bg${statusColor(i?.status)}`}>{statusPayment(i?.status)}</span>
                    </section>
                  </div>)
                : <div className='noItem alignCenter'>موردی یافت نشد</div>}
          </div>
          {Array.from(listHistory).filter((e: any) => e.tab === tab).length > 0
            && <div className='btnShowMore' onClick={() => dispatch(fullDrawer(<History defaultView={tab == tabTitles.toman ? "برداشت تومان" : "برداشت رمزارز"} />))}>
              <span>مشاهده بیشتر</span>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
