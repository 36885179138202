import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import { ICoin, ICurrency, ILevel, IPair, ISetting, ISubject } from 'interfaces/global';
import gather from 'utils/gather';

const initialState = {
  subjects: [] as ISubject[],
  setting: {} as ISetting,
  pairs: {} as { [k: string]: IPair },
  levels: {} as { [k: string]: ILevel },
  listLevelObjByID: {} as any,
  currencyUnit: 'TMN',
  currencies: {} as { [k: string]: ICurrency },
  coins: {} as { [k: string]: ICoin },
  coinsNews: {} as { [k: string]: ICoin },
  listVoucherObj: {} as any,
  listVoucherObjID: {} as any,
  listVoucherSymbol: {} as any,
  orderReload: {} as any,
  orderSocketData: {} as any,
};

const getCoins = createAsyncThunk('coins/fetchCoins', async () => {
  return await gather(`${config.data.api}/v1/coin`).get();
});

const getCoinsNews = createAsyncThunk('coinsNews/fetchCoinsNews', async () => {
  return await gather(`${config.data.api}/v1/coin?sortBy=createdAt&orderBy=desc`).get();
});

const getSetting = createAsyncThunk('setting/fetchSetting', async () => {
  return await gather(`${config.data.api}/v1/setting`).get();
});

const getLevels = createAsyncThunk('levels/fetchLevels', async () => {
  return await gather(`${config.data.api}/v1/level`).get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    reloadApiOrder: (state, action) => {
      state.orderReload = action.payload
    },
    handleSockerOrderData: (state, action) => {
      state.orderSocketData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSetting.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.setting = action.payload.data;
          localStorage.setItem("appVerPwaEX", action.payload?.data?.version.pwa.force);
        }
      })
      .addCase(getCoins.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data?.filter((ele: any) => ele?.isActive).forEach((value: ICoin) => {
            state.coins[value.symbol] = value;
            state.coins[value.symbol].icon = `${config.data.api}/icons/${value.symbol.toUpperCase()}.png`;

            value?.networks?.forEach((element: any) => {
              if (element?.voucher?.create?.isActive && element?.voucher?.use?.isActive && value.pair.tmn.otc) {
                state.listVoucherSymbol[value.symbol] = value.symbol;
                state.listVoucherObjID[value._id] = value;
                state.listVoucherObj[value.symbol?.toLocaleLowerCase()] = value;
                state.listVoucherObj[value.symbol?.toLocaleLowerCase()].icon = `${config.data.api}/icons/${value.symbol.toUpperCase()}.png`;
              }
            });

          });
        }
      })
      .addCase(getCoinsNews.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data?.filter((ele: any) => ele?.isActive).forEach((value: ICoin) => {
            state.coinsNews[value.symbol] = value;
            state.coinsNews[value.symbol].icon = `${config.data.api}/icons/${value.symbol.toUpperCase()}.png`;
          });
        }
      })
      .addCase(getLevels.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ILevel) => {
            state.levels[value.type] = value;
            state.listLevelObjByID[value._id] = value;
          });
        }
      });
  },
});

export { getCoins, getLevels, getSetting, getCoinsNews };
export const { reloadApiOrder, handleSockerOrderData } = slice.actions;
export default slice.reducer;
