import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import config from 'config';
import useUser from 'hooks/useUser';
import { IDispatch } from 'interfaces/global';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import { useLocalStorage } from 'usehooks-ts';
import gather from 'utils/gather';

const statusTitles = {
  unread: 'خوانده نشده',
  all: 'همه',
};

const Alarms = ({ reloadApi }: { reloadApi: any }) => {
  const { profile } = useUser();
  const [tab, setTab] = useState(statusTitles.unread);
  const [tickets, setTickets] = useState([]);
  const dispatch: IDispatch = useDispatch();
  const [isSundNotif, setIsSundNotif] = useLocalStorage('isSundNotifPwa', "false");
  const [userProfile, setUserProfile] = useState(profile?.readNotif);


  const ticketsRender = () => {
    const rows: JSX.Element[] = [];

    tickets
      .filter((i: any) => {
        if (tab === statusTitles.unread) {
          return i?.createdAt > userProfile
        } else {
          return i
        }
      })
      .sort((a: any, b: any) => b.updatedAt - a.updatedAt)
      .forEach((e: any, i) => {
        rows.push(
          <div key={i} className={e?.createdAt > userProfile ? "noSee" : ""}>
            {e.text}
            <i>
              {moment(e.updatedAt)
                .locale('fa')
                .format('ddd MMM yy ساعت HH:mm')}
            </i>
            {e?.createdAt > userProfile && <span className='notRead'>خوانده نشده</span>}
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/alarm`, true).get();
    if (result.code === 200) {
      setTickets(result.data);
      dispatch(hideLoading());

      await gather(`${config.data.api}/v1/alarm/read`, true).get();
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
    return () => {reloadApi(); }
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'اعلان ها'} />
        <div className="changeSound">
          <div className="box">
            <div className='inSecurity'>
              <div className={`switcher ${isSundNotif == "true" && 'active'}`} onClick={() => setIsSundNotif(isSundNotif == "true" ? "false" : "true")}>
                پخش صدا  <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={statusTitles} selected={tab} setSelected={setTab} />
          </div>
          <div className='items itemsFull alarmsData'>{ticketsRender()}</div>
        </div>
      </div>
    </div>
  );
};

export default Alarms;
