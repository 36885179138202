import React, { useEffect, useState } from 'react';
import FilterHist from './FilterHist';
import moment from 'jalali-moment';
import gather from 'utils/gather';
import config from 'config';
import { crateQuerySearch } from 'utils/tools';
import _ from 'lodash';
import FetchListLoad from 'components/FetchListLoad';
import { centerDialog } from 'services/reducer/dialog';
import Detail from '../Detail';
import { statusColor, typeColor } from 'utils/color';
import { sideTranslate, statusOrder } from 'utils/translate';
import { zeroFormat } from 'utils/numeral';
import useGlobal from 'hooks/useGlobal';
import { useDispatch } from 'react-redux';
import { Pagination } from '@mui/material';

const HistOrder = () => {
  const global = useGlobal();
  const dispatch = useDispatch();
  const [loadHistory, setLoadHistory] = useState(true);
  const [listHistory, setListHistory] = useState([]);
   const [listFixHistory, setListFixHistory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [searchBox, setSearchBox] = useState('');
  const [reloadApi, setReloadApi] = useState('');
  const [advancedSearch, setAdvancedSearch] = useState({
    fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
    toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
  });

  const statusList: any = {
    "complete": "انجام شده",
    "pending": "درانتظار",
    "cancel": "رد شده",
  };

  const listSide: any = {
    buy: 'خرید',
    sell: 'فروش',
  };

  const handleSearch = (e: any) => {
    setSearchBox(e)
    if (e.trim().length > 0) {
      const neweList = Array.from(listFixHistory)
        ?.filter((e: any) => e?.coin?.name?.includes(e) || e?.coin?.nameFa?.includes(e) || e?.coin?.symbol?.includes(e))

      setListHistory(neweList)
    }
    else {
      setListHistory(listFixHistory)
    }
  }

  const get = async (quList: any) => {
    setSearchBox("")
    setListHistory([])
    setLoadHistory(true);

    const result = await gather(`${config.data.api}/v1/order?sortBy=createdAt&orderBy=desc${crateQuerySearch(quList)}&-coins=${Object.keys(global.listVoucherObjID).toString().replace(/,/g, '-') ?? ""}`, true).get()

    if (result.message === 200) {
      setListHistory(result?.data ?? []);
      setListFixHistory(result?.data ?? []);
      setPageCount(result?.data?.pages?.count ?? 1);
      setPageCurrent(result?.data?.pages?.current ?? 1);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  const createQuery = () => {
    let newListQuery = [...[{ key: "page", value: pageCurrent }], ...Object.values(advancedSearch)]
    get(newListQuery);
  }

  useEffect(() => {
    createQuery();
  }, [reloadApi])


  return (

    <>
      <FilterHist searchBox={searchBox} listStatus={statusList} listType={{}} listSide={listSide} setSearchBox={handleSearch} hanleSelect={(e: any) => {
        setAdvancedSearch(e); setTimeout(() => {
          setPageCurrent(1);
          setReloadApi(new Date().getTime().toString());
        }, 100);
      }} isCoin={true} />
      <div className='historyDetail'>
        {(!loadHistory && !_.isEmpty(listHistory))
          && Array.from(listHistory)
            // ?.filter((e: any) => searchBox.trim().length > 0 && (e.name?.includes(searchBox) || e.nameFa?.includes(searchBox) || e.symbol?.includes(searchBox)))
            ?.map((e: any, n: any) =>
              <div key={n} onClick={() => dispatch(centerDialog(<Detail data={e} type='order' />))}>
                <section>
                  <img crossOrigin='anonymous' src={global?.coins[e.coin?.symbol]?.icon} />
                  <span className='f1'>{e.coin?.symbol.toUpperCase()}</span>
                  <span>
                    {moment(e.createdAt)
                      .locale('fa')
                      .format('YYYY-MM-DD , HH:mm:ss')}
                  </span>
                </section>
                <section>
                  <span className={`color${typeColor(e.side)}`}>{sideTranslate(e.side)}</span>
                  <span className={`boxStatus bg${statusColor(e.status)}`}>{statusOrder(e.status)}</span>
                </section>
                <section>
                  <span>مقدار</span>
                  <span className='ltr'>{zeroFormat(e.amount)} {e.coin?.symbol.toUpperCase()}</span>
                </section>
                <section>
                  <span>
                    قیمت <b>({e.pair.toUpperCase()})</b>
                  </span>
                  <span className='ltr'>{zeroFormat(e.price)}</span>
                </section>
              </div>
            )}
        <FetchListLoad list={listHistory} load={loadHistory} />
      </div>
      <div className='paging'>
        <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => { setPageCurrent(v); setReloadApi(new Date().getTime().toString()) }} />
      </div>
    </>
  );
}

export default HistOrder;
