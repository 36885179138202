import { CircularProgress, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';
import EditPhone from './EditPhone';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { useState } from 'react';
import { errorAlert, successAlert } from 'services/reducer/alert';
import gather from 'utils/gather';
import config from 'config';
import { numbersToEn } from 'utils/tools';
import ConfirmPhoneCode from './ConfirmPhoneCode';

const Warning = ({ oldPhone }: { oldPhone: any }) => {
  const dispatch = useDispatch<any>();
  const [myLoading, setMyLoading] = useState(false);

  const openMenu = (content: JSX.Element) => {
    dispatch(hideDrawer());
    dispatch(hideDialog())
    setTimeout(() => {
      dispatch(fullDrawer(content));
    }, 300);
  };



  const send = async () => {
    setMyLoading(true);

    const result = await gather(`${config.data.api}/v1/user/mobile`, true).post({ "mobile": numbersToEn(oldPhone?.toString()) })

    if (result.message === 200) {
      dispatch(successAlert("کد با موفقیت ارسال شد"));
      openMenu(<ConfirmPhoneCode value={numbersToEn(oldPhone?.toString())} />);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    setMyLoading(false);
  }

  return (
    <>
      <DialogContent className='dialogWarningTitel'>
        <div className='titleCloseDialog'>
          <div className='textWarning'>
            <span className='iconRed-identity'></span>
            <h4>هشدار</h4>
          </div>
          <section className="cancelOrder" onClick={() => dispatch(hideDialog())}></section>
        </div>
      </DialogContent>
      <DialogContent className='dialogWarning'>
        <p>{"توجه داشته باشید به منظور حفظ امنیت دارایی شما، پس از تغییر"}  <strong >موبایل </strong> {"به مدت ۲۴ ساعت برداشت رمز ارز  محدود میباشد"}</p>
        <div className='btns'>
          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <button className='btOk' onClick={send}>تایید</button>
          }
          <button onClick={() => !myLoading && dispatch(hideDialog())}>بازگشت</button>
        </div>
      </DialogContent>
    </>
  )
}

export default Warning;