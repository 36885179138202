import "../assets/css/notificationPrompt.css";

const NotificationBox = ({ submit, setShow }: { submit: any, setShow: any }) => {

  return (
    <div className="notification-box">
      <button className="close-btn" onClick={()=>setShow(false)}>❌</button>
      <video className="icon-video" autoPlay loop muted playsInline>
        <source src="https://exnovin.net/dl/notification.mp4" type="video/mp4" />
      </video>
      <h2 className="title">فعال‌سازی اعلان‌ها</h2>
      <p className="message">می خواهید جدیدترین اخبار، اطلاعیه و به‌روزرسانی های اکسنوین دریافت کنید؟</p>
      <div className="buttons">
        <button className="btn btn-allow" onClick={submit}>فعال‌سازی</button>
        <button className="btn btn-deny" onClick={()=>setShow(false)}>رد کردن</button>
      </div>
    </div>
  );
};

export default NotificationBox;
