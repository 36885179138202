import Tabs from 'components/Helper/Tabs';
import Bottom from 'components/Layouts/Bottom';
import Header from 'components/Layouts/Header';
import Orders from 'components/Layouts/Orders';
import Pairs from 'components/Layouts/Pairs';
import PreLogin from 'components/Layouts/PreLogin';
import VerifyList from 'components/Layouts/VerifyList';
import Deposit from 'components/Modules/Deposit';
import config from 'config';
import { EPair } from 'enums/global';
import useBalance from 'hooks/useBalance';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IBuySell } from 'interfaces/global';
import _, { invert } from 'lodash';
import { useEffect, useState } from 'react';
import Slider from 'react-input-slider';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog, hideDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { toFixed, zeroFormat } from 'utils/numeral';
import { isUserLogin } from 'utils/user';
import Chart from './Chart';
import OrderBook from './OrderBook';
import { numbersToEn, priceZeroDecimal } from 'utils/tools';
import { Tooltip } from '@mui/material'
import { useLocalStorage } from 'usehooks-ts';
import ConfirmBuySellMarket from 'components/Pages/Market/ConfirmBuySellMarket';
import DialogPriceOrder from './DialogPriceOrder';
import DialogEmptyBalance from './DialogEmptyBalance';

const tradeTabTitles = {
  limit: 'محدود',
  market: 'فوری',
  stop: 'حد ضرر',
};

const sideTabTitles = {
  buy: 'خرید',
  sell: 'فروش',
};

const marketTabTitles = {
  tmn: 'تومان',
  usdt: 'تتر',
};

const findSum = (data: IBuySell[], amount: number, precision: number) => {
  let sum = 0;
  let price = 0;
  let count = 0;

  for (const e of data) {
    if (amount <= sum) {
      break;
    }

    count++;
    sum += e.amount;
    price += e.price;
  }

  return toFixed(Number(price / count), precision);
};

const Home = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const global = useGlobal();
  const ticker = useTicker();
  const balance = useBalance();
  const user = useUser();
  const [orderList, setOrderList] = useState<{ buy: IBuySell[]; sell: IBuySell[] }>({ buy: [], sell: [] });
  const [sideTab, setSideTab] = useState(sideTabTitles.buy);
  const [coin, setCoin] = useState(state?.default || Object.values(global?.coins).find((e) => e.symbol === 'usdt')!);
  const [pair, setPair] = useState(EPair.tmn);
  const [amount, setAmount] = useState(0);
  const [stop, setStop] = useState(0);
  const [customPrice, setCustomPrice] = useState(0);
  const [tradeTab, setTradeTab] = useState(tradeTabTitles.limit);
  const [reload, setReload] = useState(0);
  const [total, setTotal] = useState(0);
  const [focusTotal, setFocusTotal] = useState(0);
  const [focusAmount, setFocusAmount] = useState(0);
  const [range, setRange] = useState({ x: 0 });
  const { coinPairParam } = useParams();
  const [confiemOrder] = useLocalStorage('confiemOrder', "false");
  const [confiemPriceOrder] = useLocalStorage('confiemPriceOrder', "false");
  const [showChart, setShowChart] = useState(false);

  const coinPrice = ticker.price(coin?.symbol + pair);
  const sum = amount > 0 ? findSum(orderList[sideTab === sideTabTitles.buy ? 'sell' : 'buy'], amount, coin.pair[pair].precision) : orderList[sideTab !== sideTabTitles.buy ? 'buy' : 'sell']?.[0]?.price;
  const currentPrice = tradeTab === tradeTabTitles.market ? sum : customPrice;
  const userAsset = balance[sideTab === sideTabTitles.buy ? pair : coin?.symbol] ?? 0;
  const trade = invert(tradeTabTitles)[tradeTab] as any;

  const resetInput = () => {
    setAmount(0);
    setCustomPrice(0);
    setRange({ x: 0 });
    setTotal(0);
    setStop(0);
  };

  const checkPriceOrderByOrderBook = () => {
    const max = (coinPrice + ((5 * coinPrice) / 100))
    const min = (coinPrice - ((5 * coinPrice) / 100));

    const checkBuyOrderBook = (orderList["buy"]?.[0]?.price + ((1 * orderList["buy"]?.[0]?.price) / 100))
    const checkSellOrderBook = (orderList["sell"]?.[0]?.price - ((1 * orderList["sell"]?.[0]?.price) / 100));

    if (Number(userAsset.balance) < (sideTab === sideTabTitles.buy ? Number(numbersToEn(total?.toString())) : Number(numbersToEn(amount?.toString())))) {
      dispatch(centerDialog(<DialogEmptyBalance symbol={sideTab === sideTabTitles.buy ? pair : coin?.symbol} />))
      return
    }

    if ((tradeTab === tradeTabTitles.stop && stop <= 0)) {
      dispatch(errorAlert("قیمت توقف معتبر نیست"));
      return
    }

    if (tradeTab === tradeTabTitles.stop && (sideTab === sideTabTitles.buy && stop <= coinPrice)
      || (sideTab === sideTabTitles.sell && stop >= coinPrice)) {
      dispatch(errorAlert("قیمت توقف معتبر نیست"));
      return
    }

    if (Number(coin?.pair[pair?.toLocaleLowerCase()]?.min) > Number(numbersToEn(total?.toString()))) {
      dispatch(errorAlert("حداقل میزان سفارش را بررسی کنید"));
      return
    }

    if (_.isEmpty(coin)) {
      dispatch(errorAlert("لطفا یک ارز را انتخاب کنید"));
      return
    }

    if (currentPrice <= 0) {
      dispatch(errorAlert("قیمت را وارد کنید"));
      return
    }

    if (amount <= 0) {
      dispatch(errorAlert("مقدار را وارد کنید"));
      return
    }

    if (!(currentPrice > min && currentPrice < max)) {
      dispatch(errorAlert("قیمت معتبر نیست"));
      return
    }

    if (tradeTab === tradeTabTitles.stop) {
      confirmSend()
      return
    }
    if (confiemPriceOrder == "true") {
      confirmSend()
      return
    }

    if (sideTab === sideTabTitles.buy
      ? Number(numbersToEn(currentPrice?.toString())) < checkSellOrderBook
      : Number(numbersToEn(currentPrice?.toString())) > checkBuyOrderBook) {
      dispatch(centerDialog(<DialogPriceOrder side={sideTab === sideTabTitles.buy ? 'buy' : 'sell'} pair={pair}
        buyOrSellAction={confirmSend}
        userPric={Number(numbersToEn(currentPrice?.toString()))}
        orderBookPrice={orderList[sideTab !== sideTabTitles.buy ? 'buy' : 'sell']?.[0]?.price} />))

    } else {
      confirmSend();
    }
  }

  const confirmSend = () => {

    if (confiemOrder == "true") {
      buyOrSellAction()
    } else {
      const data = {
        "coin": coin,
        "side": sideTab === sideTabTitles.buy ? 'buy' : 'sell',
        "price": Number(numbersToEn(currentPrice?.toString())),
        "amount": Number(numbersToEn(amount?.toString())),
        "trade": tradeTab === tradeTabTitles.stop ? 'stop' : tradeTab === tradeTabTitles.limit ? 'limit' : 'market',
        "total": Number(numbersToEn(total?.toString())),
        "fee": handleTotal().f,
        "totalFee": handleTotal().t,
        "stop": tradeTab === tradeTabTitles.stop ? Number(numbersToEn(stop?.toString())) : null,
        "base": pair
      }
      dispatch(centerDialog(<ConfirmBuySellMarket data={data} send={buyOrSellAction} close={() => dispatch(hideDialog())} />))
    }
  }

  const buyOrSellAction = async () => {
    dispatch(hideDialog())
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/order`, true).post({
      coin: coin._id,
      price: Number(numbersToEn(currentPrice?.toString())),
      pair: pair,
      stop: tradeTab === tradeTabTitles.stop ? Number(numbersToEn(stop?.toString())) : null,
      side: sideTab === sideTabTitles.buy ? 'buy' : 'sell',
      trade: tradeTab === tradeTabTitles.stop ? 'stop' : tradeTab === tradeTabTitles.limit ? 'limit' : 'market',
      amount: Number(numbersToEn(amount?.toString())),
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('سفارش شما با موفقیت ثبت شد'));
      setReload(reload + 1);
      resetInput();
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const handleSlider = (num: any) => {
    setRange(num);
    if (tradeTabTitles.market === tradeTab) {
      if (sideTab === sideTabTitles.sell) {
        const a = Number(userAsset.balance * num.x) / 100;
        setAmount(toFixed(a - Number((0.0005 * a) / 100), coin.decimal));
      } else {
        if (!currentPrice) {
          setAmount(0);
        } else {
          const a = Number((userAsset.balance / currentPrice) * num.x) / 100
          setAmount(toFixed(a - Number((0.0005 * a) / 100), coin.decimal));
        }
      }
    } else {
      let newPrice = 0

      if (customPrice == 0 || !customPrice) {
        newPrice = orderList[sideTab !== sideTabTitles.buy ? 'buy' : 'sell']?.[0]?.price ?? coinPrice
        setCustomPrice(newPrice)
      } else {
        newPrice = customPrice;
      }

      if (sideTab === sideTabTitles.sell) {
        const a = Number(userAsset.balance * num.x) / 100;
        setAmount(toFixed(a - Number((0.0005 * a) / 100), coin.decimal));
      } else {
        const a = Number((userAsset.balance / newPrice) * num.x) / 100
        setAmount(toFixed(a - Number((0.0005 * a) / 100), coin.decimal));
      }
    }
  }

  const handleAmount = (e: any) => {
    setFocusAmount(1);
    setAmount(Number(e.value));

    const total = sideTab === sideTabTitles.buy
      ? Number(e.value) * Number(currentPrice ?? 0)
      : Number(e.value)

    const pers = (Number(total / userAsset.balance) * 100);
    setRange({ x: pers > 100 ? 100 : pers });
    if (focusTotal == 0) {
      setTotal(toFixed(Number(e.value * currentPrice), pair.toUpperCase() == "TMN" ? 0 : coin.decimal))
    }
    setTimeout(() => {
      setFocusAmount(0)
    }, 50);
  }

  const handlePrice = (e: any) => {
    setCustomPrice(Number(e.value));
    const total = sideTab === sideTabTitles.buy
      ? Number(amount) * Number(e.value ?? 0)
      : Number(amount)

    const pers = (Number(total / userAsset.balance) * 100);
    setRange({ x: pers > 100 ? 100 : pers });
    if (focusTotal == 0) {
      setTotal(toFixed(Number(amount) * Number(e.value ?? 0), pair.toUpperCase() == "TMN" ? 0 : coin.decimal))
    }
  }

  const handleTotal = () => {
    const fee = Number(user?.profile?.plane?.level?.fee?.[pair.toLowerCase() == "tmn" ? "tmn" : "usdt"]?.[sideTab === sideTabTitles.buy ? "buy" : "sell"]);
    if (sideTab === sideTabTitles.buy) {
      const f = fee > 0 ? ((fee / 100) * amount) : 0
      const t = Number(amount - f);
      return { t, f }
    } else {
      const f = fee > 0 ? Number((fee / 100) * (amount * currentPrice)) : 0
      const t = Number(amount * currentPrice) - Number(f)
      return { t, f }
    }
  }

  const handleTotalChange = (e: any) => {
    if (focusAmount !== 0) return
    setFocusTotal(1)
    setTotal(e)
    if (Number(e) > 0) {
      let newPrice;
      if (!customPrice || customPrice <= 0) {
        newPrice = coinPrice;
        setCustomPrice(toFixed(Number(newPrice), coin?.pair[pair]?.precision));
      } else {
        newPrice = customPrice
      }
      const newAmount = Number(e) / Number(newPrice)
      setAmount(toFixed(Number(newAmount), coin?.decimal))
    } else {
      setAmount(0)
    }
    setTimeout(() => {
      setFocusTotal(0)
    }, 50);
  }

  useEffect(() => {
    resetInput();
  }, [sideTab, tradeTab, coin, pair]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      const coinParams = coinPairParam?.split("-")[0]?.toLocaleLowerCase();
      const PairParams = coinPairParam?.split("-")[1]?.toLocaleLowerCase();
      if (coinParams && PairParams) {
        if (Object.values(global?.coins).find((e) => e.symbol === coinParams?.toLocaleLowerCase())) {
          setCoin(Object.values(global?.coins).find((e) => e.symbol === coinParams?.toLocaleLowerCase())!)
          if (EPair.usdt == PairParams?.toLocaleLowerCase()) {
            setPair(EPair.usdt)
          } else {
            setPair(EPair.tmn)
          }
        }
      }
    }, 10);
  }, []);


  return (
    <>
      <Header title={'بازار حرفه ای'} />
      <VerifyList />
      <div className='whiteBg buyExpress'>
        <div className='container'>
          <div className='headMarket'>
            <div className='tabs'>
              <Tabs titles={tradeTabTitles} selected={tradeTab} setSelected={setTradeTab} />
            </div>
            <div className='chart' style={{ marginRight: "auto", marginLeft: "20px" }} onClick={() => setShowChart(!showChart)}></div>
            <div
              className='marketTitle'
              onClick={() => dispatch(fullDrawer(<Pairs isMarket={true} setCoin={setCoin} setPair={setPair} pair={['tmn', 'usdt']} trade={trade} />))}
            >
              <h3>
                {coin?.symbol.toUpperCase()}/{pair.toUpperCase()}
                <i>
                  {coin.nameFa}/{marketTabTitles[pair]}
                </i>
              </h3>
              <img crossOrigin='anonymous' src={coin.icon} />
              <img crossOrigin='anonymous' src={`/images/${pair}.png`} />
            </div>
          </div>
          {showChart && <div style={{ height: "280px", width: "100%" }}>
            <Chart symbol={coin?.symbol.toUpperCase()} />
          </div>}
          <div className='yourBalance yourBalanceMarket yourBalanceNew'  >
            <div>
              موجودی در دسترس:{' '}
              <span className='txtBox'>
                <p className='t1' onClick={() => userAsset.balance > 0 && handleSlider({ x: 100, y: 0 })}>
                  {zeroFormat(isUserLogin() ? userAsset.balance : 0)} {sideTab === sideTabTitles.buy ? pair.toUpperCase() : coin?.symbol.toUpperCase()}
                </p>
                <i
                  onClick={() => dispatch(fullDrawer(<Deposit symbol={sideTab === sideTabTitles.buy ? pair : coin?.symbol} />))}
                ></i>
              </span>
            </div>
          </div>
        </div>
        <div className='container inMarket'>
          <div className='rightMarket'>
            <div className='sellBuy'>
              <Tabs titles={sideTabTitles} selected={sideTab} setSelected={setSideTab} />
            </div>
            <div className='formFast'>
              {tradeTabTitles.stop === tradeTab && (
                <Tooltip arrow placement="top"
                  enterDelay={100} leaveDelay={700}
                  title={`قیمت فعال شدن حد ضرر در سفارش ${sideTab === sideTabTitles.buy ? 'خرید باید بیشتر' : 'فروش باید کمتر'} از قیمت فعلی بازار باشد`}>

                  <div className='payed'>
                    <NumericFormat
                      inputMode='decimal'
                      className='ltr textRight'
                      value={stop > 0 ? stop : ''}
                      allowNegative={false}
                      thousandSeparator={true}
                      onValueChange={(e) => setStop(Number(e.value))}
                      placeholder='حد ضرر'
                      decimalScale={coin?.pair[pair]?.precision}
                    />
                    <span>{pair.toUpperCase()}</span>
                  </div>

                </Tooltip>

              )}
              <div className={`payed ${tradeTabTitles.market === tradeTab ? 'payed_true' : 'payed_false'}`}>
                <NumericFormat
                  inputMode='decimal'
                  disabled={tradeTabTitles.market === tradeTab ? true : false}
                  className='ltr textRight'
                  value={tradeTabTitles.market === tradeTab ? (currentPrice > 0 ? currentPrice : '') : customPrice > 0 ? customPrice : ''}
                  allowNegative={false}
                  thousandSeparator={true}
                  onValueChange={(e) => handlePrice(e)}
                  placeholder='قیمت'
                  decimalScale={coin?.pair[pair]?.precision}
                />
                <span>{pair.toUpperCase()}</span>
              </div>
              <div className='payed'>
                <NumericFormat
                  inputMode='decimal'
                  className='ltr textRight'
                  value={amount > 0 ? amount : ''}
                  allowNegative={false}
                  thousandSeparator={true}
                  onValueChange={(e) => handleAmount(e)}
                  placeholder='مقدار'
                  decimalScale={coin?.decimal}
                />
                <span>{coin?.symbol?.length > 5 ? coin?.symbol?.slice(0, 4)?.toUpperCase() : coin?.symbol.toUpperCase()}</span>
              </div>
              <div className='bestPrice' onClick={() => setCustomPrice(orderList[sideTab !== sideTabTitles.buy ? 'buy' : 'sell']?.[0]?.price)}>
                <span>بهترین پیشنهاد {sideTab === sideTabTitles.buy ? 'فروش' : 'خرید'}</span>
                <p>
                  {priceZeroDecimal(orderList[sideTab !== sideTabTitles.buy ? 'buy' : 'sell']?.[0]?.price || 0, coin.pair[pair].precision, false)} {pair.toUpperCase()}
                </p>
              </div>
              <div className='runRange'>
                <Slider
                  x={range.x}
                  xreverse={true}
                  styles={{
                    track: {
                      backgroundColor: '#DFDFDF',
                    },
                    active: {
                      backgroundColor: '#2424DA',
                    },
                    thumb: {
                      backgroundColor: '#2424DA',
                      boxShadow: 'none',
                    },
                  }}
                  onChange={(e: any) => handleSlider(e)}
                />
                <span style={{ width: range.x + '%' }}></span>
                <section>
                  <i>0%</i>
                  <i>25%</i>
                  <i>50%</i>
                  <i>75%</i>
                  <i>100%</i>
                </section>
              </div>
              <div className={`payed`}>
                <span className='font-11'>جمع کل</span>
                <NumericFormat
                  inputMode='decimal'
                  className='ltr textRight'
                  // decimalScale={coin?.pair[pair]?.precision}
                  decimalScale={coin?.decimal}
                  value={total}
                  allowNegative={false}
                  thousandSeparator={true}
                  onValueChange={(e) => handleTotalChange(Number(e.value))}
                  placeholder='--'
                />
                <span className='font-11'>{pair.toUpperCase()}</span>
              </div>
              <div className='receive'>
                <div>
                  کارمزد
                  <section className='showNumSymbolMarket'>
                    <p>
                      {zeroFormat(toFixed(handleTotal().f, sideTab === sideTabTitles.buy
                        ? coin?.decimal
                        : pair == "tmn" ? 0 : coin?.pair[pair]?.precision)
                        , sideTab === sideTabTitles.buy
                          ? coin?.decimal
                          : pair == "tmn" ? 0 : coin?.pair[pair]?.precision, false)}
                    </p>
                    <p className='t1'>
                      {sideTab === sideTabTitles.buy
                        ? coin?.symbol?.length > 5
                          ? coin?.symbol?.slice(0, 4)?.toUpperCase()
                          : coin?.symbol.toUpperCase()
                        : pair?.toUpperCase()}
                    </p>
                  </section>
                </div>
                <div>
                  دریافتی شما
                  <section className='showNumSymbolMarket'>
                    <p>
                      {zeroFormat(toFixed(handleTotal().t, sideTab === sideTabTitles.buy
                        ? coin?.decimal
                        : pair == "tmn" ? 0 : coin?.pair[pair]?.precision)
                        , sideTab === sideTabTitles.buy
                          ? coin?.decimal
                          : pair == "tmn" ? 0 : coin?.pair[pair]?.precision, false)}
                    </p>
                    <p className='t1'>
                      {sideTab === sideTabTitles.buy ? coin?.symbol?.length > 5 ? coin?.symbol?.slice(0, 4)?.toUpperCase() : coin?.symbol.toUpperCase() : pair?.toUpperCase()}
                    </p>
                  </section>
                </div>
              </div>
              <div
                className={`agreePay ${sideTab === sideTabTitles.sell ? 'bgRed' : 'bgGreen'}`}
                onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : checkPriceOrderByOrderBook())}
              >
                ثبت   {sideTab}  {coin?.symbol.toUpperCase()}
              </div>
            </div>
          </div>
          <OrderBook
            orderList={orderList}
            setOrderList={setOrderList}
            coin={coin}
            pair={pair}
            coinPrice={coinPrice}
            precision={coin.pair[pair].precision}
            setCustomPrice={setCustomPrice}
            setAmount={setAmount}
            tradeTab={tradeTab}
          />
        </div>
      </div>
      <Orders reload={reload} isVoucher={false} />
      <Bottom />
    </>
  );
};

export default Home;
