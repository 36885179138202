import useGlobal from 'hooks/useGlobal';
import moment from 'jalali-moment';
import React, { useEffect, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import _ from 'lodash';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const FilterHist = ({ isCoin, hanleSelect, listStatus, listType, listSide, setSearchBox, searchBox }:
  { isCoin: boolean, hanleSelect: any, listStatus: any, listType: any, listSide: any, setSearchBox: any, searchBox: any }) => {
  const global = useGlobal();
  const [showSearch, setShowSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [listCoin, setListCoin] = useState(global?.coins);
  const [selectCoin, setSelectCoin]: any = useState({});
  const [selectType, setSelectType]: any = useState({});
  const [selectSide, setSelectSide]: any = useState("buy");
  const [selectStatus, setSelectStatus]: any = useState({});
  const [textSearch, setTextSearch] = useState<string>('');
  const [totalSearch, setTotalSearch] = useState({
    fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
    toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
  });
  const [valueTime, setValueTime] = useState([
    new DateObject({ calendar: persian }).subtract(30, "days"),
    new DateObject({ calendar: persian }).add(0, "days")
  ])

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectSide((event.target as HTMLInputElement).value);
  };

  const handleReset = () => {
    setShowSearch(false);
    setIsSearch(false);
    setSelectSide("buy");
    setSelectType({});
    setSelectCoin({});
    setSelectStatus({});
    // setCoin(null)
    setValueTime([
      new DateObject({ calendar: persian }).subtract(30, "days"),
      new DateObject({ calendar: persian }).add(0, "days")
    ])
    // setStatusFilter('');
    setTotalSearch({
      fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
      toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
    });

    hanleSelect({
      fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
      toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
    })
  }

  const handleFilterTime = (e: any) => {
    if (e) {
      let time2 = e[0]?.unix + 86400
      if (e[1] && e[1].unix > e[0]?.unix) {
        time2 = e[1].unix
      }
      handelSearch("fromDate", e[0]?.unix * 1000)
      handelSearch("toDate", time2 * 1000)
    }
  }

  const handelSearch = (key: string, value: any) => {
    setTotalSearch((prevState: any) => ({
      ...prevState,
      [key]: { key: key, value: value?.toString() }
    }));
  }

  const handleMultiSelect = (newTotal: any, selectItem: any, key: string) => {
    let newItem: any = []
    Object.keys(selectItem)?.forEach((element: any) => {
      selectItem[element]?.isChecke && newItem.push(element)
    });
    if (newItem?.length > 0) {
      newTotal[key] = { key: key, value: newItem?.join('-') }
    } else {
      delete newTotal[key]
    }
  }

  const handleSend = () => {
    let newTotal: any = { ...totalSearch, }

    handleMultiSelect(newTotal, selectCoin, "coins");
    handleMultiSelect(newTotal, selectStatus, "status");
    handleMultiSelect(newTotal, selectType, "type");
    if (!_.isEmpty(listSide)) {
      newTotal["side"] = { key: "side", value: selectSide?.toString() }
    } else {
      delete newTotal["side"]
    }

    hanleSelect(newTotal);
    setIsSearch(true);
    setShowSearch(false);
  }

  useEffect(() => {
    setListCoin(global?.coins)
  }, [global?.coins]);

  return (
    <>
      <div className='boxIconSearchHistory'>
        <div className='boxSearch'>
          <i className='searchIcon'></i>
          <input type="text" placeholder='جستجو کتید' value={searchBox} onChange={(e) => setSearchBox(e.target.value)} />
        </div>
        <i className='advSearchIcon' onClick={() => setShowSearch(!showSearch)}></i>
        {isSearch && <section className="cancelOrder" onClick={handleReset}></section>}
      </div>
      {showSearch
        &&
        <>
          <div className='boxFilerHistory mb_15 mt_10'>
            <p className='txt1'>تاریخ</p>
            <div className='datePickerAlert datePickerHistory'>
              <DatePicker
                value={valueTime}
                onChange={handleFilterTime}
                range
                dateSeparator=" تا "
                calendar={persian}
                locale={persian_fa}
                calendarPosition="bottom-right"
                placeholder='بازه تاریخ'
              />
            </div>
          </div>
          {!_.isEmpty(listSide)
            &&
            <div className='boxFilerHistory mb_15'>
              <p className='txt1'>سمت</p>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectSide}
                onChange={handleChangeRadio}
              >
                {Object.keys(listSide)?.map((i: any, n: any) =>
                  <FormControlLabel key={n} value={i} control={<Radio />} label={listSide[i]} />
                )}
              </RadioGroup>
            </div>}
          {isCoin
            &&
            <div className='boxFilerHistory mb_15'>
              <p className='txt1'>نام رمز ارز</p>
              <div className='boxSearch'>
                <i className='searchIcon'></i>
                <input type="text" value={textSearch} onChange={(e) => setTextSearch(e.target.value)} placeholder='جستجو کتید' />
              </div>
              <div className='listItemBox'>
                {Object.values(listCoin)
                  ?.filter((e) => e.name?.includes(textSearch) || e.nameFa?.includes(textSearch) || e.symbol?.includes(textSearch))
                  ?.map((i: any, n: any) =>
                    <label key={n} htmlFor={n} className='itemSelectBox'>
                      <input className='checkBox' type="checkbox" name="" checked={selectCoin[i?._id]?.isChecke ??false} id={i?._id}
                        onChange={(e: any) => setSelectCoin((prevState: any) => ({
                          ...prevState, [i?._id]: { isChecke: e.target.checked }
                        }))} />
                      <div className='nameBox'>
                        <img className='icon' crossOrigin="anonymous" src={i?.icon} alt="" />
                        <p className='t1'>{i?.nameFa}</p>
                        <p className='t2'>{i?.symbol?.toUpperCase()}</p>
                      </div>
                    </label>
                  )}
              </div>
            </div>}
          {!_.isEmpty(listStatus)
            &&
            <div className='boxFilerHistory mb_15'>
              <p className='txt1'>وضعیت</p>
              <div className='listItemBox'>
                {Object.keys(listStatus)?.map((i: any, n: any) =>
                  <label key={n} htmlFor={i} className='itemSelectBox'>
                    <input className='checkBox' type="checkbox" name="" checked={selectStatus[i]?.isChecke ??false} id={i}
                      onChange={(e: any) => setSelectStatus((prevState: any) => ({
                        ...prevState, [i]: { isChecke: e.target.checked }
                      }))} />
                    <p className='t1'>{listStatus[i]}</p>
                  </label>
                )}
              </div>
            </div>}
          {!_.isEmpty(listType)
            &&
            <div className='boxFilerHistory mb_15'>
              <p className='txt1'>نوع</p>
              <div className='listItemBox'>
                {Object.keys(listType)?.map((i: any, n: any) =>
                  <label key={n} htmlFor={i} className='itemSelectBox'>
                    <input className='checkBox' type="checkbox" name="" checked={selectType[i]?.isChecke ??false}  id={i}
                      onChange={(e: any) => setSelectType((prevState: any) => ({
                        ...prevState, [i]: { isChecke: e.target.checked }
                      }))} />
                    <p className='t1'>{listType[i]}</p>
                  </label>
                )}
              </div>
            </div>}

          <div className='largeButton largeButtonGreen' onClick={handleSend}>
            جستجو
          </div>
        </>}

      {/* {showSearch
        && <div>
          {isCoin
            && <SelectBox
              data={global?.coins}
              search={true}
              value={coin
                ? (<>
                  <img crossOrigin='anonymous' src={coin.icon} />
                  <h3>{coin.name} ({coin?.symbol.toUpperCase()})  </h3></>)
                : (<>ارز خود را انتخاب کنید</>)}
              template='coin'
              setSelected={(e) => { handelSearch("coins", e?._id); setCoin(e) }}
            />}
          <SelectBox
            data={listStatus}
            search={false}
            value={statusFilter ? <h3>{statusFilter}</h3> : <>وضعیت</>}
            template='default'
            setSelected={(e: any) => { handelSearch("status", getKeyByValue(listStatus, e)); setStatusFilter(e) }}
          />
          <div className='datePickerAlert'>
            <DatePicker
              value={valueTime}
              onChange={handleFilterTime}
              range
              dateSeparator=" تا "
              calendar={persian}
              locale={persian_fa}
              calendarPosition="bottom-right"
              placeholder='بازه تاریخ'
            />
          </div>
          <div className='largeButton largeButtonGreen' onClick={() => { hanleSelect(totalSearch); setIsSearch(true) }}>
            جستجو
          </div>
        </div>} */}
    </>
  );
}

export default FilterHist;
