import Top from 'components/Layouts/Top';
import config from 'config';
import { IDispatch } from 'interfaces/global';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDialog } from 'services/reducer/dialog';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';
import heic2any from 'heic2any';

const Level2 = () => {
  const dispatch = useDispatch<IDispatch>();
  const [src, setSrc]: any = useState();
  const [text, setText]: any = useState('');
  const [docName, setDocName] = useState("اینجا کلیک کنید یا فایل را بکشید");

  const acceptAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/levelUp`, true).get();
    if (result.code === 200) {
      dispatch(successAlert('درخواست شما با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const sendImg = async (myfile:any) => {

    const form_data = new FormData();
    form_data.append("type", "signature");
    form_data.append("doc", myfile);

    const result = await gather(`${config.data.api}/v1/file`,true).upload(form_data);

    if (result.code === 200) {
      setDocName(src?.name??"فایل با موفقیت ارسال شد")
      sendUpgrade(result?.data?.link)
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      dispatch(hideLoading());
      setDocName("خطا در ارسال دوباره تلاش کنید")
    }

  }

  const sendUpgrade = async (link: any) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/sign`,true).put({ text: text, signature: link });

    if (result.code === 200) {
      acceptAction()
      await dispatch(getProfile());
      // dispatch(DialogShow.show(<ConfirmUpgrade text={"درخواست شما جهت بررسی ارسال شد"} close={() => dispatch(DialogShow.hide())} />))
      // setChange(true);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  }

  // const handleImageUpload = async () => {

  //   if (!src) {
  //     dispatch(errorAlert("لطفا مدارک خود را بارگذاری کنید"));
  //     return;
  //   }

  //   if (text?.trim()?.length < 2) {
  //     dispatch(errorAlert("لطفا متن درخواست را وارد کنید"));
  //     return;
  //   }

  //   setDocName("اینجا کلیک کنید یا فایل را بکشید")
  //   dispatch(showLoading());
     
  //   const file:any = src;

  //   if (file && file.type.startsWith('image/')) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.src = e.target?.result as string;

  //       img.onload = async () => {
  //         const canvas = document.createElement('canvas');
  //         const maxWidth = 800;
  //         const scaleFactor = maxWidth / img.width;
  //         const width = Math.min(maxWidth, img.width);
  //         const height = img.height * scaleFactor;

  //         canvas.width = width;
  //         canvas.height = height;

  //         const ctx = canvas.getContext('2d');
  //         ctx?.drawImage(img, 0, 0, width, height);

  //         canvas.toBlob(
  //           async (blob) => {
  //             if (blob) {
  //               sendImg(blob)
  //             }else{
  //               dispatch(hideDialog());
  //             }
  //           },'image/jpeg',  0.6 );
  //       };
  //     };
  //   }
  // };

  const handleImageUpload = async () => {
    if (!src) {
      dispatch(errorAlert("لطفا مدارک خود را بارگذاری کنید"));
      return;
    }

    if (text?.trim()?.length < 2) {
      dispatch(errorAlert("لطفا متن درخواست را وارد کنید"));
      return;
    }

    setDocName("اینجا کلیک کنید یا فایل را بکشید")
    dispatch(showLoading());
     
    const file:any = src;

    if (!file || !file.type.startsWith('image/')) return;

    let convertedFile = file;

    // **تبدیل HEIC به JPEG**
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      try {
        const blob = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.8 });
        convertedFile = new File([blob as Blob], file.name.replace(/\.\w+$/, '.jpg'), { type: 'image/jpeg' });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return;
      }
    }

    const reader = new FileReader();
    reader.readAsDataURL(convertedFile);

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 1024;  // **حداکثر عرض تصویر**
        const maxHeight = 1024; // **حداکثر ارتفاع تصویر**
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const scaleFactor = Math.min(maxWidth / width, maxHeight / height);
          width = Math.floor(width * scaleFactor);
          height = Math.floor(height * scaleFactor);
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // **فشرده‌سازی و ارسال**
        let quality = 0.8; // کیفیت اولیه
        let blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'image/jpeg', quality)
        );

        // **کاهش حجم تا زیر 600 کیلوبایت**
        while (blob && blob.size > 600 * 1024 && quality > 0.2) {
          quality -= 0.1;
          blob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(resolve, 'image/jpeg', quality)
          );
        }

        if (blob) {
          sendImg(blob)
        }else{
          dispatch(hideDialog());
        }
      };
    };
  };


  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'ارتقا سطح حساب'} />
        <div className='container inData level2Page'>
          <p className='t1 colorText'>متن خود را بنویسید</p>
          <div className='textBox'>
            <textarea value={text} onChange={(e:any)=>setText(e.target.value)} placeholder='متن' className=''></textarea>
          </div>
          <p className='t1 colorText'>آپلود مدارک</p>
          <div className='uploadPicture fileUploader'>
            {src && <img className='imgSelect' src={URL.createObjectURL(src)} alt='' />}
            <FileUploader handleChange={(e:any)=>setSrc(e)} defaultValue='' value='' name='fileImage' types={['JPG', 'PNG', 'JPEG']} />
            <span></span>
            <i>{docName}</i>
          </div>
          <div className='largeButton' onClick={handleImageUpload}>
            ارسال درخواست
          </div>
        </div>
      </div>
    </div>
  );
};

export default Level2;
